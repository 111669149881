import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import ControlledTextField from '~components/Form/ControlledTextField';
import SectionCard from '~components/SectionCard';
import useConnectEndpointList from '~hooks/useConnectEndpointList';
import { CampaignPredictiveSettings } from '~pages/CampaignManagement/domain';

interface PredictiveSettingsFormProps {
  predictiveSettings: CampaignPredictiveSettings;
  isConnectPredictive: boolean;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit: () => void;
  onSubmit: (data: CampaignPredictiveSettings) => Promise<void>;
}

interface Form {
  ivrAgentsCallId: string;
  ivrAgentsNoCallId: string;
  ivrNoAgents: string;
  ivrCallback: string;
  ivrMsgBank: string;
  leaveMessageOnAttempt: string;
  answeringMachineMessage: string;
  allCallbacksAsPreview: boolean;
  trunk?: string;
  sourcePhoneNumber: string;
}

const SSMLTags = '(speak|break|lang|mark|p|phoneme|prosody|s|say-as|sub|w|amazon:effect)';
const SSMLOpen = new RegExp('^<\\s*' + SSMLTags + '(\\s+[-\\w:]+="[^"]*")*\\s*>');
const SSMLSelfClose = new RegExp('^<\\s*' + SSMLTags + '(\\s+[-\\w:]+=("[^"]*")*)*\\s*/>');
const SSMLClose = new RegExp('^</\\s*' + SSMLTags + '\\s*>');

const ssmlValidate = (value: string): string | undefined => {
  var state = [];
  while (value) {
    value = value.replace(/^\s+/, '');
    var isOpen = SSMLOpen.exec(value);
    if (isOpen !== null) {
      state.push(isOpen[1]);
      value = value.slice(isOpen[0].length);
      continue;
    }

    var isSelfClose = SSMLSelfClose.exec(value);
    if (isSelfClose !== null) {
      value = value.slice(isSelfClose[0].length);
      continue;
    }

    var isClose = SSMLClose.exec(value);
    if (isClose) {
      if (state.length === 0 || state[state.length - 1] != isClose[1]) {
        return `Unmatched closing tag: ${isClose[1]} found, state [${state.join('>')}]`;
      }
      state.pop();
      value = value.slice(isClose[0].length);
      continue;
    }

    if (/^[<>]/.test(value)) {
      return `Could not read at ${value.slice(0, 10)}`;
    }
    value = value.replace(/^[^<>]+/, '');
  }
  if (state.length > 0) {
    return `Unclosed tags: [${state.join('>')}]`;
  }

  return undefined;
};

const PredictiveSettingsForm = ({
  predictiveSettings,
  isConnectPredictive,
  isEdit,
  submitting,
  toggleEdit,
  onSubmit,
}: PredictiveSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    loading: fetchingEndpoints,
    list: endpoints,
    intersectionObserverRef,
  } = useConnectEndpointList({ shouldFetch: isEdit });
  const isLoading = fetchingEndpoints || submitting;
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      ivrAgentsCallId: `<speak>
<prosody rate="0.9"><break time="1s" />
Hi, and thanks for returning our call. If you would like to speak to one of our agents now, press
1 and we will connect you as soon as one becomes available. Alternatively, press 2 and we will try
to call you in the next few minutes, otherwise simply hang up and we will try calling over the next
couple of days.
</prosody>
</speak>`,
      ivrAgentsNoCallId: `<speak>
<prosody rate="0.9"><break time="1s" />
Hi, and thanks for returning our call. If you would like to speak to one of our agents now, press 1
and we will connect you as soon as one becomes available. Otherwise simply hang up and we will try
calling over the next couple of days.
</prosody>
</speak>`,
      ivrNoAgents: `<speak>
<prosody rate="0.9"><break time="1s" />
Hi, and thanks for returning our call. Unfortunately we don't currently have agents available to
take your call, however you can press 1 to leave us a message, and we'll get back to you.
</prosody>
</speak>`,
      ivrCallback: `<speak>
<prosody rate="0.9">
Okay, we'll give you a call once one of our agents becomes available.
</prosody>
</speak>`,
      ivrMsgBank: `<speak>\n<prosody rate="0.9">\nPlease leave your message now.\n</prosody>\n</speak>`,
      leaveMessageOnAttempt: '',
      answeringMachineMessage: '',
      allCallbacksAsPreview: false,
      trunk: '',
      sourcePhoneNumber: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isEdit === true) {
      if (isConnectPredictive) {
        setValue('sourcePhoneNumber', predictiveSettings.sourcePhoneNumber || '');
      } else {
        setValue('ivrAgentsCallId', predictiveSettings.ivrAgentsCallId || '');
        setValue('ivrAgentsNoCallId', predictiveSettings.ivrAgentsNoCallId || '');
        setValue('ivrNoAgents', predictiveSettings.ivrNoAgents || '');
        setValue('ivrCallback', predictiveSettings.ivrCallback || '');
        setValue('ivrMsgBank', predictiveSettings.ivrMsgBank || '');
        setValue('leaveMessageOnAttempt', predictiveSettings.leaveMessageOnAttempt || '');
        setValue('answeringMachineMessage', predictiveSettings.answeringMachineMessage || '');
        setValue('allCallbacksAsPreview', Boolean(predictiveSettings.allCallbacksAsPreview));
        setValue('trunk', predictiveSettings.trunk || '');
      }
    }

    return () => {
      reset();
    };
  }, [isEdit]);

  const onSubmitFn = handleSubmit(async (data: Form) => {
    let submitData: CampaignPredictiveSettings = {
      ivrAgentsCallId: undefined,
      ivrAgentsNoCallId: undefined,
      ivrNoAgents: undefined,
      ivrCallback: undefined,
      ivrMsgBank: undefined,
      leaveMessageOnAttempt: undefined,
      answeringMachineMessage: undefined,
      allCallbacksAsPreview: undefined,
      trunk: undefined,
      sourcePhoneNumber: undefined,
    };

    if (isConnectPredictive) {
      submitData = {
        ...submitData,
        sourcePhoneNumber: data.sourcePhoneNumber || undefined,
      };
    } else {
      submitData = {
        ...submitData,
        ivrAgentsCallId: data.ivrAgentsCallId || null,
        ivrAgentsNoCallId: data.ivrAgentsNoCallId || null,
        ivrNoAgents: data.ivrNoAgents || null,
        ivrCallback: data.ivrCallback || null,
        ivrMsgBank: data.ivrMsgBank || null,
        leaveMessageOnAttempt: data.leaveMessageOnAttempt || null,
        answeringMachineMessage: data.answeringMachineMessage || null,
        allCallbacksAsPreview: data.allCallbacksAsPreview,
        trunk: data.trunk || null,
      };
    }

    try {
      await onSubmit(submitData);
    } catch (e) {
      // Just return on error as we only want to reset the form on a successful submission
      return;
    }
  });

  return (
    <SectionCard title='Predictive Settings' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          {!isConnectPredictive && (
            <>
              <DataItem
                stacked={isExtraSmall}
                title='Inbound IVR - Lead Found, Agents Online'
                tooltip='SSML presented to callers when we can identify the caller and there are agents available.'
                value={predictiveSettings.ivrAgentsCallId}
              />
              <DataItem
                stacked={isExtraSmall}
                title='Inbound IVR - No Lead Found, Agents Online'
                tooltip='SSML presented to callers when we cannot identify the caller but there are agents available.'
                value={predictiveSettings.ivrAgentsNoCallId}
              />
              <DataItem
                stacked={isExtraSmall}
                title='Inbound IVR - No Agents Online'
                tooltip='SSML presented to callers when there are no agents online to take the call.'
                value={predictiveSettings.ivrNoAgents}
              />
              <DataItem
                stacked={isExtraSmall}
                title='Inbound IVR - Schedule Callback'
                tooltip='SSML presented to callers when they have selected the option to be called back.'
                value={predictiveSettings.ivrCallback}
              />
              <DataItem
                stacked={isExtraSmall}
                title='Inbound IVR - Leave Message'
                tooltip='SSML presented to callers when they have requested to leave a message.'
                value={predictiveSettings.ivrMsgBank}
              />
              <DataItem
                stacked={isExtraSmall}
                title='Leave Message On Answering Machine - Which Attempt'
                tooltip='On which attempts should we leave a message when finding an answering machine?'
                value={predictiveSettings.leaveMessageOnAttempt}
              />
              <DataItem
                stacked={isExtraSmall}
                title='Leave Message On Answering Machine - Message'
                tooltip='SSML of the message to be left on an answering machine.'
                value={predictiveSettings.answeringMachineMessage}
              />
              <DataItem
                stacked={isExtraSmall}
                title='All Callbacks As Preview'
                tooltip='Should callbacks assigned to the group be dialled as preview instead of predictive?'
                value={predictiveSettings.allCallbacksAsPreview ? 'Yes' : 'No'}
              />
              <DataItem
                stacked={isExtraSmall}
                title='Campaign Default Trunk'
                tooltip='Which trunk should be used to place and receive predictive calls?'
                value={predictiveSettings.trunk || 'Default'}
              />
            </>
          )}

          {isConnectPredictive && (
            <>
              <DataItem
                stacked={isExtraSmall}
                title='Source Phone Number'
                tooltip='The outbound CLI used for the campaign.'
                value={predictiveSettings.sourcePhoneNumber}
              />
            </>
          )}
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmitFn} noValidate>
          <Grid container spacing={2}>
            {!isConnectPredictive && (
              <>
                <Grid item xs={12}>
                  <ControlledTextField
                    name='ivrAgentsCallId'
                    control={control}
                    rules={{ validate: ssmlValidate }}
                    rows={3}
                    label='Inbound IVR, Lead Found, Agents Online'
                    disabled={isLoading}
                    error={Boolean(errors.ivrAgentsCallId)}
                    helperText={errors.ivrAgentsCallId?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ControlledTextField
                    name='ivrAgentsNoCallId'
                    control={control}
                    rules={{ validate: ssmlValidate }}
                    rows={3}
                    label='Inbound IVR, No Lead Found, Agents Online'
                    disabled={isLoading}
                    error={Boolean(errors.ivrAgentsNoCallId)}
                    helperText={errors.ivrAgentsNoCallId?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ControlledTextField
                    name='ivrNoAgents'
                    control={control}
                    rules={{ validate: ssmlValidate }}
                    rows={3}
                    label='Inbound IVR, No Agents Online'
                    disabled={isLoading}
                    error={Boolean(errors.ivrNoAgents)}
                    helperText={errors.ivrNoAgents?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ControlledTextField
                    name='ivrCallback'
                    control={control}
                    rules={{ validate: ssmlValidate }}
                    rows={3}
                    label='Inbound IVR, Schedule Callback'
                    disabled={isLoading}
                    error={Boolean(errors.ivrCallback)}
                    helperText={errors.ivrCallback?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ControlledTextField
                    name='ivrMsgBank'
                    control={control}
                    rules={{ validate: ssmlValidate }}
                    rows={3}
                    label='Inbound IVR, Leave Message'
                    disabled={isLoading}
                    error={Boolean(errors.ivrMsgBank)}
                    helperText={errors.ivrMsgBank?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ControlledTextField
                    name='leaveMessageOnAttempt'
                    control={control}
                    rules={{ pattern: /^((\d+|\d+-\d+)(,(\d+|\d+-\d+))+)?$/ }}
                    rows={1}
                    label='Leave Message On Answering Machine - Which Attempt'
                    disabled={isLoading}
                    error={Boolean(errors.leaveMessageOnAttempt)}
                    helperText={errors.leaveMessageOnAttempt?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ControlledTextField
                    name='answeringMachineMessage'
                    control={control}
                    rows={3}
                    label='Leave Message On Answering Machine - Message'
                    disabled={isLoading}
                    error={Boolean(errors.answeringMachineMessage)}
                    helperText={errors.answeringMachineMessage?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ControlledCheckbox
                    name='allCallbacksAsPreview'
                    label='Place group-assigned callbacks as preview calls?'
                    control={control}
                    disabled={isLoading}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ControlledTextField
                    name='trunk'
                    control={control}
                    label='Trunk'
                    disabled={isLoading}
                    error={Boolean(errors.trunk)}
                    helperText={errors.trunk?.message}
                  />
                </Grid>
              </>
            )}

            {isConnectPredictive && (
              <>
                <Grid item xs={12}>
                  <Controller
                    name='sourcePhoneNumber'
                    control={control}
                    rules={{
                      required: 'Source phone number is required',
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        onChange={(e, data) => {
                          field.onChange(data);
                        }}
                        options={endpoints}
                        disabled={isLoading}
                        disableClearable
                        getOptionLabel={(option) => option || ''}
                        renderOption={(props, option) => (
                          <li {...props} ref={intersectionObserverRef} key={option}>
                            <Typography variant='body1' color='textPrimary' component='p'>
                              {option}
                            </Typography>
                          </li>
                        )}
                        renderInput={({ inputProps, ...rest }) => (
                          <TextField
                            {...rest}
                            label='Source Phone Number'
                            variant='outlined'
                            required={true}
                            error={Boolean(errors.sourcePhoneNumber)}
                            helperText={errors.sourcePhoneNumber?.message}
                            inputProps={{ ...inputProps, readOnly: true }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={isLoading}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default PredictiveSettingsForm;
