import CancelIcon from '@mui/icons-material/Cancel';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import BulkRemoveConfirmModal from '~pages/CampaignManagement/LeadListDetails/LeadListLeads/BulkRemoveConfirmModal';
import useCampaignListLeadSearch from '~pages/CampaignManagement/LeadListDetails/useCampaignListLeadSearch';
import { PolicyType, isAllowedRole, useAuth } from '~providers/AuthProvider';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';
import { sortObject } from '~utils/Functions';

import { Lead, LeadStatusType } from '../../domain';
import LeadCard from './LeadCard';

interface Query {
  search?: string;
  leadStatus?: { label: string; value: LeadStatusType }[];
}

const leadStatusList = [
  {
    label: 'Contacted',
    value: LeadStatusType.Contacted,
  },
  {
    label: 'Washed',
    value: LeadStatusType.Washed,
  },
  {
    label: 'Filtered',
    value: LeadStatusType.Filtered,
  },
  {
    label: 'Opt-out',
    value: LeadStatusType.OptOut,
  },
  {
    label: 'No Endpoints',
    value: LeadStatusType.NoEndpoints,
  },
  {
    label: 'Excluded',
    value: LeadStatusType.Excluded,
  },
  {
    label: 'Duplicate',
    value: LeadStatusType.Duplicate,
  },
  {
    label: 'Building',
    value: LeadStatusType.Building,
  },
  {
    label: 'Awaiting Retry',
    value: LeadStatusType.AwaitingRetry,
  },
  {
    label: 'Awaiting Start',
    value: LeadStatusType.AwaitingStart,
  },
  {
    label: 'Ready',
    value: LeadStatusType.Ready,
  },
  {
    label: 'Out of Hours',
    value: LeadStatusType.OutOfHours,
  },
  {
    label: 'Finished',
    value: LeadStatusType.Finished,
  },
  {
    label: 'Finished Today',
    value: LeadStatusType.FinishedToday,
  },
  {
    label: 'Callback',
    value: LeadStatusType.Callback,
  },
  {
    label: 'No Skilled Agents',
    value: LeadStatusType.NoSkilledAgents,
  },
  {
    label: 'Assigned',
    value: LeadStatusType.Assigned,
  },
  {
    label: 'Initiated',
    value: LeadStatusType.Initiated,
  },
  {
    label: 'Connected',
    value: LeadStatusType.Connected,
  },
  {
    label: 'Invalid Endpoint',
    value: LeadStatusType.InvalidEndpoint,
  },
  {
    label: 'Disconnected',
    value: LeadStatusType.Disconnected,
  },
  {
    label: 'Removed',
    value: LeadStatusType.Removed,
  },
  {
    label: 'Expired',
    value: LeadStatusType.Expired,
  },
  {
    label: 'Awaiting SMS',
    value: LeadStatusType.AwaitingSMS,
  },
  {
    label: 'Inactive List',
    value: LeadStatusType.InactiveList,
  },
  {
    label: 'Missed Callback',
    value: LeadStatusType.MissedCallback,
  },
  {
    label: 'In Queue',
    value: LeadStatusType.InQueue,
  },
  {
    label: 'Awaiting Callback',
    value: LeadStatusType.AwaitingCallback,
  },
  {
    label: 'Replaced',
    value: LeadStatusType.Replaced,
  },
].sort(sortObject('label', 'asc'));

const LeadListLeads = () => {
  const { policies } = useAuth();
  const { pushNotification } = useNotification();
  const { campaignId, listId } = useParams() as { campaignId: string; listId: string };
  const [query, setQuery] = useState<Query>({
    search: undefined,
    leadStatus: undefined,
  });
  const isDiallerAdmin = isAllowedRole([PolicyType.DiallerAdmin], policies);

  const debouncedSearch = useDebounce(query.search, 500);
  const {
    loading,
    error,
    leads,
    hasMore,
    intersectionObserverRef: lastDataElement,
    downloadLink,
    remove: removeLead,
    bulkRemove: bulkRemoveLeads,
  } = useCampaignListLeadSearch(+campaignId, +listId, {
    search: debouncedSearch,
    leadStatus: query.leadStatus?.map((item) => item.value),
  });
  const [selectedLeads, setSelectedLeads] = useState<Lead[]>([]);
  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState<boolean>(false);
  const noSearchOrFilterSet = !query.search && !query.leadStatus;

  const toggleBulkDeleteOpen = () => {
    setOpenBulkDeleteModal((prev) => !prev);
  };

  const onSearchChange = async (e: ChangeEvent<any>) => {
    const { value } = e.target;
    setQuery((prev) => ({ ...prev, search: value }));
  };

  const onLeadStatusChange = (e: ChangeEvent<{}>, value: any) => {
    setQuery((prev) => ({ ...prev, leadStatus: value }));
  };

  const onRemoveLead = (leadId: number) => async () => {
    try {
      await removeLead(leadId);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }

    pushNotification('success', 'You have successfully updated the list.');
  };

  const onLeadSelection = (lead: Lead) => () => {
    setSelectedLeads((prev) => {
      const index = prev.findIndex((item) => item.id === lead.id);

      if (index === -1) {
        return [...prev, lead];
      } else {
        return prev.filter((item) => item.id !== lead.id);
      }
    });
  };

  const removeSelectedLead = (id: number) => {
    setSelectedLeads((prev) => {
      const newList = prev.filter((item) => item.id !== id);
      if (newList.length === 0) {
        setOpenBulkDeleteModal(false);
      }

      return newList;
    });
  };

  const onBulkRemoveLeads = async () => {
    try {
      await bulkRemoveLeads(selectedLeads.map((item) => item.id));
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }

    pushNotification('success', 'You have successfully updated the list.');
    setSelectedLeads([]);
    setOpenBulkDeleteModal(false);
  };

  const displayList = useMemo(
    () =>
      leads.map((item, index) => {
        const props = {
          ref: index === leads.length - 1 ? lastDataElement : undefined,
          key: item.id,
          lead: item,
          checked: Boolean(selectedLeads.find((l) => l.id === item.id)),
          canRemoveLeads: isDiallerAdmin,
          onCheckboxClick: onLeadSelection(item),
          onRemove: onRemoveLead(item.id),
        };

        return <LeadCard {...props} />;
      }),
    [leads, selectedLeads, lastDataElement, isDiallerAdmin, onRemoveLead],
  );

  return (
    <>
      <Grid sx={{ marginBottom: 2 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={3}>
          <TextField fullWidth variant='outlined' label='Search' id='search' name='search' onChange={onSearchChange} />
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            multiple
            fullWidth
            onChange={onLeadStatusChange}
            value={query.leadStatus || []}
            options={leadStatusList}
            filterSelectedOptions
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  deleteIcon={<CancelIcon />}
                  label={option.label}
                  {...getTagProps({ index })}
                  variant='filled'
                  color='primary'
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} id='leadStatus' name='leadStatus' label='Lead Status' variant='outlined' />
            )}
          />
        </Grid>

        {!isDiallerAdmin && (
          <Hidden smDown>
            <Grid item md={3}></Grid>
          </Hidden>
        )}

        {isDiallerAdmin && (
          <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              fullWidth
              variant='contained'
              onClick={toggleBulkDeleteOpen}
              disableElevation
              startIcon={<DeleteIcon />}
              disabled={selectedLeads.length === 0}
              color='primary'
              title='remove leads'>
              Remove
            </Button>
          </Grid>
        )}

        <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            fullWidth
            variant='contained'
            href={downloadLink}
            disableElevation
            startIcon={<CloudDownloadIcon />}
            color='primary'
            title='download list'>
            Download
          </Button>
        </Grid>
      </Grid>

      <AsyncLoader isLoading={loading && leads.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {leads.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && leads.length > 0 && <DotLoader align='center' />}
                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}
                {error && leads.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load leads
                  </Typography>
                )}
              </>
            )}

            {leads.length === 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text='No leads found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}

            {leads.length === 0 && noSearchOrFilterSet && <EmptyState type='no-items-2' text='No leads available' />}
          </Grid>
        </Grid>
      </AsyncLoader>

      <BulkRemoveConfirmModal
        open={openBulkDeleteModal}
        leads={selectedLeads}
        onClose={toggleBulkDeleteOpen}
        onRemoveItem={removeSelectedLead}
        onAccept={onBulkRemoveLeads}
      />
    </>
  );
};

export default LeadListLeads;
