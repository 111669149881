import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { CampaignListItem, CampaignType, CreateCampaign, DiallerType } from '~pages/CampaignManagement/domain';

import { createCampaign, getCampaigns, getCampaignsByNextUrl } from './api';
import { CampaignResponse } from './domain';

type Options = {
  accessFilterId?: number;
  archived?: boolean;
  diallerType?: DiallerType;
  campaignType?: CampaignType;
  shouldFetch?: boolean;
};

const useCampaignSearch = (
  search: string,
  options: Options = {
    archived: undefined,
    diallerType: undefined,
    campaignType: undefined,
    accessFilterId: undefined,
    shouldFetch: true,
  },
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [list, setList] = useState<CampaignListItem[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const accessFilterId = options.accessFilterId;
  const archived = options.archived;
  const diallerType = options.diallerType;
  const campaignType = options.campaignType;
  const shouldFetch = options.shouldFetch ?? true;

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: CampaignResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getCampaignsByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.campaigns]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: CampaignResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getCampaigns(search, archived, diallerType, campaignType, accessFilterId, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setList(resp.campaigns);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, [search, archived, diallerType, campaignType, accessFilterId]);

  /** Ref watches for element view intersection and loads more results. Note: Should only be assigned to last element in
   * a list
   * */
  const intersectionObserverRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const create = useCallback(
    async (data: CreateCampaign) => {
      try {
        await createCampaign(data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [reload],
  );

  useEffect(() => {
    setList([]);
  }, [search, archived, diallerType, campaignType, accessFilterId, shouldFetch]);

  useEffect(() => {
    const load = async (
      search: string,
      archived: boolean | undefined,
      diallerType: DiallerType | undefined,
      campaignType: CampaignType | undefined,
      accessFilterId: number | undefined,
    ) => {
      setLoading(true);
      setError(false);

      let resp: CampaignResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getCampaigns(search, archived, diallerType, campaignType, accessFilterId, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.campaigns]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    };

    if (shouldFetch) {
      load(search, archived, diallerType, campaignType, accessFilterId);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [search, archived, diallerType, campaignType, accessFilterId, shouldFetch]);

  return { loading, error, list, hasMore, create, intersectionObserverRef };
};

export default useCampaignSearch;
