import axios, { CancelTokenSource } from 'axios';
import md5 from 'nano-md5';
import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';

import { AccessFilter } from '~pages/SystemManagement/domain';
import { useAuth } from '~providers/AuthProvider';
import { useNotification } from '~providers/NotificationProvider';
import { getUserAccessFilter, updateUserAccessFilter } from '~providers/UserPreferencesProvider/api';
import { UserAccessFilter } from '~providers/UserPreferencesProvider/domain';

type UserPreferencesContextProps = {
  avatarUrl: string;
  accessFilter: UserAccessFilter | undefined;
  setUserAccessFilter: (data?: AccessFilter) => Promise<void>;
};

type Props = {
  children: ReactNode;
};

export const UserPreferencesContext = createContext<UserPreferencesContextProps | undefined>(undefined);

export const useUserPreferences = (): UserPreferencesContextProps => {
  return useContext(UserPreferencesContext) as UserPreferencesContextProps;
};

const gravatarIcon = (username: string) => `https://www.gravatar.com/avatar/${md5(username)}?default=404&size=64`;

const UserPreferencesProvider = ({ children }: Props) => {
  const { pushNotification } = useNotification();
  const [initialPass, setInitialPass] = useState<boolean>(true);
  const { username } = useAuth();
  const [accessFilter, setAccessFilter] = useState<UserAccessFilter | undefined>(undefined);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    const load = async () => {
      let af: UserAccessFilter | undefined;

      try {
        axiosCancelRef.current = axios.CancelToken.source();
        af = await getUserAccessFilter(axiosCancelRef.current);
      } catch (e) {
        // Silently fail from a users perspective
        console.error("Unable to get user's access filter preferences");
        return;
      }

      setAccessFilter(af);
      setInitialPass(false);
    };

    load();
    const interval = window.setInterval(() => load(), 8_000);

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
      clearInterval(interval);
    };
  }, []);

  const setUserAccessFilter = async (data?: AccessFilter) => {
    // // Cancel any pending get request in the off chance
    axiosCancelRef.current.cancel();

    try {
      await updateUserAccessFilter(data?.id || undefined);
    } catch (e) {
      pushNotification('error', 'Failed to set access filter');
      return;
    }

    let newState: UserAccessFilter | undefined = undefined;

    if (data) {
      newState = {
        id: data.id,
        name: data.name,
        archived: data.archived,
      };
    }

    setAccessFilter(newState);
  };

  const context: UserPreferencesContextProps = {
    avatarUrl: gravatarIcon(username),
    accessFilter: accessFilter,
    setUserAccessFilter: setUserAccessFilter,
  };

  if (initialPass) {
    return null;
  }

  return (
    <>
      <UserPreferencesContext.Provider value={context}>{children}</UserPreferencesContext.Provider>
    </>
  );
};

export default UserPreferencesProvider;
