import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import grey from '@mui/material/colors/grey';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import ContentSpacer from '~components/ContentSpacer';
import { Lead, LeadStatusType, leadStatusColor } from '~pages/CampaignManagement/domain';

type Props = {
  open: boolean;
  leads: Lead[];
  onClose: () => void;
  onRemoveItem: (id: number) => void;
  onAccept: () => Promise<void>;
};

type SelectedLeadProps = {
  name: string;
  status: LeadStatusType;
  onClear?: () => void;
};

const SelectedLead = ({ name, status, onClear }: SelectedLeadProps) => {
  const baseColor = leadStatusColor[status]?.baseColor || grey['600'];

  return (
    <ContentSpacer spacing={1}>
      <Box
        sx={{
          width: '100%',
          border: `1px solid rgba(0, 0, 0, 0.12)`,
          borderRadius: 2,
          padding: '8px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Box>
          {name}

          <br />

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
            <Chip
              sx={{
                marginTop: 0.5,
                textTransform: 'uppercase',
                fontSize: 10,
                borderRadius: 1,
                height: 20,
                lineHeight: '21px',
                color: '#ffffff',
                fontWeight: 700,
                backgroundColor: baseColor,
              }}
              label={status}
            />
          </Box>
        </Box>
        {onClear && <Button onClick={onClear}>Clear</Button>}
      </Box>
    </ContentSpacer>
  );
};

export const BulkRemoveConfirmModal = ({ open, leads, onClose, onRemoveItem, onAccept }: Props) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const leadDisplay = leads.map((item, index) => {
    const props = {
      name: item.name,
      status: item.leadStatus,
      onClear: () => onRemoveItem(item.id),
    };

    if (index === leads.length - 1) {
      return (
        <Box key={item.id} sx={{ marginBottom: 1 }}>
          <SelectedLead {...props} />
        </Box>
      );
    } else {
      return <SelectedLead key={item.id} {...props} />;
    }
  });

  const handleAccept = async () => {
    setSubmitting(true);

    try {
      await onAccept();
    } catch (e) {
      // do nothing
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ActionDialog
      open={open}
      title='Are you sure you want to do this?'
      disableContentPadding
      loading={submitting}
      content={
        <Box sx={{ padding: '16px 24px 0 24px' }}>
          <DialogContentText sx={{ marginBottom: 2 }}>
            You are about to remove the following leads from the dialling list, once you have completed this action it
            cannot be undone.
          </DialogContentText>

          <Box sx={{ maxHeight: 400, minHeight: 0, height: '100%', overflow: 'auto' }}>{leadDisplay}</Box>
        </Box>
      }
      onClose={onClose}
      onAccept={handleAccept}
      primaryActionTitle='Remove'
    />
  );
};

export default BulkRemoveConfirmModal;
