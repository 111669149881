import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import React, { forwardRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import AccessFilterMenu from '~components/SiteHeader/AccessFilterMenu';
import organisations from '~organisations';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { PolicyType, isAllowedRole, useAuth } from '~providers/AuthProvider';
import { useRoutes } from '~providers/RouteProvider';
import Page from '~providers/RouteProvider/Page';

import NotificationDrawer from './NotificationDrawer';
import UserMenu from './UserMenu';

const headerHeight = 64;
const drawerWidth = 300;

type CustomizedAppBarProps = MUIStyledCommonProps<Theme> &
  AppBarProps & {
    isOpen: boolean;
  };

const CustomizedAppBar = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'isOpen' })<CustomizedAppBarProps>(
  ({ theme, isOpen }) => ({
    height: headerHeight,
    backgroundColor: '#333',
    width: isOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
    marginLeft: isOpen ? 300 : 0,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: isOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
    }),
  }),
);

type CustomizedDrawerProps = MUIStyledCommonProps<Theme> &
  DrawerProps & {
    isOpen: boolean;
  };

const CustomizedDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'isOpen' })<CustomizedDrawerProps>(
  ({ theme, isOpen }) => ({
    'width': isOpen ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`,
    'flexShrink': 0,
    'whiteSpace': 'nowrap',
    'overflowX': isOpen ? undefined : 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: isOpen ? drawerWidth : `calc(${theme.spacing(9)} + 1px)`,
    },
    'transition': theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: isOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
    }),
    '& .MuiDrawer-paper': {
      width: isOpen ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up('sm')]: {
        width: isOpen ? drawerWidth : `calc(${theme.spacing(9)} + 1px)`,
      },
      overflowX: isOpen ? undefined : 'hidden',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: isOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
      }),
    },
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const SiteHeader = () => {
  const { policies } = useAuth();
  const appConfig = useAppConfiguration();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const logo = organisations[appConfig.orgReference].logo;
  const routes = useRoutes();
  const canUserAccessFilter = isAllowedRole(
    [
      PolicyType.TeamLeader,
      PolicyType.Manager,
      PolicyType.WorkforceAnalyst,
      PolicyType.QualityAnalyst,
      PolicyType.DiallerAdmin,
    ],
    policies,
  );

  // Close draw on naviation selection
  useEffect(() => {
    setOpenDrawer(false);
  }, [location.pathname]);

  const menuItemDisplay = routes.map(({ path, isTopLevel, isSelected, title, icon: Icon }: Page, index: number) => {
    // We only want to render top level routes
    if (!isTopLevel) return null;

    const RouterLink = forwardRef((linkProps, ref) => <Link to={path} {...linkProps} />);

    return (
      <Tooltip key={index} disableFocusListener disableTouchListener disableHoverListener={openDrawer} title={title}>
        <ListItem
          sx={{
            'paddingLeft': {
              xs: 2,
              sm: 3,
            },
            '&.Mui-selected': {
              'backgroundColor': 'rgba(0, 0, 0, 0.08)',
              ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
              },
            },
          }}
          selected={isSelected}
          button
          component={RouterLink}>
          <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      </Tooltip>
    );
  });

  const toggleDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };

  return (
    <>
      <CustomizedAppBar isOpen={openDrawer} elevation={0} position='fixed'>
        <Toolbar sx={{ height: headerHeight, minHeight: headerHeight, maxHeight: headerHeight }}>
          {!openDrawer && (
            <IconButton color='inherit' aria-label='open drawer' onClick={toggleDrawer} edge='start'>
              {!openDrawer && <MenuIcon />}
            </IconButton>
          )}

          <Box
            sx={{
              maxWidth: 200,
              minWidth: 0,
              height: '100%',
              padding: '10px 0',
              marginLeft: 2,
              marginRight: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img
              style={{ objectFit: 'contain', height: '100%', width: '100%' }}
              src={logo}
              alt={`${appConfig.orgReference} logo`}
            />
          </Box>

          <Typography sx={{ flexGrow: 1, color: 'primary.contrastText' }} variant='h6'>
            {appConfig.web.name}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <NotificationDrawer />

            {canUserAccessFilter && <AccessFilterMenu />}

            <UserMenu />
          </Box>
        </Toolbar>
      </CustomizedAppBar>

      <CustomizedDrawer anchor='left' variant='permanent' elevation={11} isOpen={openDrawer}>
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>

        <Divider />

        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>{menuItemDisplay}</List>
      </CustomizedDrawer>
    </>
  );
};

export default SiteHeader;
