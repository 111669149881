import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PhoneIcon from '@mui/icons-material/Phone';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import grey from '@mui/material/colors/grey';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, { ForwardedRef, KeyboardEvent, MouseEvent, forwardRef, useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import OberonCard from '~components/OberonCard';
import LeadDetailsModal from '~pages/CampaignManagement/LeadListDetails/LeadListLeads/LeadDetailsModal';

import { Lead, LeadStatusType, leadStatusColor } from '../../../domain';

interface LeadCardProps {
  lead: Lead;
  checked: boolean;
  canRemoveLeads: boolean;
  onCheckboxClick: () => void;
  onRemove: () => void;
}

const menuEnabled: { [key in LeadStatusType]: boolean } = {
  [LeadStatusType.Assigned]: true,
  [LeadStatusType.AwaitingRetry]: true,
  [LeadStatusType.AwaitingStart]: true,
  [LeadStatusType.Building]: true,
  [LeadStatusType.Callback]: true,
  [LeadStatusType.Connected]: false,
  [LeadStatusType.Contacted]: false,
  [LeadStatusType.Duplicate]: true,
  [LeadStatusType.Excluded]: false,
  [LeadStatusType.Filtered]: false,
  [LeadStatusType.Finished]: false,
  [LeadStatusType.FinishedToday]: false,
  [LeadStatusType.Initiated]: false,
  [LeadStatusType.NoEndpoints]: true,
  [LeadStatusType.NoSkilledAgents]: true,
  [LeadStatusType.OptOut]: false,
  [LeadStatusType.OutOfHours]: true,
  [LeadStatusType.Ready]: true,
  [LeadStatusType.Washed]: false,
  [LeadStatusType.InvalidEndpoint]: true,
  [LeadStatusType.Disconnected]: true,
  [LeadStatusType.Removed]: false,
  [LeadStatusType.Expired]: false,
  [LeadStatusType.AwaitingSMS]: false,
  [LeadStatusType.InactiveList]: true,
  [LeadStatusType.MissedCallback]: false,
  [LeadStatusType.InQueue]: false,
  [LeadStatusType.AwaitingCallback]: true,
  [LeadStatusType.Replaced]: false,
};

const LeadCard = forwardRef(
  ({ lead, checked, canRemoveLeads, onCheckboxClick, onRemove }: LeadCardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
    const [performingRemoveAction, setPerformingRemoveAction] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const statusColor = leadStatusColor[lead.leadStatus];
    const isMenuEnabled = menuEnabled[lead.leadStatus];
    const isRemoved = lead.leadStatus === LeadStatusType.Removed;

    // If backgroundColor unknown default to grey
    const baseColor = statusColor?.baseColor || grey['600'];
    // If lightColor unknown default to light grey variant
    const lightColor = statusColor?.lightColor || grey['100'];
    const statusName = lead.leadStatus.replace(/-/gi, ' ');
    // Does the card display the menu options or not
    const hasMenuAccess = isMenuEnabled ?? false;

    const handleMenuOpen = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAnchorEl(null);
    };

    const handleRemoveConfirmationOpen = (e: MouseEvent) => {
      e.stopPropagation();
      setAnchorEl(null);
      setRemoveConfirmOpen(true);
    };

    const handleRemoveConfirmationClose = () => {
      setRemoveConfirmOpen(false);
    };

    const handleRemove = async () => {
      setPerformingRemoveAction(true);

      try {
        await onRemove();
      } catch (e) {
        return;
      } finally {
        setPerformingRemoveAction(false);
      }

      setRemoveConfirmOpen(false);
    };

    const onModalOpen = () => {
      setOpenModal(true);
    };

    const onModalClose = () => {
      setOpenModal(false);
    };

    const handleCheckboxClick = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onCheckboxClick();
    };

    return (
      <>
        <OberonCard
          ref={ref}
          onClick={onModalOpen}
          titleFontWeight={400}
          title={lead.name}
          backgroundColor={checked ? grey[50] : undefined}
          subHeader={
            <Chip
              sx={{
                marginTop: 0.5,
                textTransform: 'uppercase',
                fontSize: 10,
                borderRadius: 1,
                height: 20,
                lineHeight: '21px',
                color: '#ffffff',
                fontWeight: 700,
                backgroundColor: baseColor,
              }}
              label={statusName}
            />
          }
          avatar={
            <>
              {!isRemoved && canRemoveLeads && (
                <Box sx={{ position: 'absolute', left: -1, zIndex: 1, top: -1 }}>
                  <Checkbox sx={{}} onClick={handleCheckboxClick} onKeyPress={handleCheckboxClick} checked={checked} />
                  <Box
                    sx={{
                      backgroundColor: '#fff',
                      position: 'absolute',
                      left: 12,
                      top: 12,
                      width: 18,
                      height: 18,
                      zIndex: -1,
                      borderRadius: 0.5,
                    }}
                  />
                </Box>
              )}
              <Avatar style={{ backgroundColor: lightColor }}>
                <PhoneIcon style={{ color: baseColor, display: 'inline-block', marginTop: 4, fontSize: 27 }} />
              </Avatar>
            </>
          }
          action={
            hasMenuAccess &&
            canRemoveLeads && (
              <>
                <IconButton
                  aria-controls='lead-menu'
                  aria-haspopup='true'
                  onKeyPress={handleMenuOpen}
                  onClick={handleMenuOpen}>
                  <MoreHorizIcon />
                </IconButton>
                <Menu id='lead-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  <MenuItem onClick={handleRemoveConfirmationOpen}>Remove</MenuItem>
                </Menu>
              </>
            )
          }
          footer={
            <>
              <Typography fontSize={12} fontWeight={400} variant='body1' component='span' color='textPrimary'>
                Endpoints:
              </Typography>{' '}
              <Typography fontSize={12} fontWeight={400} variant='body2' component='span' color='textSecondary'>
                {lead.endpoints.join(', ')}
              </Typography>
            </>
          }
          footerBorderColor={baseColor}
        />

        <LeadDetailsModal open={openModal} lead={lead} onClose={onModalClose} />

        <ActionDialog
          open={removeConfirmOpen}
          title='Are you sure you want to do this?'
          content={`You are about to remove the lead ${lead.name} from the dialling list, once you have completed this action it cannot be undone.`}
          onClose={handleRemoveConfirmationClose}
          onAccept={handleRemove}
          loading={performingRemoveAction}
          primaryActionTitle='Remove'
        />
      </>
    );
  },
);

export default LeadCard;
