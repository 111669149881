import React, { useEffect, useRef, useState } from 'react';

import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useAssignedDiallerGroup } from '~providers/AssignedDiallerGroupProvider';
import { useConnect } from '~providers/ConnectProvider';
import { useLogRocket } from '~providers/LogRocketProvider';
import { assertUnreachable } from '~utils/Functions';

import { ConnectDialler } from '../ConnectDialler';

enum AsteriskAgentState {
  LoggedIn = 'Logged In',
  Initialised = 'Initialised',
  Ringing = 'Ringing',
  Unavailable = 'Unavailable',
  InQueue = 'In Queue',
  Preview = 'Preview',
  Connected = 'Connected',
  AfterCall = 'AfterCallWork',
  Disconnected = 'Disconnected',
}

const asteriskAgentStateList = Object.values(AsteriskAgentState) as string[];

// Custom Event guard
const isCustomEvent = (event: Event | CustomEvent): event is CustomEvent => {
  return (event as CustomEvent).detail !== undefined;
};

const pushConnectEvent = (hasContact: boolean) => {
  window.dispatchEvent(new CustomEvent('connectContact', { detail: { hasContact } }));
};

const pushGroupChangeEvent = () => {
  window.dispatchEvent(new CustomEvent('groupChange', { detail: null }));
};

const AsteriskDialler = () => {
  const appConfig = useAppConfiguration();
  const logRocket = useLogRocket();
  const { agent, currentVoiceContact } = useConnect();
  const assignedGroup = useAssignedDiallerGroup();
  const [showConnectDialler, setShowConnectDialller] = useState<boolean>(Boolean(currentVoiceContact));
  const [showAsteriskDialler, setShowAsteriskDialller] = useState<boolean>(true);
  const previousAsteriskStatus = useRef<string | undefined>(undefined);
  const predictiveConfig = appConfig.extensions.predictive ?? {
    defaultTrunk: '',
    diallerURL: '',
    sipWebsocket: '',
    sipHost: '',
  };
  const [initialRun, setInitialRun] = useState(true);

  // Relative path to svelte predictive smartphone
  // In dev mode this is proxied to http://localhost:5555/ within watch.js
  // In prod will be packages as static file under public/predictive-softphone/build
  const url = '/predictive-softphone/build/bundle.js';

  // Log rocket key setup
  useEffect(() => {
    logRocket.trackKeyValue('dialler', 'predictive');
  }, []);

  // Init event
  useEffect(() => {
    if (initialRun) {
      setInitialRun(false);
      return;
    }

    (window as any).__getPredictiveSoftphoneMetadata = (key: string) => {
      const data: { [key: string]: any } = {
        diallerURL: predictiveConfig.diallerURL + '/agent-ws',
        sipWebsocket: predictiveConfig.sipWebsocket,
        sipHost: predictiveConfig.sipHost,
        groupId: assignedGroup.configuredGroup?.diallerGroupId || assignedGroup.group.diallerGroupId,
        defaultTrunk: predictiveConfig.defaultTrunk,
        routingProfile: agent.routingProfile,
        defaultOutboundQueue: agent.defaultOutboundQueue,
        defaultOutboundQueueARN: agent.defaultOutboundQueueARN,
        canPauseCallRecordings: appConfig.extensions.pauseRecordings,
      };

      if (key in data) {
        return data[key];
      }

      return null;
    };

    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);

    // Cleans up created global function
    return function cleanup() {
      delete (window as any).__getPredictiveSoftphoneMetadata;

      // Defined within /predictive-softphone/build/bundle.js
      if ((window as any).__destroyPredictiveSoftphone) {
        (window as any).__destroyPredictiveSoftphone();
      }

      document.body.removeChild(script);
    };
  }, [initialRun]);

  // Polling dialler group assignment to refresh display IF an agents dialler group has been changed
  useEffect(() => {
    if (assignedGroup.configuredGroup !== undefined) {
      assignedGroup.switchGroup();
    }
  }, [assignedGroup.configuredGroup]);

  // Listens to events of the asterisk/ connect dialler to change agent status
  useEffect(() => {
    const asteriskAgentStatusChange = (e: Event | CustomEvent<{ state: string }>) => {
      if (isCustomEvent(e) && previousAsteriskStatus.current !== e.detail.state) {
        // If not found in enum list assume custom state
        if (asteriskAgentStateList.includes(e.detail.state)) {
          const state: AsteriskAgentState = e.detail.state as AsteriskAgentState;

          switch (state) {
            case AsteriskAgentState.Connected:
            case AsteriskAgentState.AfterCall: {
              agent.setOffline();
              break;
            }
            case AsteriskAgentState.LoggedIn:
            case AsteriskAgentState.Ringing:
            case AsteriskAgentState.Unavailable:
            case AsteriskAgentState.Preview:
            case AsteriskAgentState.Disconnected:
            case AsteriskAgentState.Initialised: {
              agent.setOffline();
              break;
            }
            case AsteriskAgentState.InQueue: {
              // allow calls from connect, but wait for the agent to go Unavailable before switching campaign.
              agent.setOnline();
              break;
            }
            default: {
              assertUnreachable(state);
            }
          }
        } else {
          agent.setOffline();
        }

        previousAsteriskStatus.current = e.detail.state;
      }
    };

    window.addEventListener('asteriskAgentStateChange', asteriskAgentStatusChange);

    // Cleans up custom event listener
    return () => {
      window.removeEventListener('asteriskAgentStateChange', asteriskAgentStatusChange);
    };
  }, []);

  // Emits events around current voice contact
  const hasContact = Boolean(currentVoiceContact);
  useEffect(() => {
    pushConnectEvent(hasContact);
    setShowAsteriskDialller(!hasContact);
    setShowConnectDialller(hasContact);
    console.log('+ Emitting connect contact event');
  }, [hasContact]);

  // Updates global __getPredictiveSoftphoneMetadata function and emits event around campaign changing
  useEffect(() => {
    if (assignedGroup.configuredGroup !== undefined) {
      (window as any).__getPredictiveSoftphoneMetadata = (key: string) => {
        const data: { [key: string]: any } = {
          diallerURL: predictiveConfig.diallerURL + '/agent-ws',
          sipWebsocket: predictiveConfig.sipWebsocket,
          sipHost: predictiveConfig.sipHost,
          groupId: assignedGroup.configuredGroup?.diallerGroupId,
          defaultTrunk: predictiveConfig.defaultTrunk,
          routingProfile: agent.routingProfile,
          defaultOutboundQueue: agent.defaultOutboundQueue,
          defaultOutboundQueueARN: agent.defaultOutboundQueueARN,
        };

        if (key in data) {
          return data[key];
        }

        return null;
      };

      pushGroupChangeEvent();
    }
  }, [assignedGroup.configuredGroup?.diallerGroupId]);

  return (
    <>
      {showConnectDialler && <ConnectDialler />}
      <div style={{ display: showAsteriskDialler ? 'block' : 'none' }} id='predictive-softphone' />
    </>
  );
};

export default AsteriskDialler;
