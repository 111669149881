import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { DiallerGroup, UpdateDiallerGroup } from '~pages/CampaignManagement/domain';

import { archiveDiallerGroupById, getDiallerGroup, updateDiallerGroup } from './api';

const useDiallerGroup = (diallerGroupId: number) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [data, setData] = useState<DiallerGroup | undefined>(undefined);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  const reload = useCallback(async () => {
    setLoading(true);
    setError(undefined);

    let resp: DiallerGroup | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getDiallerGroup(diallerGroupId, axiosCancelRef.current);
    } catch (e) {
      setError((e as Error).message);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setData(resp);
    setLoading(false);
  }, [diallerGroupId]);

  const update = useCallback(
    async (data: Partial<UpdateDiallerGroup>) => {
      try {
        await updateDiallerGroup(diallerGroupId, data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [diallerGroupId],
  );

  const archive = useCallback(async () => {
    try {
      await archiveDiallerGroupById(diallerGroupId);
    } catch (e) {
      // Do nothing
      return Promise.reject(e);
    }

    await reload();
  }, [diallerGroupId]);

  useEffect(() => {
    setData(undefined);
  }, [diallerGroupId]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(undefined);

      let resp: DiallerGroup | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getDiallerGroup(diallerGroupId, axiosCancelRef.current);
      } catch (e) {
        setError((e as Error).message);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setData(resp);
      setLoading(false);
    })();

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [diallerGroupId]);

  return { loading, error, data, reload, update, archive };
};

export default useDiallerGroup;
