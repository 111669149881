import axios, { AxiosResponse } from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  ConferenceTransferItem,
  ConferenceTransferListDecoder,
  ExternalTransferItem,
  ExternalTransferListDecoder,
  PaymentDetailsDecoder,
} from './domain';

export const getExternalTransferList = async (): Promise<ExternalTransferItem[]> => {
  let resp: AxiosResponse;

  try {
    resp = await axios({
      method: 'GET',
      url: `/api/external-transfer/list`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = ExternalTransferListDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getConferenceTransferList = async (): Promise<ConferenceTransferItem[]> => {
  let resp: AxiosResponse;

  try {
    resp = await axios({
      method: 'GET',
      url: `/api/conference-transfer/list`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = ConferenceTransferListDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getPaymentDetails = async (paymentId: string): Promise<Record<string, string>> => {
  const path = `/api/payment-gateway/payment/${paymentId}`;
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = PaymentDetailsDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};
