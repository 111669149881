import logrocket from 'logrocket';
import React, { useEffect } from 'react';

import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useAuth } from '~providers/AuthProvider';

interface LogRocketProviderProps {
  trackEvent: (key: TrackEvent) => void;
  trackKeyValue: (key: TrackKey, value: string) => void;
  // note(jae): 2021-05-12
  // delete after 3 months if unused
  //setUserTraits: (traits: Partial<UserTraits>) => void;
}

/**
 * UserTraits is where we define all possible user traits we've defined for this
 * project. This allows us to easily vet which ones exist by simply looking at this
 * definition.
 */
// note(jae): 2021-05-12
// delete after 3 months if unused
//interface UserTraits {}

type TrackKey = 'dialler' | 'aws_connect_queue_arn' | 'aws_connect_queue_name';

type TrackEvent = 'transfer' | 'internal_transfer' | 'external_transfer' | 'conference_transfer' | 'payment_gateway';

const LogRocketContext = React.createContext<LogRocketProviderProps | undefined>(undefined);

export const useLogRocket = (): LogRocketProviderProps => {
  const r = React.useContext(LogRocketContext);
  if (r === undefined) {
    throw new Error('Missing "LogRocketProvider" context');
  }
  return r;
};

export const LogRocketProvider = ({ children }: { children: React.ReactNode }) => {
  const config = useAppConfiguration();
  const auth = useAuth();
  const isLogRocketEnabled = Boolean(config.web.logrocketAppId);

  // note(jae): 2021-05-12
  // I looked into using "User Traits" (2nd param for logrocket.identify) for storing key-values against a session.
  // This doesn't work out well as "User Traits" persist *across* all sessions in LogRocket so
  // it only makes sense to leverage this if we wanted to log user-specific information.
  //
  // We might end up having another use for this in the near future though so...
  // If this isn't used within 3 months of the timestamp above, delete this.
  /* const setUserTraits = (traits: Partial<UserTraits>): void => {
    if (!isLogRocketEnabled) {
      return;
    }
    console.log(`+ logrocket set user traits`, traits);
    logrocket.identify(traits as { [name: string]: any });
  }; */

  const trackEvent = (eventName: TrackEvent): void => {
    if (!isLogRocketEnabled) {
      return;
    }
    console.log(`+ logrocket track event`, eventName);
    logrocket.track(eventName);
  };

  const trackKeyValue = (key: TrackKey, value: string): void => {
    if (!isLogRocketEnabled) {
      return;
    }
    // note(jae): 2021-05-12
    // other than "User Traits" the only other custom option I could find within
    // LogRocket to track custom data was the event tracking API
    // (the error filtering logic that comes with "Pro" version we don't currently
    // have might've worked too but... we don't have that)
    //
    // The intent here is that we can filter by a "Custom Event" that "Starts With"
    // something like "dialler" to just get any dialler events, just "Is" filter by
    // "dialler predictive" or "dialler inbound"
    //
    // I considered making the seperation value ':' instead of ' ' below but opted
    // for a single space because values rarely start with a space, but it's not unlikely
    // that they could start with ':' or something else.
    const eventName = key + ' ' + value;
    console.log(`+ logrocket track event (key-value)`, eventName);
    logrocket.track(eventName);
  };

  useEffect(() => {
    if (!isLogRocketEnabled) {
      console.log('- skipped logrocket initialization');
      return;
    }
    logrocket.init(config.web.logrocketAppId);
    logrocket.identify(auth.username);
    console.log(`+ logrocket initialized with app id ${config.web.logrocketAppId} for username ${auth.username}`);
  }, []);

  const context: LogRocketProviderProps = {
    trackEvent: trackEvent,
    trackKeyValue: trackKeyValue,
    // note(jae): 2021-05-12
    // delete after 3 months if unused
    //setUserTraits: setUserTraits,
  };
  return <LogRocketContext.Provider value={context}>{children}</LogRocketContext.Provider>;
};
