import * as j from '@mojotech/json-type-validation';
import axios from 'axios';
import queryString from 'query-string';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { APIError } from '~services/Errors';

import EmptyState, { StateType } from '../../components/EmptyState';

export enum PolicyType {
  Agent = 'agent',
  TeamLeader = 'team-leader',
  Manager = 'manager',
  QualityAnalyst = 'quality-analyst',
  WorkforceAnalyst = 'workforce-analyst',
  DiallerAdmin = 'dialler-admin',
}

type AuthContextProps = {
  policies: PolicyType[];
  username: string;
  fullName: string;
  signOut: () => void;
};

type Props = {
  children: ReactNode;
};

interface ErrorState {
  type: StateType;
  text: string;
  subText: string;
  actionText?: string;
  action?: () => void;
}

const loginURL = '/api/identity/login/authorize';
const logoutURL = '/api/identity/logout';

// If user has one of the allowed scope roles then they are allowed
export const isAllowedRole = (required: PolicyType[], user: PolicyType[]): boolean => {
  if (required.length === 0) return true; // allow if no required scopes exists

  const a = new Set(required);
  const b = new Set(user);
  return !![...a].find((i) => b.has(i));
};

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
  return useContext(AuthContext) as AuthContextProps;
};

const validateScopes = (policies: string[]): PolicyType[] => {
  const known = Object.values(PolicyType);
  const unknown: string[] = [];
  const valid: PolicyType[] = [];

  // Filter valid and invalid scopes into separate lists
  for (const policy of policies) {
    if (known.indexOf(policy as any) === -1) {
      unknown.push(policy);
    } else {
      valid.push(policy as any);
    }
  }

  // In the event that we do have unknown scopes we want to log them
  if (unknown.length > 0) {
    console.warn('Unknown Policies: ', unknown);
  }

  return valid;
};

const signOutUser = (): void => {
  localStorage.clear();
  window.location.href = logoutURL;
};

const whoAmIValidStatuses = new Set([200, 401, 403]);

const whoAmIOrError = async () => {
  try {
    // has to be awaited here to catch the error
    return await axios.get('/api/identity/whoami', {
      validateStatus: (status: number) => whoAmIValidStatuses.has(status),
    });
  } catch (e) {
    return new APIError(401, 'Failed to determine your identity.');
  }
};

const AuthProvider = ({ children }: Props) => {
  const [context, setContext] = useState<AuthContextProps | undefined>(undefined);
  const [error, setError] = useState<ErrorState | null>(null);

  if (window.location.pathname.startsWith('/logged_out')) {
    return (
      <EmptyState
        fullHeight
        type='offline'
        text='Logged out'
        subText='You have been logged out'
        action={() => (window.location.href = loginURL)}
        actionText='Log back in'
      />
    );
  }

  // Return an error display for any login based error with the verify endpoint redirect path
  if (window.location.pathname.startsWith('/login_error')) {
    const { code } = queryString.parse(window.location.search);

    switch (code) {
      case '401': {
        return (
          <EmptyState
            fullHeight
            type='puzzle'
            text='Login Error'
            subText='Looks like you have not been authenticated with this application.'
          />
        );
      }
      case '403': {
        return (
          <EmptyState
            fullHeight
            type='puzzle'
            text='Login Error'
            subText='Looks like you do not have permission to access this application.'
          />
        );
      }
      case '500': {
        return (
          <EmptyState fullHeight type='puzzle' text='Login Error' subText='Unable to login due to server error.' />
        );
      }
      default: {
        console.error('Unknown Error Code: ', code);

        return (
          <EmptyState fullHeight type='puzzle' text='Login Error' subText='Unable to login due to unknown error.' />
        );
      }
    }
  }

  useEffect(() => {
    (async () => {
      if (!context && !error) {
        const whoAmIResp = await whoAmIOrError();
        if (whoAmIResp instanceof APIError) {
          console.error(whoAmIResp);
          setError({
            type: 'puzzle',
            text: 'Login Error',
            subText:
              'An unexpected error occurred while trying to login. Contact the system administrator or ' +
              'try logging in again later.',
            actionText: 'Refresh',
            action: () => window.location.reload(),
          });
          return;
        }

        if (whoAmIResp.status === 401) {
          window.location.href = loginURL;
          return;
        }

        if (whoAmIResp.status === 403) {
          setError({
            type: 'puzzle',
            text: 'Login Error',
            subText: 'Looks like you do not have permission to access this application.',
            action: () => signOutUser(),
            actionText: 'Logout',
          });
          return;
        }

        const whoAmI = j
          .object({
            username: j.string(),
            full_name: j.string(),
            policies: j.array(j.string()),
          })
          .run(whoAmIResp.data);

        if (!whoAmI.ok) {
          setError({
            type: 'puzzle',
            text: 'Login Error',
            subText: 'An unexpected error occurred. Try again later.',
            actionText: 'Refresh',
            action: () => window.location.reload(),
          });
          return;
        }

        const policies = validateScopes(whoAmI.result.policies);
        // If no scopes we should catch and display an error for easy identification
        // as a logged-in user should have at least one scope present
        if (policies.length === 0) {
          setError({
            type: 'puzzle',
            text: 'Login Error',
            subText: 'Looks like you do not have permission to access this application.',
            action: () => signOutUser(),
            actionText: 'Logout',
          });
          return;
        }

        setContext({
          policies,
          username: whoAmI.result.username,
          fullName: whoAmI.result.full_name,
          signOut: signOutUser,
        });

        setError(null);
      }
    })();
  });

  return (
    <>
      {error !== null && (
        <EmptyState
          fullHeight
          type={error.type}
          text={error.text}
          subText={error.subText}
          action={error.action && error.action}
          actionText={error.actionText && error.actionText}
        />
      )}

      {context && !error && <AuthContext.Provider value={context}>{children}</AuthContext.Provider>}
    </>
  );
};

export default AuthProvider;
