import PeopleIcon from '@mui/icons-material/People';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import SignalCellularConnectedNoInternet1BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet1Bar';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AgentStatusBar, { StatisticItem } from '~components/AgentStatusBar';
import { CampaignType, RoutingProfile } from '~pages/CampaignManagement/domain';
import { useAgentStates } from '~providers/AgentStatesProvider';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useAssignedDiallerGroup } from '~providers/AssignedDiallerGroupProvider';
import { useAsync } from '~providers/AsyncProvider';
import { useAttempt } from '~providers/AttemptProvider';
import { useAuth } from '~providers/AuthProvider';
import { useConnect } from '~providers/ConnectProvider';
import {
  AgentStateType,
  ConnectAgentStateDefinition,
  ContactDirection,
  ContactStateType,
  TimeSeriesStats,
} from '~providers/ConnectProvider/domain';
import { AsyncAgentState, AuthorRole } from '~services/AsyncManager/domain';
import { getKeysSortedByNewestConversationCreatedDate } from '~services/AsyncManager/helpers';
import { calculatePercentage } from '~utils/Functions';

import { getGroupRoutingProfiles, postChangeRoutingProfile } from '../api';
import AsyncView from './AsyncView';
import VoiceView from './VoiceView';

enum ViewType {
  Voice = 'voice',
  Messaging = 'messaging',
}

const timeAgoFromSeconds = (seconds: number): string => {
  if (seconds === 0) {
    return 'N/A';
  }

  return DateTime.fromJSDate(new Date()).minus({ seconds: seconds }).toRelative() || 'N/A';
};

const getPacketLossStatistic = (callStatistics: TimeSeriesStats | undefined): StatisticItem => {
  let icon = SignalCellularConnectedNoInternet0BarIcon;
  let text = 'N/A';

  if (callStatistics !== undefined) {
    const percentage = calculatePercentage(callStatistics?.packetsLostDelta, callStatistics?.packetsCount);

    if (percentage > 10) {
      icon = SignalCellularConnectedNoInternet0BarIcon;
      text = 'Fatal';
    } else if (percentage <= 10 && percentage >= 5) {
      icon = SignalCellularConnectedNoInternet1BarIcon;
      text = 'Poor';
    } else if (percentage < 5) {
      icon = SignalCellular4BarIcon;
      text = 'Good';
    }
  }

  return { icon, text };
};

const fetchGroupRoutingProfilesOrEmptyArray = async (diallerGroupId: number) => {
  try {
    return await getGroupRoutingProfiles(diallerGroupId);
  } catch (e) {
    return [];
  }
};

export const ConnectDialler = () => {
  const appConfig = useAppConfiguration();
  const {
    agent: asyncAgent,
    customers,
    selectedCustomerKey,
    asyncManager,
    sessionActive: asyncSessionActive,
    disableMessageChimeRef,
    setActiveCustomer,
  } = useAsync();
  const {
    agent: connectAgent,
    currentVoiceContact,
    agentStateList,
    callStatistics,
    downloadSessionLogs,
  } = useConnect();
  const { connectMetrics } = useAgentStates();
  const { username: loggedInUserUsername } = useAuth();
  const { attempt } = useAttempt();
  const { group, configuredGroup, switchGroup } = useAssignedDiallerGroup();
  const [campaignRoutingProfiles, setCampaignRoutingProfiles] = useState<RoutingProfile[]>([]);
  const [searchParams, setSearchParams] = useSearchParams({
    view: ViewType.Voice,
  });

  const hasUnseenMessages: boolean = useMemo(() => {
    const customerKeys = Object.keys(customers);

    for (let cKey of customerKeys) {
      const conversationKeys = Object.keys(customers[cKey].conversations).map((n) => Number(n));
      for (let convKey of conversationKeys) {
        const messageKeys = Object.keys(customers[cKey].conversations[convKey].messages);

        for (let msgKey of messageKeys) {
          const msg = customers[cKey].conversations[convKey].messages[msgKey];
          if (msg.readTimestamp === undefined && msg.authorRole === AuthorRole.Customer) {
            return true;
          }
        }
      }
    }

    for (let cKey of customerKeys) {
      const conversationKeys = Object.keys(customers[cKey].conversations).map((n) => Number(n));
      const messages = customers[cKey].conversations[conversationKeys[conversationKeys.length - 1]].messages;
      const messageKeys = Object.keys(messages);

      const hasAgentOrCustomerMessages =
        messageKeys.filter((msgKey) => {
          return messages[msgKey].authorRole !== AuthorRole.System;
        }).length > 0;

      if (!hasAgentOrCustomerMessages) {
        return true;
      }
    }

    return false;
  }, [customers]);
  const isAsyncEnabled = group.hasAsyncQueues && group.campaignType !== CampaignType.Predictive;
  const canChangeRoutingProfile = Boolean(appConfig.extensions.routingProfileSelection);

  // Setup agent status bar statistic items
  let statisticItems: StatisticItem[] = [getPacketLossStatistic(callStatistics)];

  if (connectMetrics !== undefined && !appConfig.web.hideQueueDepth) {
    statisticItems = [
      ...statisticItems,
      {
        icon: PeopleIcon,
        text: `${connectMetrics.contactsInQueue} in queue (oldest ${timeAgoFromSeconds(
          connectMetrics.oldestContactAge,
        )})`,
      },
    ];
  }

  // Should only be visible if the dialler chan change it else this info is redundant
  if (canChangeRoutingProfile === true) {
    statisticItems = [
      ...statisticItems,
      {
        icon: PeopleIcon,
        text: `Routing Profile: ${connectAgent.routingProfile}`,
      },
    ];
  }

  const isVoiceView =
    !isAsyncEnabled || searchParams.get('view') === undefined || searchParams.get('view') === ViewType.Voice;
  const isAsyncView = isAsyncEnabled && searchParams.get('view') === ViewType.Messaging;
  const isStateChangeDisabled =
    currentVoiceContact?.direction === ContactDirection.Outbound ||
    currentVoiceContact?.transferTargetAgent === loggedInUserUsername ||
    (currentVoiceContact?.direction === ContactDirection.Inbound &&
      currentVoiceContact?.statusType !== ContactStateType.Connecting);

  const setView = (view: ViewType) => () => {
    setSearchParams({ view: view });
  };

  const setAgentState = useCallback(
    async (stateName: string) => {
      let state: ConnectAgentStateDefinition;
      try {
        state = await connectAgent.changeStatus(stateName);
      } catch (e) {
        // Do nothing errors reported as part of function
        return;
      }

      if (asyncSessionActive) {
        // We do a state type check here in the event that the Routable and Offline states are not named correctly
        // on the connect end. This is a fallback to ensure that we are not passing any funny/ unexpected states to the
        // async socket. (i.e. Functionality should not be impaired due to a state naming misconfiguration within
        // connect.)
        switch (state.type) {
          case AgentStateType.Routable: {
            asyncManager.setAgentState(AsyncAgentState.Available);
            return;
          }
          case AgentStateType.Offline: {
            asyncManager.setAgentState(AsyncAgentState.NotReady);
            return;
          }
          case AgentStateType.NotRoutable: {
            asyncManager.setAgentState(state.name as unknown as string);
            return;
          }
          default: {
            // We do not care about any of the other AgentStateType's. The final state type is INIT and will never
            // fall to this point as this component is render blocked until connect streams is fully initialised.
            // However, if for some reason we do reach this point we want to just fail out.
            return;
          }
        }
      }
    },
    [asyncSessionActive],
  );

  const setRoutingProfile = async (routingProfileID: string, routingProfileName: string) => {
    try {
      await postChangeRoutingProfile(routingProfileID);
      // Dodgy hack to support connect streams bug. Refer to interface definition for info on why we are doing this for
      // now.
      connectAgent.updateRoutingProfile(routingProfileName);
    } catch (e) {
      return;
    }
  };

  // Async: marks that the agent is connected to a voice call and to block allocation of conversations to them or not
  useEffect(() => {
    if (asyncSessionActive) {
      // type definition says contact queue timestamp is always of type Date, this is a lie as it can be null (╯°□°）╯︵ ┻━┻
      if (currentVoiceContact && currentVoiceContact.queueTimestamp) {
        disableMessageChimeRef.current = true;
        asyncManager.setAgentVoiceCall({
          // Unable to get a persisted version of this value from connect after state change/ updates as we rebuild the
          // contact object after ever connect update. TODO: revisit when we refactor out old chat properties/ contact provider
          // and consider using a useReducer so we can at least persist this value from a local state (NOTE: if a refresh
          // or object rehydration occurs this values state will be lost)
          connectedTimestamp: undefined,
          contactId: currentVoiceContact.contactId,
          initialContactId: currentVoiceContact.initialContactId,
          initiationMethod: currentVoiceContact.direction === ContactDirection.Inbound ? 'INBOUND' : 'OUTBOUND',
          queue: currentVoiceContact.queue,
          queueARN: currentVoiceContact.queueARN,
          queueTimestamp: currentVoiceContact.queueTimestamp.toISOString(),
          state: currentVoiceContact.statusType as unknown as string,
          stateTimestamp: currentVoiceContact.statusTimestamp.toISOString(),
        });
        return;
      }

      disableMessageChimeRef.current = false;
      asyncManager.setAgentVoiceCall();
    }
  }, [asyncSessionActive, currentVoiceContact]);

  // Async: Change async agent states to match connect state on initial load
  // NOTE: Routable = available, Offline/ Not Routable = pending offline logic
  useEffect(() => {
    if (asyncSessionActive) {
      if (connectAgent.status.type === AgentStateType.Routable) {
        asyncManager.setAgentState(AsyncAgentState.Available);
        return;
      }

      if (connectAgent.status.type === AgentStateType.Offline) {
        asyncManager.setAgentState(AsyncAgentState.NotReady);
        return;
      }

      if (connectAgent.status.type === AgentStateType.NotRoutable) {
        asyncManager.setAgentState(connectAgent.status.name as unknown as string);
        return;
      }
    }
  }, []);

  // Async: If we miss a conversation we should set the connect agent offline
  const isMissedConversation = asyncAgent.state === AsyncAgentState.MissedConversation;
  useEffect(() => {
    if (isMissedConversation) {
      connectAgent.setOffline();
    }
  }, [isMissedConversation]);

  // Async: Set selected customer to the first customer in the list
  useEffect(() => {
    if (asyncSessionActive && selectedCustomerKey === '') {
      const firstItemKey = getKeysSortedByNewestConversationCreatedDate(customers)[0] || '';
      setActiveCustomer(firstItemKey);
    }
  }, [asyncSessionActive, customers, selectedCustomerKey]);

  // All: Changes campaign assignment to refresh display IF an agents campaign has been changed
  useEffect(() => {
    if (currentVoiceContact === undefined) {
      switchGroup();
    }
  }, [currentVoiceContact, configuredGroup]);

  // Voice: Force After call work for an attempt returned to the agent that does not have an existing contact object.
  //        This flag is used to optimize effect reruns so that it only occurs if the boolean flag
  //        changes, rather than when each dependency of this condition changes
  const attemptHasPreviousInteraction =
    currentVoiceContact === undefined &&
    attempt !== undefined &&
    (attempt.initiated === true || attempt.connected === true || attempt.disconnected === true);
  const initiateForcedAfterCallWork =
    connectAgent.status.type !== AgentStateType.Offline && attemptHasPreviousInteraction;
  useEffect(() => {
    if (initiateForcedAfterCallWork) {
      console.log('+ Forcing after call work');
      connectAgent.setOffline();
      asyncManager.setAgentState(AsyncAgentState.NotReady);
    }
  }, [initiateForcedAfterCallWork]);

  useEffect(() => {
    if (canChangeRoutingProfile === true) {
      const fetchRoutingProfiles = async (): Promise<void> => {
        setCampaignRoutingProfiles(await fetchGroupRoutingProfilesOrEmptyArray(group.diallerGroupId));
      };

      fetchRoutingProfiles();
    }
  }, [group.diallerGroupId, canChangeRoutingProfile]);

  // Catches potential routing issues for the following cases:
  // - Chat not enabled and navigating to the hard link of ViewType.Messaging
  // - If view is anything other than a ViewType value
  useEffect(() => {
    if (
      (searchParams.get('view') !== ViewType.Voice && searchParams.get('view') !== ViewType.Messaging) ||
      (!isAsyncEnabled && searchParams.get('view') === ViewType.Messaging)
    ) {
      setView(ViewType.Voice)();
    }
  }, [isAsyncEnabled, searchParams]);

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <AgentStatusBar
          agentStateList={agentStateList}
          agentStatusType={connectAgent.status.type}
          agentStatus={connectAgent.status.name}
          routingProfiles={campaignRoutingProfiles}
          setRoutingProfile={canChangeRoutingProfile ? setRoutingProfile : undefined}
          isStateChangeDisabled={isStateChangeDisabled}
          currentVoiceContact={currentVoiceContact}
          agentStatusTimestamp={connectAgent.status.startTimestamp}
          setAgentState={setAgentState}
          onVoiceButtonClick={isAsyncEnabled ? setView(ViewType.Voice) : undefined}
          onMessagingButtonClick={isAsyncEnabled ? setView(ViewType.Messaging) : undefined}
          statisticItems={statisticItems}
          isVoiceViewActive={searchParams.get('view') === ViewType.Voice}
          isMessagingViewActive={searchParams.get('view') === ViewType.Messaging}
          isAsyncPendingStateChange={Boolean(asyncAgent.requestedStateTimestamp)}
          isAsyncMissedConversation={Boolean(asyncAgent.state === AsyncAgentState.MissedConversation)}
          attemptHasPreviousInteraction={attemptHasPreviousInteraction}
          unseenMessages={hasUnseenMessages}
          downloadSessionLogs={downloadSessionLogs}
        />
      </div>

      {isVoiceView && <VoiceView />}
      {isAsyncView && <AsyncView />}
    </>
  );
};

export default ConnectDialler;
