import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FocusEvent, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';

import ActionDialog from '~components/ActionDialog';
import AgentCard from '~components/AgentCard';
import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useAgentList from '~hooks/useAgentList';
import useDebounce from '~hooks/useDebounce';
import useDiallerGroupSearch from '~pages/CampaignManagement/DiallerGroupList/useDiallerGroupSearch';
import { DiallerGroup } from '~pages/CampaignManagement/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useNotification } from '~providers/NotificationProvider';
import Routes from '~providers/RouteProvider/Routes';
import { useUserPreferences } from '~providers/UserPreferencesProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { createAgent, deleteAgentByUsername, updateAgentByUsername } from '../api';
import { Agent, AgentUpdateRequest } from '../domain';
import CreateEditAgentModal from './CreateEditAgentModal';

interface Query {
  search: string;
  filter: number | undefined;
}

const AgentList = () => {
  const { aws } = useAppConfiguration();
  const { accessFilter } = useUserPreferences();
  const { pushNotification } = useNotification();
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [editableAgentRef, setEditableAgentRef] = useState<string | undefined>(undefined);
  const [deletableAgentRef, setDeletableAgentRef] = useState<string | undefined>(undefined);
  const [query, setQuery] = useState<Query>({ search: '', filter: undefined });
  const [groupListValue, setGroupListValue] = useState<DiallerGroup | null>(null);
  const debouncedSearch = useDebounce(query.search, 500);
  const [searchGroup, setSearchGroup] = useState<string>('');
  const debouncedSearchGroup = useDebounce(searchGroup, 500);
  const {
    loading: groupFetching,
    list: groups,
    error: groupFetchError,
    intersectionObserverRef: lastGroupDataElement,
  } = useDiallerGroupSearch(debouncedSearchGroup, { accessFilterId: accessFilter?.id, archived: false });
  const {
    loading: agentFetching,
    agents,
    error: agentFetchError,
    hasMore: hasMoreAgents,
    reload: reloadAgentsList,
    intersectionObserverRef: lastAgentDataElement,
  } = useAgentList(debouncedSearch, {
    accessFilterId: accessFilter?.id,
    diallerGroupId: query.filter || undefined,
    disabled: false,
  });
  const editableAgent = useMemo(() => {
    return agents.find((item) => item.username === editableAgentRef);
  }, [editableAgentRef]);
  const deletableAgent = useMemo(() => {
    return agents.find((item) => item.username === deletableAgentRef);
  }, [deletableAgentRef]);
  const noSearchOrFilterSet = query.search === '' && !query.filter;

  const onSearchChange = async (e: ChangeEvent<any>) => {
    setQuery((prev) => ({ ...prev, search: e.target.value }));
  };

  const onFilterAutoCompleteChange = async (data: DiallerGroup | null) => {
    setGroupListValue(data);
    setQuery((prev) => ({ ...prev, filter: data?.id || undefined }));
  };

  const handleRemoveConfirmationOpen = (username: string) => () => {
    setDeletableAgentRef(username);
    setRemoveConfirmOpen(true);
  };

  const handleRemoveConfirmationClose = () => {
    setDeletableAgentRef(undefined);
    setRemoveConfirmOpen(false);
  };

  const toggleCreateAgentModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const setEditAgentData = (username: string) => () => {
    setEditableAgentRef(username);
  };

  const removeEditAgentData = () => {
    setEditableAgentRef(undefined);
  };

  const addAgent = async (data: AgentUpdateRequest) => {
    setSubmittingData(true);

    try {
      await createAgent(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Created agent ${data.firstName} ${data.lastName}`);
    setCreateModalOpen(false);
    reloadAgentsList();
  };

  const onRemoveAgent = (data: Agent | undefined) => async () => {
    if (data != undefined) {
      try {
        await deleteAgentByUsername(data.username);
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
        return;
      }

      pushNotification('success', `Deleted agent ${data.firstName} ${data.lastName}`);
    }
  };

  const updateAgent = async (data: AgentUpdateRequest) => {
    setSubmittingData(true);

    try {
      await updateAgentByUsername(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Updated agent ${data.firstName} ${data.lastName}`);
    removeEditAgentData();
    reloadAgentsList();
  };

  const deleteAgent = (data: Agent | undefined) => async () => {
    if (data != undefined) {
      try {
        await deleteAgentByUsername(data.username);
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
        return;
      }

      pushNotification('success', `Deleted agent ${data.firstName} ${data.lastName}`);
      handleRemoveConfirmationClose();
      reloadAgentsList();
    }
  };

  const displayList = useMemo(
    () =>
      agents.map((item: Agent, index: number) => {
        const viewLink = generatePath(Routes.agentDetails.path, { username: item.username });
        let props: any = {
          key: item.username,
          fullName: item.fullName,
          username: item.username,
          diallerGroupName: item.diallerGroupName || 'N/A',
          to: viewLink,
          menuItems: [{ name: 'Edit', action: setEditAgentData(item.username) }],
        };

        if (!aws.externallyManagedConnectInstance) {
          props.menuItems = [
            ...props.menuItems,
            { name: 'Delete', action: handleRemoveConfirmationOpen(item.username) },
          ];
        }

        if (index === agents.length - 1) {
          props = { ...props, ref: lastAgentDataElement };
        }

        return <AgentCard key={item.username} {...props} />;
      }),
    [agents, lastAgentDataElement, onRemoveAgent],
  );

  const onGroupSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchGroup(e.target.value);
  };

  const onGroupSearchBlur = (e: FocusEvent<HTMLInputElement>) => {
    setSearchGroup('');
  };

  const groupNoOptionsText = useMemo(() => {
    if (groupFetching) {
      return <DotLoader align='center' />;
    }

    if (groupFetchError) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          'Failed to load groups'
        </Typography>
      );
    }

    return undefined;
  }, [groupFetching, groupFetchError]);

  const groupFilteredList = useMemo(() => {
    if (!query.filter) {
      return groups;
    }

    return groups.filter((listItem) => {
      return listItem.id !== Number(query.filter);
    });
  }, [groups, query.filter]);

  return (
    <>
      <Grid sx={{ marginBottom: 2 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            defaultValue={query.search}
            onChange={onSearchChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Autocomplete
            id='filter'
            value={groupListValue}
            onChange={(e, data) => {
              onFilterAutoCompleteChange(data);
            }}
            fullWidth
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            options={groupFilteredList}
            noOptionsText={groupNoOptionsText}
            getOptionLabel={(option) => option?.name || ''}
            renderOption={(props, option) => (
              <li {...props} ref={lastGroupDataElement} key={option.id}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Assigned to Dialler Group'
                variant='outlined'
                onBlur={onGroupSearchBlur}
                onChange={onGroupSearchChange}
              />
            )}
          />
        </Grid>

        <Hidden smDown>
          <Grid item md={2}></Grid>
        </Hidden>

        {!aws.externallyManagedConnectInstance && (
          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={toggleCreateAgentModal}>
              Create Agent
            </Button>
          </Grid>
        )}
      </Grid>

      <AsyncLoader isLoading={agentFetching && agents.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {agents.length > 0 && (
              <>
                <List>{displayList}</List>
                {agentFetching && agents.length > 0 && <DotLoader align='center' />}

                {!agentFetching && !hasMoreAgents && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}

                {agentFetchError && agents.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load agents
                  </Typography>
                )}
              </>
            )}

            {agents.length === 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text='No agents found matching your search criteria'
                subText='Try selecting a different campaign or entering a different search name.'
              />
            )}

            {agents.length === 0 && noSearchOrFilterSet && !accessFilter && (
              <EmptyState type='no-items-3' text='No agents currently exist' />
            )}

            {agents.length === 0 && noSearchOrFilterSet && accessFilter && (
              <EmptyState
                type='no-records-found'
                text={`${accessFilter.name} access filter does not have any agents assigned to it`}
              />
            )}
          </Grid>
        </Grid>

        <CreateEditAgentModal
          open={createModalOpen || Boolean(editableAgent)}
          submitting={submittingData}
          agentUsername={editableAgent?.username || undefined}
          onClose={editableAgent !== undefined ? removeEditAgentData : toggleCreateAgentModal}
          onAccept={editableAgent !== undefined ? updateAgent : addAgent}
        />

        <ActionDialog
          open={removeConfirmOpen}
          title='Are you sure you want to do this?'
          content={`You are about to delete ${deletableAgent?.username}, once you have completed this action it cannot be undone.`}
          onClose={handleRemoveConfirmationClose}
          onAccept={deleteAgent(deletableAgent)}
          primaryActionTitle='Remove'
        />
      </AsyncLoader>
    </>
  );
};

export default AgentList;
