import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';

import DialPhoneNumberInput from '~components/DialPhoneNumberInput';
import { ConnectVoiceContact } from '~providers/ConnectProvider/domain';
import { useLogRocket } from '~providers/LogRocketProvider';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getExternalTransferList } from '../api';
import { ExternalTransferItem } from '../domain';
import ExternalTransferListItem from './ExternalTransferListItem';

interface ExternalTransfer {
  contact?: ConnectVoiceContact;
  maxConnectionCapacityReached: boolean;
  onCloseTransferModal: () => void;
}

const ExternalTransfer = ({ contact, maxConnectionCapacityReached, onCloseTransferModal }: ExternalTransfer) => {
  const logRocket = useLogRocket();
  const { pushNotification } = useNotification();
  const [searchText, setSearchText] = useState<string>('');
  const [externalTransferList, setExternalTransferList] = useState<ExternalTransferItem[]>([]);
  const [boxRef, { height: boxHeight }] = useMeasure();

  // Resets all properties to default values
  const defaultReset = () => {
    setSearchText('');
  };

  // Initial fetch of the external transfer list
  useEffect(() => {
    (async () => {
      try {
        setExternalTransferList(await getExternalTransferList());
      } catch (e) {
        console.error('! error fetching external transfer list ', e);
      }
    })();
  }, []);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const triggerExternalTransfer = async (phoneNumber: string) => {
    if (contact === undefined) {
      console.error('triggerExternalTransfer: contact does not exist');
      return;
    }

    if (maxConnectionCapacityReached) {
      console.error('Conference at capacity');
      pushNotification('error', 'Conference at capacity');
      return;
    }

    if (contact.initiateExternalTransfer === undefined) {
      console.error('triggerExternalTransfer: contact.initiateExternalTransfer does not exist');
      return;
    }

    try {
      await contact.initiateExternalTransfer(phoneNumber);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }

    logRocket.trackEvent('transfer');
    logRocket.trackEvent('external_transfer');

    onCloseTransferModal();
    defaultReset();
  };

  const triggerDirectTransfer = async (phoneNumber: string) => {
    await triggerExternalTransfer(phoneNumber);
  };

  const searchTerm = searchText.split(/\s+/).filter((term) => term.length > 0);
  const searchResultList = externalTransferList.filter((item) =>
    searchTerm.every((term) => item.name.toLowerCase().includes(term.toLowerCase())),
  );
  const transferItemList = searchResultList.map((item, index) => (
    <ExternalTransferListItem
      key={item.name}
      name={item.name}
      address={item.address}
      divider={index !== searchResultList.length - 1}
      onTransfer={() => triggerExternalTransfer(item.phoneNumber)}
    />
  ));

  return (
    <>
      <div ref={boxRef}>
        <Grid sx={{ padding: 1 }} container spacing={1}>
          <Grid item xs={12}>
            <DialPhoneNumberInput
              id='directTransfer'
              name='directTransfer'
              label='Direct Transfer'
              onClick={triggerDirectTransfer}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              style={{ marginTop: 8 }}
              fullWidth
              variant='outlined'
              id='search'
              name='search'
              label='Search'
              onChange={onSearchChange}
            />
          </Grid>
        </Grid>

        <Divider component='hr' />
      </div>

      <div style={{ height: `calc(100% - ${boxHeight}px)`, overflow: 'auto' }}>
        {transferItemList.length === 0 && (
          <Typography marginTop={1} variant='body1' component='p' align='center'>
            {searchTerm.length === 0
              ? 'There is currently no one to externally transfer to.'
              : 'No search results found.'}
          </Typography>
        )}

        {transferItemList.length > 0 && <List sx={{ padding: 0 }}>{transferItemList}</List>}
      </div>
    </>
  );
};

export default ExternalTransfer;
