import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';
import { getDispositionAttributes } from '~pages/SystemManagement/api';
import { DispositionAttribute } from '~pages/SystemManagement/domain';

import { CampaignType, DiallerType, Disposition, OutcomeType, UpdateDisposition } from '../../../domain';

interface CreateEditDispositionModalProps {
  diallerType: DiallerType;
  campaignType: CampaignType;
  open: boolean;
  submitting: boolean;
  disposition?: Disposition;
  onAccept: (data: Disposition | UpdateDisposition) => Promise<void>;
  onClose: () => void;
}

interface Form {
  code: string;
  subCode: string;
  title: string;
  outcome: string;
  description: string;
  attributes: { attribute: string; value: string }[];
}

const fetchDispositionAttributesOrEmptyArray = async () => {
  try {
    return await getDispositionAttributes();
  } catch (e) {
    return [];
  }
};

const structureAttributes = (attributes: DispositionAttribute[]): Record<string, string[]> => {
  return attributes.reduce((state, currentVal) => {
    return {
      ...state,
      [currentVal.attribute]: currentVal.values,
    };
  }, {});
};

const CreateEditDispositionModal = ({
  open,
  diallerType,
  campaignType,
  submitting,
  disposition,
  onAccept,
  onClose,
}: CreateEditDispositionModalProps) => {
  const [dispositionAttributes, setDispositionAttributes] = useState<Record<string, string[]>>({});
  const [fetchingFormData, setFetchingFormData] = useState<boolean>(false);
  const isLoading = submitting || fetchingFormData;
  const isEdit = Boolean(disposition);
  const isSystem = disposition !== undefined && disposition.code === 'system';
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      code: '',
      subCode: '',
      title: '',
      outcome: '',
      description: '',
      attributes: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attributes',
    shouldUnregister: true,
  });

  let outcomeList = [
    {
      label: 'Contacted',
      value: OutcomeType.Contacted,
    },
    {
      label: 'No Answer',
      value: OutcomeType.NoAnswer,
    },
    {
      label: 'Answering Machine',
      value: OutcomeType.AnsweringMachine,
    },
    {
      label: 'Engaged',
      value: OutcomeType.Engaged,
    },
    {
      label: 'Invalid Endpoint',
      value: OutcomeType.InvalidEndpoint,
    },
    {
      label: 'Excluded',
      value: OutcomeType.Excluded,
    },
  ];

  // We only want these outcome types available for preview campaigns
  if (campaignType === CampaignType.Preview) {
    outcomeList = [
      ...outcomeList,
      {
        label: 'Skipped',
        value: OutcomeType.Skipped,
      },
      {
        label: 'Removed',
        value: OutcomeType.Removed,
      },
    ];
  }

  // TODO: currently callbacks are not supported as part of predictive connect campaigns, add option back to initial list
  //       under the contacted disposition object when supported
  if (diallerType !== DiallerType.Connect || campaignType !== CampaignType.Predictive) {
    outcomeList = [
      ...outcomeList,
      {
        label: 'Callback',
        value: OutcomeType.Callback,
      },
      {
        label: 'Missed Callback',
        value: OutcomeType.MissedCallback,
      },
    ];
  }

  const outcomeListDisplay = outcomeList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  // Handles all async based actions
  useEffect(() => {
    (async () => {
      if (open) {
        setFetchingFormData(true);
        setDispositionAttributes(structureAttributes(await fetchDispositionAttributesOrEmptyArray()));

        if (disposition !== undefined) {
          setValue('code', disposition.code);
          setValue('subCode', disposition.subCode);
          setValue('title', disposition.title);
          setValue('description', disposition.description);
          disposition.attributes.forEach((item) =>
            append({
              attribute: item.attribute,
              value: item.value,
            }),
          );

          // Since this is optional depending on campaign type we check to see if its define before assigning
          if (disposition.outcome !== undefined) {
            setValue('outcome', disposition.outcome);
          }
        }

        setFetchingFormData(false);
      }
    })();

    // Reset form on close
    return function cleanupCreateEditAttributeModal() {
      reset();
    };
  }, [open, disposition]);

  const onSubmit = handleSubmit(async (data: Form) => {
    // NOTE (2021-08-04): Bug exists in react-hook-form's current implementation which leads default value of empty array to be undefined when using useFieldArray
    // this is a workaround for now until the fix this issue in a later version.
    // Ref: https://github.com/react-hook-form/react-hook-form/issues/2382
    const attributes = data.attributes
      ? data.attributes.map((attr) => ({
          attribute: attr.attribute,
          value: attr.value,
        }))
      : [];

    try {
      if (isEdit && disposition) {
        await onAccept({
          originalDispositionCode: disposition.code,
          originalDispositionSubCode: disposition.subCode,
          disposition: {
            code: data.code,
            subCode: data.subCode,
            title: data.title,
            outcome: data.outcome as OutcomeType,
            description: data.description,
            attributes: attributes,
          },
        });
      } else {
        await onAccept({
          code: data.code,
          subCode: data.subCode,
          title: data.title,
          outcome: data.outcome as OutcomeType,
          description: data.description,
          attributes: attributes,
        });
      }

      reset();
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
    }
  });

  const watchAttributes = watch('attributes');
  // Watch can somteimes be undefined, so lets fallback to fields as a check if this is the case
  const selectedAttributes = watchAttributes
    ? watchAttributes.map((attr) => attr.attribute)
    : fields.map((attr) => attr.attribute);
  const disableAddAttributes = selectedAttributes.length >= Object.keys(dispositionAttributes).length;

  const attributeItems = fields.map((field: any, index: number) => {
    const selectedAttributeRef = selectedAttributes[index] || undefined;
    const attributeList = Object.keys(dispositionAttributes)
      .filter((item) => {
        // We want the same value to be returned for display purposes
        if (item === selectedAttributeRef) return true;
        // Return value if it does not exist in this list
        return !selectedAttributes.includes(item);
      })
      .map((value, index) => (
        <MenuItem key={index} value={value}>
          {value}
        </MenuItem>
      ));
    const valuesList =
      selectedAttributeRef === undefined
        ? []
        : (dispositionAttributes[selectedAttributeRef] || []).map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ));

    return (
      <Fragment key={field.id}>
        <Grid sx={{ display: 'flex' }} item xs={2}>
          <Button
            sx={{
              flex: 1,
              // Height of default sized input field
              maxHeight: 56,
            }}
            variant='contained'
            disableElevation
            color='secondary'
            disabled={isLoading}
            onClick={() => remove(index)}>
            <DeleteIcon />
          </Button>
        </Grid>

        <Grid item xs={5}>
          <Controller
            name={`attributes.${index}.attribute` as const}
            control={control}
            rules={{ required: 'Attribute is required.' }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                select
                variant='outlined'
                label='Attribute'
                disabled={isLoading}
                required={true}
                error={Boolean(errors?.attributes?.[index]?.attribute)}
                helperText={errors?.attributes?.[index]?.attribute?.message}>
                {attributeList}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={5}>
          <Controller
            name={`attributes.${index}.value` as const}
            control={control}
            rules={{ required: 'Value is required.' }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                select
                variant='outlined'
                label='Value'
                disabled={isLoading || selectedAttributeRef === undefined}
                required={true}
                error={Boolean(errors?.attributes?.[index]?.value)}
                helperText={errors?.attributes?.[index]?.value?.message}>
                {valuesList}
              </TextField>
            )}
          />
        </Grid>
      </Fragment>
    );
  });

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Disposition`}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='code'
              control={control}
              rules={{
                required: 'Code is required.',
                pattern: {
                  value: isSystem ? /^system$/ : /^(?!system$)[0-9a-z-]+$/,
                  message: 'Code can only be lowercase, alphanumeric and contain hyphens, and system is reserved.',
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Code'
                  disabled={isLoading || isSystem}
                  required={true}
                  error={Boolean(errors.code)}
                  helperText={errors.code?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='subCode'
              control={control}
              rules={{
                required: 'Sub Code is required.',
                pattern: {
                  value: /^[0-9a-z-]+$/,
                  message: 'Code can only be lowercase, alphanumeric and contain hyphens.',
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Sub Code'
                  disabled={isLoading || isSystem}
                  required={true}
                  error={Boolean(errors.subCode)}
                  helperText={errors.subCode?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='title'
              control={control}
              rules={{ required: 'Title is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Title'
                  disabled={isLoading || isSystem}
                  required={true}
                  error={Boolean(errors.title)}
                  helperText={errors.title?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='outcome'
              rules={{ required: 'Outcome is required.' }}
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  select
                  variant='outlined'
                  label='Outcome'
                  disabled={isLoading || isSystem}
                  required={true}
                  error={Boolean(errors.outcome)}
                  helperText={errors.outcome?.message}
                  {...field}>
                  {outcomeListDisplay}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{ required: 'Description is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Description'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          {attributeItems}

          <Grid item xs={12}>
            <Button
              variant='contained'
              disableElevation
              fullWidth
              color='primary'
              disabled={isLoading || disableAddAttributes}
              onClick={() => append({ attribute: '', value: '' })}>
              Add Attribute
            </Button>
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateEditDispositionModal;
