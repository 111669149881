import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import EmptyState from '~components/EmptyState';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { updateCampaignFiltersById } from '../../api';
import { CampaignFilter } from '../../domain';
import CreateEditFilterModal from './CreateEditFilterModal';
import FilterCard from './FilterCard';

interface Query {
  search: string;
}

interface CampaignFiltersProps {
  filters: CampaignFilter[];
  triggerCampaignRefresh: () => Promise<void>;
}

const CampaignFilters = ({ filters, triggerCampaignRefresh }: CampaignFiltersProps) => {
  const { pushNotification } = useNotification();
  const { campaignId } = useParams() as { campaignId: string };
  const [createFilterModalOpen, setCreateFilterModalOpen] = useState<boolean>(false);
  const [editableRef, setEditableRef] = useState<number | undefined>(undefined);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const filterRef = useRef(filters);
  const noFilters = !filters || filters.length === 0;
  const [query, setQuery] = useState<Query>({
    search: '',
  });
  const editableFilter = useMemo(() => filters.find((item, index) => index === editableRef), [editableRef]);

  useEffect(() => {
    filterRef.current = filters;
  }, [filters]);

  const openCreateFilterModal = () => {
    setCreateFilterModalOpen(true);
  };

  const closeCreateEditModal = () => {
    setCreateFilterModalOpen(false);
    setEditableRef(undefined);
  };

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const createFilter = async (data: CampaignFilter) => {
    setSubmittingData(true);

    try {
      await updateCampaignFiltersById(+campaignId, [...filterRef.current, data]);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Created filter ${data.name}`);
    setCreateFilterModalOpen(false);

    // TODO: do we need to even await, parent component handles state display errors if this refresh fails
    await triggerCampaignRefresh();
  };

  const updateFilter = (index?: number) => async (data: CampaignFilter) => {
    if (index === undefined) {
      console.error('updateFilter: Filter index undefined');
      return;
    }

    setSubmittingData(true);

    const arrayClone = JSON.parse(JSON.stringify(filters));
    arrayClone[index] = data;

    try {
      await updateCampaignFiltersById(+campaignId, arrayClone);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on update failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Updated Filter ${data.name}`);
    closeCreateEditModal();

    // TODO: do we need to even await, parent component handles state display errors if this refresh fails
    await triggerCampaignRefresh();
  };

  const onAccept = useCallback(
    (index?: number) => async (data: CampaignFilter) => {
      if (index !== undefined) {
        await updateFilter(index)(data);
      } else {
        await createFilter(data);
      }
    },
    [editableFilter],
  );

  const removeFilter = (index: number, name: string) => async () => {
    const arrayClone = JSON.parse(JSON.stringify(filters));
    arrayClone.splice(index, 1);

    try {
      await updateCampaignFiltersById(+campaignId, arrayClone);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    }

    pushNotification('success', `Removed Filter ${name}`);

    // TODO: do we need to even await, parent component handles state display errors if this refresh fails
    await triggerCampaignRefresh();
  };

  const displayList = !noFilters
    ? filters
        // Search Fuzzy matching
        .filter((item: CampaignFilter) => {
          if (query.search) {
            return item.name.toLowerCase().includes(query.search.toLowerCase());
          }

          return true;
        })
        // Component generation
        .map((item: CampaignFilter, index: number) => (
          <FilterCard
            key={index}
            filter={item}
            onEdit={() => setEditableRef(index)}
            onDelete={removeFilter(index, item.name)}
          />
        ))
    : [];

  // If any filter property is set and displayList is empty we assume no relevant search results
  const noSearchOrFilterResults = query.search && displayList.length === 0;

  return (
    <>
      {noFilters && (
        <EmptyState
          type='no-items-1'
          text='No filters are currently assigned to this campaign'
          subText='Click the button below to start adding filters'
          action={openCreateFilterModal}
          actionText='Create Filter'
        />
      )}

      {!noFilters && (
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={query.search}
              onChange={onQueryChange}
            />
          </Grid>

          <Hidden smDown>
            <Grid item md={5}></Grid>
          </Hidden>

          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={openCreateFilterModal}>
              Create Filter
            </Button>
          </Grid>
          <Grid item xs={12}>
            {!noSearchOrFilterResults && <List>{displayList}</List>}
            {noSearchOrFilterResults && (
              <EmptyState
                type='no-records-found'
                text='No filters found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}
          </Grid>
        </Grid>
      )}

      <CreateEditFilterModal
        open={createFilterModalOpen || Boolean(editableFilter)}
        filter={editableFilter}
        submitting={submittingData}
        onClose={closeCreateEditModal}
        onAccept={onAccept(editableRef)}
      />
    </>
  );
};

export default CampaignFilters;
