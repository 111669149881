import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { ChangeEvent, useEffect, useState } from 'react';

import useForm, { UseForm, ValidatorType } from '~hooks/useForm';
import organisations from '~organisations';
import { DiallingHours, Disposition, OutcomeType, PublicHoliday } from '~pages/CampaignManagement/domain';
import { getTimezoneList } from '~pages/Dialler/api';
import { Callback } from '~pages/Dialler/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useConnect } from '~providers/ConnectProvider';
import { convertDictValuesToString } from '~utils/Functions';

import Selectbox from '../../../../../components/Form/Selectbox';
import LoadingButtonDropdown from '../LoadingButtonDropdown';

type ChannelType = 'voice' | 'chat';

interface OutcomeCaptureProps {
  orgReference: string;
  channelType: ChannelType;
  campaignId?: number;
  dispositions?: Disposition[];
  diallingHours?: DiallingHours;
  publicHolidays?: PublicHoliday[];
  exclusionLists?: string[];
  timezone?: string;
  agentUsername?: string;
  isUnknownLead?: boolean;
  contactName?: string;
  endpoint?: string;
  disableSubmit?: boolean;
  disableNewLead?: boolean;
  onSubmit: (data: Outcome, switchToStatus: string | undefined) => Promise<void>;
}

// Note: lead's endpoint comes from our amazon connect contact object
interface NewLead {
  endpoint: string;
  name: string;
  externalId: string;
  timezone: string;
}

export interface Outcome {
  dispositionCode: string;
  dispositionSubCode: string;
  hasSystemIssue: boolean;
  systemIssueDescription: string;
  exclusionList?: string;
  callback?: Callback;
  newLead?: NewLead;
  attributes: Record<string, any>;
}

export interface OutcomesFormProps {
  form: UseForm;
  formSubmitting: boolean;
  channelType: ChannelType;
}

interface AutoCompleteList {
  label: string;
  value: string;
}

// Used for disabling dates within disableDates function
const dayToWeekdayNumber: { [key: string]: number } = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

// Used for showing error messages for selected date range within onValidation function
const weekdayNumberToDay: { [key: number]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

// TODO: update to use react-hook-form so its much cleaner logically and less buggy
const OutcomeCapture = ({
  orgReference,
  channelType,
  campaignId,
  dispositions,
  diallingHours,
  publicHolidays,
  exclusionLists,
  timezone,
  agentUsername,
  isUnknownLead,
  contactName,
  endpoint,
  disableSubmit,
  disableNewLead,
  onSubmit,
}: OutcomeCaptureProps) => {
  const appConfig = useAppConfiguration();
  const { agentStateList } = useConnect();
  const [viewFlag, setViewFlag] = useState<OutcomeType.Callback | OutcomeType.Excluded | undefined>(undefined);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [requireLeadCreation, setRequireLeadCreation] = useState<boolean>(false);
  const [timezoneList, setTimezoneList] = useState<string[]>([]);
  const [timezoneFetchError, setTimezoneFetchError] = useState<string>('');
  const AdditionalFields =
    campaignId !== undefined ? organisations[orgReference]?.additionalOutcomeCaptureFields[campaignId] : undefined;
  const form = useForm({
    disposition: {
      value: null,
      validators: [
        {
          type: ValidatorType.Required,
          message: 'A disposition is Required',
        },
      ],
    },
    hasSystemIssue: {
      value: false,
      validators: [],
    },
  });

  // Fetch timezone list
  useEffect(() => {
    if (isUnknownLead) {
      const fetchTimezoneList = async () => {
        setTimezoneFetchError('');

        let resp: string[];
        try {
          resp = await getTimezoneList(appConfig.web.timezonePrefixes);
        } catch (e) {
          setTimezoneFetchError('Unable to fetch timezone list');
          return;
        }

        setTimezoneList(resp);
        form.handleUnconventionalInputChange('leadTimezone', appConfig.defaultTimezone);
      };

      fetchTimezoneList();

      return () => {
        setTimezoneFetchError('');
        setTimezoneList([]);
      };
    }
  }, [isUnknownLead, appConfig.web.timezonePrefixes]);

  // Dynamically add/ remove unknown lead collection detail fields
  useEffect(() => {
    if (isUnknownLead === true) {
      form.addSchemaProperties({
        leadEndpoint: {
          value: '',
          validators: [
            {
              type: ValidatorType.ValidIfSetWith,
              fieldName: 'leadName',
              message: 'Lead endpoint is required',
            },
            {
              type: ValidatorType.ValidIfSetWith,
              fieldName: 'leadExternalId',
              message: 'Lead endpoint is required',
            },
          ],
        },
        leadName: {
          value: '',
          validators: [
            {
              type: ValidatorType.ValidIfSetWith,
              fieldName: 'leadEndpoint',
              message: 'Lead name is required',
            },
            {
              type: ValidatorType.ValidIfSetWith,
              fieldName: 'leadExternalId',
              message: 'Lead name is required',
            },
          ],
        },
        leadExternalId: {
          value: '',
          validators: [
            {
              type: ValidatorType.ValidIfSetWith,
              fieldName: 'leadEndpoint',
              message: 'External ID is required',
            },
            {
              type: ValidatorType.ValidIfSetWith,
              fieldName: 'leadName',
              message: 'External ID is required',
            },
          ],
        },
        leadTimezone: {
          value: '',
          validators: [],
        },
      });
      return;
    }

    form.removeSchemaProperties(['leadEndpoint', 'leadName', 'leadExternalId', 'leadTimezone']);
  }, [isUnknownLead]);

  // Managing if creating a new lead for unknown inbound is optional OR required
  useEffect(() => {
    if (isUnknownLead) {
      if (
        form.fields.disposition.value !== null &&
        form.fields.disposition.value.outcome === OutcomeType.Callback &&
        requireLeadCreation == false
      ) {
        // Mark all new lead fields as required
        form.addValidationTypeToField('leadEndpoint', {
          type: ValidatorType.Required,
          message: 'Lead endpoint is required.',
        });
        form.addValidationTypeToField('leadName', { type: ValidatorType.Required, message: 'Lead name is required.' });
        form.addValidationTypeToField('leadExternalId', {
          type: ValidatorType.Required,
          message: 'Lead external ID is required.',
        });
        form.addValidationTypeToField('leadTimezone', {
          type: ValidatorType.Required,
          message: 'Lead timezone is required.',
        });
        setRequireLeadCreation(true);
      } else if (
        form.fields.disposition.value !== null &&
        form.fields.disposition.value.outcome !== OutcomeType.Callback &&
        requireLeadCreation == true
      ) {
        // Remove all new lead fields required validations IF not callback disposition
        form.setAsyncFields({
          leadEndpoint: '',
        });
        form.removeValidationTypeFromField('leadEndpoint', ValidatorType.Required);
        form.removeValidationTypeFromField('leadName', ValidatorType.Required);
        form.removeValidationTypeFromField('leadExternalId', ValidatorType.Required);
        form.removeValidationTypeFromField('leadTimezone', ValidatorType.Required);
        setRequireLeadCreation(false);
      } else if (
        form.fields.disposition.value !== null &&
        form.fields.disposition.value.outcome === OutcomeType.Callback
      ) {
        form.setAsyncFields({
          leadEndpoint: form.fields.callbackEndpoint.value,
        });
      }
    }
  }, [isUnknownLead, form.fields.disposition.value, form.fields?.callbackEndpoint?.value]);

  // Dynamically add/ remove system issue description field based off of the system issue checkbox
  useEffect(() => {
    if (form.fields.hasSystemIssue.value === true) {
      form.addSchemaProperties({
        systemIssueDescription: {
          value: '',
          validators: [
            {
              type: ValidatorType.Required,
              message: 'A system issue description is required',
            },
          ],
        },
      });
      return;
    }

    form.removeSchemaProperties(['systemIssueDescription']);
  }, [form.fields.hasSystemIssue.value]);

  // Dynamically add/ remove new fields to form schema dependent on campaign and lead status type
  useEffect(() => {
    if (channelType === 'voice') {
      if (form.fields.disposition.value) {
        // If dispositions object does not exist then we skip everything below this
        if (dispositions === undefined) {
          console.error('OutcomeCapture Disposition fields Effect: dispositions do not exist');
          return;
        }
        const dispositionCode = form.fields.disposition.value.code;
        const dispositionSubCode = form.fields.disposition.value.subCode;
        const disposition = dispositions.find(
          (disposition) => disposition.code === dispositionCode && disposition.subCode === dispositionSubCode,
        );

        if (disposition?.outcome === OutcomeType.Callback) {
          form.addSchemaProperties({
            // Based on checkbox we either set the current agents username or null - default null
            callbackAssignment: {
              value: false,
              validators: [],
            },
            forAgent: {
              value: null,
              validators: [],
            },
            scheduled: {
              value: null,
              validators: [
                {
                  type: ValidatorType.Required,
                  message: 'A callback date/ time is required',
                },
                // TODO: remove this validation from useForm
                // when @material-ui/pickers is fully released and disable the time ranges instead
                {
                  type: ValidatorType.Custom,
                  onValidation: (value) => {
                    // Hard and fast fail if value becomes null. Required check above validates if required or
                    // not so we dont need to do it in here
                    if (value === null) {
                      return true;
                    }

                    // Hard and fast fail if diallingHours isn't defined. Required check above validates if required or
                    // not so we don't need to do it in here
                    if (diallingHours === undefined) {
                      return true;
                    }

                    const date = DateTime.fromISO(value as string);
                    const diallingDay = diallingHours.diallingDays[weekdayNumberToDay[date.weekday]];

                    if (diallingDay === null || diallingDay === undefined) {
                      return false;
                    }

                    // If select time is between the hours blocks we allow anything and say its valid
                    if (date.hour > diallingDay.startTimeHour && date.hour < diallingDay.endTimeHour) {
                      return true;
                    }

                    // Start and end edge cases
                    if (
                      (date.hour === diallingDay.startTimeHour && date.minute >= diallingDay.startTimeMin) ||
                      (date.hour === diallingDay.endTimeHour && date.minute < diallingDay.endTimeMin)
                    ) {
                      return true;
                    }

                    return false;
                  },
                  message: (value) => {
                    if (value === null) {
                      return '';
                    }

                    const date = DateTime.fromISO(value);

                    if (diallingHours === undefined) {
                      return '';
                    }

                    const diallingDay = diallingHours.diallingDays[weekdayNumberToDay[date.weekday]];

                    if (diallingDay === null || diallingDay === undefined) {
                      return 'Invalid Date';
                    }

                    const startHour =
                      diallingDay.startTimeHour < 10 ? `0${diallingDay.startTimeHour}` : diallingDay.startTimeHour;
                    const endHour =
                      diallingDay.endTimeHour < 10 ? `0${diallingDay.endTimeHour}` : diallingDay.endTimeHour;
                    const startMinute =
                      diallingDay.startTimeMin < 10 ? `0${diallingDay.startTimeMin}` : diallingDay.startTimeMin;
                    const endMinute =
                      diallingDay.endTimeMin < 10 ? `0${diallingDay.endTimeMin}` : diallingDay.endTimeMin;
                    const startTime = `${startHour}:${startMinute}`;
                    const endTime = `${endHour}:${endMinute}`;

                    return `Callback schedule time must be between ${startTime} and ${endTime}`;
                  },
                },
              ],
            },
            callbackEndpoint: {
              value: endpoint !== 'anonymous' ? endpoint : '',
              validators: [
                {
                  type: ValidatorType.Required,
                  message: 'A callback endpoint is required',
                },
              ],
            },
            // Optional field input
            notes: {
              value: '',
              validators: [],
            },
          });

          setViewFlag(OutcomeType.Callback);
          return;
        }

        if (disposition?.outcome === OutcomeType.Excluded) {
          form.addSchemaProperties({
            exclusionList: {
              value: '',
              validators: [
                {
                  type: ValidatorType.Required,
                  message: 'A exclusion list must be selected',
                },
              ],
            },
          });
          setViewFlag(OutcomeType.Excluded);
          return;
        }

        form.removeSchemaProperties([
          'callbackAssignment',
          'forAgent',
          'scheduled',
          'callbackEndpoint',
          'notes',
          'exclusionList',
        ]);
        setViewFlag(undefined);
      }
    }
  }, [form.fields.disposition.value]);

  // Dynamic assignment of callback agent. Can either be the current agent or null (assigned to another agent via leads engine)
  useEffect(() => {
    // If for any reason the passed in username is undefined, we want to default to the null case
    const username = agentUsername || null;

    form.setAsyncFields({
      forAgent: form.fields?.callbackAssignment?.value ? username : null,
    });
  }, [form.fields?.callbackAssignment?.value]);

  // Hack of applying <DateTime> generic type on the DatePicker components as then it
  // inforces correct typing for this onChange function so it's typing is not messed up. This breaks visible
  // typing however even though it passed type checking. i.e. defers TDate generic from input value which is wrong
  // as minDate then also overrides this with its DateTime generic
  const handleDateTimeChange = (fieldName: string) => (date: DateTime | null) => {
    form.handleUnconventionalInputChange(fieldName, date);
  };

  const handleAutoCompleteInputChange = (fieldName: string) => (e: ChangeEvent<{}>, selectedObj: AutoCompleteList) => {
    form.handleUnconventionalInputChange(fieldName, selectedObj);
  };

  const disableDates = (date: DateTime | null) => {
    // Disable public holidays if not allowed
    if (!diallingHours?.allowPublicHolidays) {
      if (publicHolidays === undefined) return false;

      for (const ph of publicHolidays) {
        if (date && ph.year === date.year && ph.month === date.month && ph.day === date.day) {
          return true;
        }
      }
    }

    // Disable specific day of the week if no dialling day hours exist
    for (const day in diallingHours?.diallingDays) {
      if (diallingHours?.diallingDays[day] === null && dayToWeekdayNumber[day] === date?.weekday) {
        return true;
      }
    }

    return false;
  };

  const handleOutcomeSubmit = (switchToStatus?: string) =>
    form.handleSubmit(async (formData: Record<string, any>) => {
      // Remove all expected fields leaving the other object to be custom attributes
      const {
        disposition,
        exclusionList,
        callbackAssignment,
        forAgent,
        scheduled,
        callbackEndpoint,
        notes,
        hasSystemIssue,
        systemIssueDescription,
        leadEndpoint,
        leadName,
        leadExternalId,
        leadTimezone,
        ...other
      } = formData;

      const attributes = convertDictValuesToString(other);
      const createNewLead = leadEndpoint != '' && leadName != '' && leadExternalId != '' && leadTimezone != '';

      const data: Outcome = {
        dispositionCode: disposition.code,
        dispositionSubCode: disposition.subCode,
        hasSystemIssue: hasSystemIssue,
        systemIssueDescription: systemIssueDescription ?? '',
        exclusionList: viewFlag === OutcomeType.Excluded ? exclusionList : undefined,
        callback:
          viewFlag === OutcomeType.Callback
            ? {
                forAgent: forAgent,
                // Appending timezone info
                // Note: If this is an unknown inbound call, the timezone field will be undefined
                // so we use the timezone of the new lead we create with this callback outcome
                scheduled: DateTime.fromISO(scheduled, { zone: timezone || leadTimezone }).toISO(),
                endpoint: callbackEndpoint,
                notes: notes,
              }
            : undefined,
        newLead:
          isUnknownLead && createNewLead
            ? {
                endpoint: leadEndpoint,
                name: leadName,
                externalId: leadExternalId,
                timezone: leadTimezone,
              }
            : undefined,
        attributes: attributes,
      };

      try {
        setFormSubmitting(true);
        await onSubmit(data, switchToStatus);
      } catch (e) {
        return;
      } finally {
        setFormSubmitting(false);
      }

      setViewFlag(undefined);
      form.resetForm();
    });

  let scheduledHelpTextDefault = timezone ? `(local time in ${timezone})` : '(local time in new leads timezone)';
  if (timezone && contactName) {
    scheduledHelpTextDefault = `(${contactName}'s time in ${timezone})`;
  }
  const scheduledHelperText = form.errors.scheduled ? form.errors.scheduled[0]! : scheduledHelpTextDefault;
  const exclusionListItems = exclusionLists
    ? exclusionLists.map((value: string) => ({ label: value, value: value }))
    : [];

  return (
    <form style={{ width: '100%' }} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            onChange={handleAutoCompleteInputChange('disposition')}
            value={form.fields.disposition.value || null}
            options={dispositions || []}
            disableClearable
            disabled={formSubmitting}
            getOptionLabel={(option) => option?.title || ''}
            filterSelectedOptions
            renderOption={(props, option, state) => (
              <li {...props} key={`${option?.code}-${option?.subCode}`}>
                <div>
                  <Typography variant='body2' color='textPrimary' component='p'>
                    {option.title}
                  </Typography>

                  <Typography variant='caption' color='textSecondary' component='p'>
                    {option.description}
                  </Typography>
                </div>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                id='disposition'
                name='disposition'
                label='Disposition'
                required={true}
                error={Boolean(form.errors.disposition)}
                helperText={form.errors.disposition && form.errors.disposition[0]!}
                variant='outlined'
              />
            )}
          />
        </Grid>

        {viewFlag === OutcomeType.Callback && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id='callbackAssignment'
                    name='callbackAssignment'
                    disabled={formSubmitting}
                    checked={form.fields.callbackAssignment.value}
                    onChange={form.handleInputChange}
                  />
                }
                label='Assign to Self?'
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                id='callbackEndpoint'
                name='callbackEndpoint'
                label='Callback Endpoint'
                disabled={formSubmitting}
                required={true}
                value={form.fields.callbackEndpoint.value}
                error={Boolean(form.errors.callbackEndpoint)}
                helperText={form.errors.callbackEndpoint && form.errors.callbackEndpoint[0]!}
                onChange={form.handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <DateTimePicker
                disableMaskedInput
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                label='Callback Schedule'
                inputFormat='dd/MM/yyyy hh:mm a'
                disabled={formSubmitting}
                disablePast={true}
                ampm={false}
                shouldDisableDate={disableDates}
                value={form.fields.scheduled.value}
                onChange={handleDateTimeChange('scheduled')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant='outlined'
                    required={true}
                    error={Boolean(form.errors.scheduled)}
                    helperText={scheduledHelperText}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant='outlined'
                id='notes'
                name='notes'
                label='Notes'
                disabled={formSubmitting}
                value={form.fields.notes.value}
                error={Boolean(form.errors.notes)}
                helperText={form.errors.notes && form.errors.notes[0]!}
                onChange={form.handleInputChange}
              />
            </Grid>
          </>
        )}
        {viewFlag === OutcomeType.Excluded && (
          <>
            <Grid item xs={12}>
              <Selectbox
                id='exclusionList'
                name='exclusionList'
                title='Exclusion List'
                items={exclusionListItems}
                disabled={formSubmitting}
                value={form.fields.exclusionList.value}
                error={Boolean(form.errors.exclusionList)}
                helperText={form.errors.exclusionList && form.errors.exclusionList[0]!}
                onChange={form.handleInputChange}
              />
            </Grid>
          </>
        )}

        {isUnknownLead === true && !disableNewLead && (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                id='leadEndpoint'
                name='leadEndpoint'
                label='Lead Endpoint'
                disabled={formSubmitting || requireLeadCreation}
                value={form.fields.leadEndpoint?.value || ''}
                required={requireLeadCreation}
                error={Boolean(form.errors.leadEndpoint)}
                helperText={form.errors.leadEndpoint && form.errors.leadEndpoint[0]!}
                onChange={form.handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                id='leadName'
                name='leadName'
                label='Lead Name'
                disabled={formSubmitting}
                value={form.fields.leadName?.value || ''}
                required={requireLeadCreation}
                error={Boolean(form.errors.leadName)}
                helperText={form.errors.leadName && form.errors.leadName[0]!}
                onChange={form.handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                id='leadExternalId'
                name='leadExternalId'
                label='External ID'
                disabled={formSubmitting}
                value={form.fields.leadExternalId?.value || ''}
                required={requireLeadCreation}
                error={Boolean(form.errors.leadExternalId)}
                helperText={form.errors.leadExternalId && form.errors.leadExternalId[0]!}
                onChange={form.handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                value={form.fields.leadTimezone?.value || null}
                onChange={handleAutoCompleteInputChange('leadTimezone')}
                fullWidth
                options={timezoneList}
                filterSelectedOptions
                disabled={formSubmitting}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    {...params}
                    id='leadTimezone'
                    name='leadTimezone'
                    label='Timezone'
                    required={true}
                    error={Boolean(timezoneFetchError) || Boolean(form.errors.leadTimezone)}
                    helperText={timezoneFetchError || (form.errors.leadTimezone && form.errors.leadTimezone[0]!)}
                    variant='outlined'
                  />
                )}
              />
            </Grid>
          </>
        )}

        {AdditionalFields !== undefined && (
          <AdditionalFields channelType={channelType} form={form} formSubmitting={formSubmitting} />
        )}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id='hasSystemIssue'
                name='hasSystemIssue'
                disabled={formSubmitting}
                checked={form.fields.hasSystemIssue.value}
                onChange={form.handleInputChange}
              />
            }
            label='I had a system issue during this contact interaction?'
          />
        </Grid>

        {form.fields.hasSystemIssue.value && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant='outlined'
              id='systemIssueDescription'
              name='systemIssueDescription'
              label='System Issue Description'
              disabled={formSubmitting}
              value={form.fields.systemIssueDescription?.value || ''}
              error={Boolean(form.errors.systemIssueDescription)}
              helperText={form.errors.systemIssueDescription && form.errors.systemIssueDescription[0]!}
              onChange={form.handleInputChange}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            variant='contained'
            disableElevation
            disabled={disableSubmit}
            loading={formSubmitting}
            onClick={() => handleOutcomeSubmit()()}
            color='primary'>
            Dispose and continue
          </LoadingButton>
        </Grid>

        <Grid item xs={12}>
          <LoadingButtonDropdown
            title='Go to State'
            fullWidth
            items={agentStateList}
            disabled={disableSubmit}
            loading={formSubmitting}
            onChange={(status) => handleOutcomeSubmit(status)()}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default OutcomeCapture;
