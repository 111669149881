import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/system/Box';
import React, { useEffect, useState } from 'react';

import { DataItem } from '~components/DataItem';
import Selectbox, { SelectItem } from '~components/Form/Selectbox';
import SelectboxMulti from '~components/Form/SelectboxMulti';
import SectionCard from '~components/SectionCard';
import useForm, { ValidatorType } from '~hooks/useForm';
import { getDiallingHourProfiles, getWashLists } from '~pages/CampaignManagement/api';
import { DiallingHourProfile, LeadEngineSettings, WashList } from '~pages/CampaignManagement/domain';
import { getExclusionLists } from '~pages/SystemManagement/api';
import { ExclusionList } from '~pages/SystemManagement/domain';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

enum CallbackRescheduleSeconds {
  Min = 0,
  Max = 86400,
  Default = 3600,
}

enum CallbackMaxReschedules {
  Min = 1,
  Max = 240,
  Never = 0,
  Unlimited = -1,
}

interface LeadEngineSettingsFormProps {
  leadEngineSettings: LeadEngineSettings;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit: () => void;
  onSubmit: (data: LeadEngineSettings) => Promise<void>;
}

const GetLabelFromValue = (options: SelectItem[], value: any): string => {
  for (let option of options) {
    if (option.value === value) {
      return option.label;
    }
  }
  return 'Unknown';
};

const LeadEngineSettingsForm = ({
  leadEngineSettings,
  isEdit,
  submitting,
  toggleEdit,
  onSubmit,
}: LeadEngineSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { pushNotification } = useNotification();
  const [exclusionLists, setExclusionLists] = useState<ExclusionList[]>([]);
  const [washListOptions, setWashListOptions] = useState<WashList[]>([]);
  const [diallingHourProfileOptions, setDiallingHourProfileOptions] = useState<DiallingHourProfile[]>([]);
  const { fields, errors, handleInputChange, handleSubmit, addSchemaProperties, removeSchemaProperties } = useForm({
    allowCallbackReschedule: {
      validators: [],
      value: Boolean(leadEngineSettings.callbackMaxReschedules),
    },
    duplicateSearchAllCampaigns: {
      validators: [],
      value: leadEngineSettings.duplicateSearchAllCampaigns,
    },
    washEndpointOnly: {
      validators: [],
      value: leadEngineSettings.washEndpointOnly,
    },
    allowContactedDuplication: {
      validators: [],
      value: leadEngineSettings.allowContactedDuplication,
    },
    sortAttemptCountFirst: {
      validators: [],
      value: leadEngineSettings.sortAttemptCountFirst,
    },
    diallingHourProfile: {
      validators: [{ type: ValidatorType.Required, message: 'dialling hour profile is required.' }],
      value: leadEngineSettings.diallingHourProfile,
    },
    duplicateSearchDays: {
      validators: [{ type: ValidatorType.Required, message: 'duplicate search days is required.' }],
      value: leadEngineSettings.duplicateSearchDays,
    },
    endpointAttemptIntervalSeconds: {
      validators: [{ type: ValidatorType.Required, message: 'endpoint attempt interval seconds is required.' }],
      value: leadEngineSettings.endpointAttemptIntervalSeconds,
    },
    endpointMachineIntervalSeconds: {
      validators: [{ type: ValidatorType.Required, message: 'endpoint machine interval seconds is required.' }],
      value: leadEngineSettings.endpointMachineIntervalSeconds,
    },
    endpointEngagedIntervalSeconds: {
      validators: [{ type: ValidatorType.Required, message: 'endpoint engaged interval seconds is required.' }],
      value: leadEngineSettings.endpointEngagedIntervalSeconds,
    },
    endpointNoAnswerIntervalSeconds: {
      validators: [{ type: ValidatorType.Required, message: 'endpoint no answer interval seconds is required.' }],
      value: leadEngineSettings.endpointNoAnswerIntervalSeconds,
    },
    endpointDailyMaxAttempts: {
      validators: [{ type: ValidatorType.Required, message: 'endpoint daily max attempts is required.' }],
      value: leadEngineSettings.endpointDailyMaxAttempts,
    },
    externalIdAttemptIntervalSeconds: {
      validators: [{ type: ValidatorType.Required, message: 'external id attempt interval seconds is required.' }],
      value: leadEngineSettings.externalIdAttemptIntervalSeconds,
    },
    externalIdDailyMaxAttempts: {
      validators: [{ type: ValidatorType.Required, message: 'external id daily max attempts is required.' }],
      value: leadEngineSettings.externalIdDailyMaxAttempts,
    },
    leadEndpointTotalMaxAttempts: {
      validators: [{ type: ValidatorType.Required, message: 'lead endpoint total max attemptsis required.' }],
      value: leadEngineSettings.leadEndpointTotalMaxAttempts,
    },
    leadTotalMaxAttempts: {
      validators: [{ type: ValidatorType.Required, message: 'lead total max attempts is required.' }],
      value: leadEngineSettings.leadTotalMaxAttempts,
    },
    washLists: {
      validators: [],
      value: leadEngineSettings.washLists,
    },
    exclusionLists: {
      validators: [],
      value: leadEngineSettings.exclusionLists,
    },
  });
  const leadEngineWashLists = leadEngineSettings.washLists || [];
  const leadEngineExclusionLists = leadEngineSettings.exclusionLists || [];

  useEffect(() => {
    if (fields.allowCallbackReschedule.value) {
      addSchemaProperties({
        reassignMissedCallbackToGroup: {
          validators: [],
          value: leadEngineSettings.reassignMissedCallbackToGroup,
        },
        callbackRescheduleSeconds: {
          validators: [
            {
              type: ValidatorType.Between,
              min: CallbackRescheduleSeconds.Min,
              max: CallbackRescheduleSeconds.Max,
              message: `reschedule seconds must be between ${CallbackRescheduleSeconds.Min} ${CallbackRescheduleSeconds.Max}`,
            },
          ],
          value: leadEngineSettings.callbackRescheduleSeconds || CallbackRescheduleSeconds.Default,
        },
        limitCallbackReschedule: {
          validators: [],
          value: leadEngineSettings.callbackMaxReschedules >= CallbackMaxReschedules.Min,
        },
      });
    } else {
      removeSchemaProperties(['reassignMissedCallbackToGroup', 'callbackRescheduleSeconds', 'limitCallbackReschedule']);
    }
  }, [fields.allowCallbackReschedule.value]);

  useEffect(() => {
    if (fields.limitCallbackReschedule?.value) {
      addSchemaProperties({
        callbackMaxReschedules: {
          validators: [
            {
              type: ValidatorType.Between,
              min: CallbackMaxReschedules.Min,
              max: CallbackMaxReschedules.Max,
              message: `max reschedules must be between ${CallbackMaxReschedules.Min} ${CallbackMaxReschedules.Max}`,
            },
          ],
          value: Math.max(leadEngineSettings.callbackMaxReschedules || 0, CallbackMaxReschedules.Min),
        },
      });
    } else {
      removeSchemaProperties(['callbackMaxReschedules']);
    }
  }, [fields.limitCallbackReschedule?.value]);

  useEffect(() => {
    (async () => {
      let resp;
      try {
        resp = await getExclusionLists();
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
        return;
      }
      setExclusionLists(resp);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let resp;

      try {
        resp = await getWashLists();
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
        return;
      }

      setWashListOptions(resp);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let resp;

      try {
        resp = await getDiallingHourProfiles();
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
        return;
      }

      setDiallingHourProfileOptions(resp);
    })();
  }, []);

  const onSubmitFn = handleSubmit((formData: any) => {
    const data: LeadEngineSettings = formData;

    if (!formData.allowCallbackReschedule) {
      data.callbackMaxReschedules = CallbackMaxReschedules.Never;
    } else if (!formData.limitCallbackReschedule) {
      data.callbackMaxReschedules = CallbackMaxReschedules.Unlimited;
    }

    onSubmit(data);
  });

  const exclusionListDropdown = exclusionLists.map((exclusionList: ExclusionList) => ({
    label: exclusionList.name,
    value: exclusionList.name,
  }));

  const leadEngineWashListsChips = leadEngineWashLists.map((item: string) => (
    <Chip
      key={item}
      sx={{ minWidth: 100, maxWidth: 200, margin: '4px' }}
      variant='filled'
      color='primary'
      label={GetLabelFromValue(washListOptions, item)}
    />
  ));

  const leadEngineExclusionListsChips = leadEngineExclusionLists.map((item: string) => (
    <Chip
      key={item}
      sx={{ minWidth: 100, maxWidth: 200, margin: '4px' }}
      variant='filled'
      color='primary'
      label={item}
    />
  ));

  // We do this so that if we have zero results we allow the DataItem to add '-' for an undefined or null value
  const leadEngineWashListsDisplay =
    leadEngineWashListsChips.length > 0 ? <Box sx={{ marginLeft: '-4px' }}>{leadEngineWashListsChips}</Box> : null;

  // We do this so that if we have zero results we allow the DataItem to add '-' for an undefined or null value
  const leadEngineExclusionListsDisplay =
    leadEngineExclusionListsChips.length > 0 ? (
      <Box sx={{ marginLeft: '-4px' }}>{leadEngineExclusionListsChips}</Box>
    ) : null;

  return (
    <SectionCard title='Lead Engine Settings' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem
            stacked={isExtraSmall}
            disableMargin
            tooltip='Should the campaign search for duplicate leads across all campaigns.'
            title='Duplicate Search All Campaigns'
            value={leadEngineSettings.duplicateSearchAllCampaigns ? 'Yes' : 'No'}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Wash Endpoint Only'
            tooltip='Should lead endpoints be washed against an exclusion list.'
            value={leadEngineSettings.washEndpointOnly ? 'Yes' : 'No'}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Allow Contacted Duplication'
            tooltip='Even if an uploaded lead has been attempted in the past, start dialling them again.'
            value={leadEngineSettings.allowContactedDuplication ? 'Yes' : 'No'}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Dialling Hour Profile'
            tooltip='Will determine the hours when leads can be dialled.'
            value={GetLabelFromValue(diallingHourProfileOptions, leadEngineSettings.diallingHourProfile)}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Duplicate Search Days'
            tooltip='The amount of days for the lead engine to reference to consider a leads as a duplicate.'
            value={leadEngineSettings.duplicateSearchDays}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Endpoint Attempt Interval Seconds'
            tooltip='The number of seconds the lead engine needs to wait before subsequent redials to an endpoint.'
            value={leadEngineSettings.endpointAttemptIntervalSeconds}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Endpoint Machine Interval Seconds'
            tooltip='The number of seconds the lead engine needs to wait before subsequent redials to an endpoint marked with an outcome of answering machine.'
            value={leadEngineSettings.endpointMachineIntervalSeconds}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Endpoint Engaged Interval Seconds'
            tooltip='The number of seconds the lead engine needs to wait before subsequent redials to an endpoint marked with an outcome of engaged.'
            value={leadEngineSettings.endpointEngagedIntervalSeconds}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Endpoint No Answer Interval Seconds'
            tooltip='The number of seconds the lead engine needs to wait before subsequent redials to an endpoint marked with an outcome of no answer.'
            value={leadEngineSettings.endpointNoAnswerIntervalSeconds}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Endpoint Daily Max Attempts'
            tooltip='The maximum number of times a day an endpoint can be dialed.'
            value={leadEngineSettings.endpointDailyMaxAttempts}
          />
          <DataItem
            stacked={isExtraSmall}
            title='External Id Attempt Interval Seconds'
            tooltip='The number of seconds the lead engine needs to wait before subsequent redials to an external ID.'
            value={leadEngineSettings.externalIdAttemptIntervalSeconds}
          />
          <DataItem
            stacked={isExtraSmall}
            title='External Id Daily Max Attempts'
            tooltip='The maximum number of times an external ID can be dialled.'
            value={leadEngineSettings.externalIdDailyMaxAttempts}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Lead Endpoint Total Max Attempts'
            tooltip='The maximum number of times a day a lead’s endpoint can be dialled.'
            value={leadEngineSettings.leadEndpointTotalMaxAttempts}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Lead Total Max Attempts'
            tooltip='The minimum number of times a day a lead can be dialled, regardless of the amount of endpoints it has.'
            value={leadEngineSettings.leadTotalMaxAttempts}
          />
          <DataItem
            stacked={isExtraSmall}
            tooltip='The maximum number of times to reschedule a missed callback before it must be handled manually or reassigned to another agent.'
            title='Maximum Reschedules for a Callback'
            value={
              (leadEngineSettings.callbackMaxReschedules || 0) < 0
                ? 'Unlimited'
                : leadEngineSettings.callbackMaxReschedules
            }
          />
          {(leadEngineSettings.callbackMaxReschedules || 0) != 0 && (
            <>
              <DataItem
                stacked={isExtraSmall}
                tooltip='Reschedule a missed callback for this number of seconds in the future.'
                title='Reschedule Callback Seconds'
                value={leadEngineSettings.callbackRescheduleSeconds || 0}
              />
              <DataItem
                stacked={isExtraSmall}
                tooltip='Whether a callback can be reassigned to another agent if the assigned agent is not present.'
                title='Reassign Missed Callback to Group'
                value={leadEngineSettings.reassignMissedCallbackToGroup ? 'Yes' : 'No'}
              />
            </>
          )}
          <DataItem
            stacked={isExtraSmall}
            tooltip='Whether leads with fewer attempts should be tried before those with higher lead priority.'
            title='Sort Attempt Count Before Priority'
            value={leadEngineSettings.sortAttemptCountFirst ? 'Yes' : 'No'}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Wash Lists'
            tooltip='External do not call register used to wash leads within campaign lists.'
            value={leadEngineWashListsDisplay}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Exclusion Lists'
            tooltip='Internal do not call register used to wash leads within campaign lists.'
            value={leadEngineExclusionListsDisplay}
          />
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmitFn} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id='duplicateSearchAllCampaigns'
                    name='duplicateSearchAllCampaigns'
                    checked={fields.duplicateSearchAllCampaigns.value}
                    onChange={handleInputChange}
                  />
                }
                label='Duplicate Search All Campaigns?'
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id='washEndpointOnly'
                    name='washEndpointOnly'
                    checked={fields.washEndpointOnly.value}
                    onChange={handleInputChange}
                  />
                }
                label='Wash Endpoint Only?'
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id='allowContactedDuplication'
                    name='allowContactedDuplication'
                    checked={fields.allowContactedDuplication.value}
                    onChange={handleInputChange}
                  />
                }
                label='Allow Contacted Duplication?'
              />
            </Grid>

            <Grid item xs={12}>
              <Selectbox
                id='diallingHourProfile'
                name='diallingHourProfile'
                title='Dialling Hour Profile'
                disabled={submitting}
                required={true}
                items={diallingHourProfileOptions}
                value={fields.diallingHourProfile.value}
                error={Boolean(errors.diallingHourProfile)}
                helperText={errors.diallingHourProfile && errors.diallingHourProfile[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='duplicateSearchDays'
                name='duplicateSearchDays'
                label='Duplicate Search Days'
                disabled={submitting}
                required={true}
                defaultValue={fields.duplicateSearchDays.value}
                error={Boolean(errors.duplicateSearchDays)}
                helperText={errors.duplicateSearchDays && errors.duplicateSearchDays[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='endpointAttemptIntervalSeconds'
                name='endpointAttemptIntervalSeconds'
                label='Endpoint Attempt Interval Seconds'
                disabled={submitting}
                required={true}
                defaultValue={fields.endpointAttemptIntervalSeconds.value}
                error={Boolean(errors.endpointAttemptIntervalSeconds)}
                helperText={errors.endpointAttemptIntervalSeconds && errors.endpointAttemptIntervalSeconds[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='endpointMachineIntervalSeconds'
                name='endpointMachineIntervalSeconds'
                label='Endpoint Machine Interval Seconds'
                disabled={submitting}
                required={true}
                defaultValue={fields.endpointMachineIntervalSeconds.value}
                error={Boolean(errors.endpointMachineIntervalSeconds)}
                helperText={errors.endpointMachineIntervalSeconds && errors.endpointMachineIntervalSeconds[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='endpointEngagedIntervalSeconds'
                name='endpointEngagedIntervalSeconds'
                label='Endpoint Engaged Interval Seconds'
                disabled={submitting}
                required={true}
                defaultValue={fields.endpointEngagedIntervalSeconds.value}
                error={Boolean(errors.endpointEngagedIntervalSeconds)}
                helperText={errors.endpointEngagedIntervalSeconds && errors.endpointEngagedIntervalSeconds[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='endpointNoAnswerIntervalSeconds'
                name='endpointNoAnswerIntervalSeconds'
                label='Endpoint No Answer Interval Seconds'
                disabled={submitting}
                required={true}
                defaultValue={fields.endpointNoAnswerIntervalSeconds.value}
                error={Boolean(errors.endpointNoAnswerIntervalSeconds)}
                helperText={errors.endpointNoAnswerIntervalSeconds && errors.endpointNoAnswerIntervalSeconds[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='endpointDailyMaxAttempts'
                name='endpointDailyMaxAttempts'
                label='Endpoint Daily Max Attempts'
                disabled={submitting}
                required={true}
                defaultValue={fields.endpointDailyMaxAttempts.value}
                error={Boolean(errors.endpointDailyMaxAttempts)}
                helperText={errors.endpointDailyMaxAttempts && errors.endpointDailyMaxAttempts[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='externalIdAttemptIntervalSeconds'
                name='externalIdAttemptIntervalSeconds'
                label='External Id Attempt Interval Seconds'
                disabled={submitting}
                required={true}
                defaultValue={fields.externalIdAttemptIntervalSeconds.value}
                error={Boolean(errors.externalIdAttemptIntervalSeconds)}
                helperText={errors.externalIdAttemptIntervalSeconds && errors.externalIdAttemptIntervalSeconds[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='externalIdDailyMaxAttempts'
                name='externalIdDailyMaxAttempts'
                label='External Id Daily Max Attempts'
                disabled={submitting}
                required={true}
                defaultValue={fields.externalIdDailyMaxAttempts.value}
                error={Boolean(errors.externalIdDailyMaxAttempts)}
                helperText={errors.externalIdDailyMaxAttempts && errors.externalIdDailyMaxAttempts[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='leadEndpointTotalMaxAttempts'
                name='leadEndpointTotalMaxAttempts'
                label='Lead Endpoint Total Max Attempts'
                disabled={submitting}
                required={true}
                defaultValue={fields.leadEndpointTotalMaxAttempts.value}
                error={Boolean(errors.leadEndpointTotalMaxAttempts)}
                helperText={errors.leadEndpointTotalMaxAttempts && errors.leadEndpointTotalMaxAttempts[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='leadTotalMaxAttempts'
                name='leadTotalMaxAttempts'
                label='Lead Total Max Attempts'
                disabled={submitting}
                required={true}
                defaultValue={fields.leadTotalMaxAttempts.value}
                error={Boolean(errors.leadTotalMaxAttempts)}
                helperText={errors.leadTotalMaxAttempts && errors.leadTotalMaxAttempts[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id='allowCallbackReschedule'
                    name='allowCallbackReschedule'
                    checked={fields.allowCallbackReschedule.value}
                    onChange={handleInputChange}
                  />
                }
                label='Reschedule missed callbacks?'
              />
            </Grid>

            {fields.allowCallbackReschedule.value && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='reassignMissedCallbackToGroup'
                        name='reassignMissedCallbackToGroup'
                        checked={fields.reassignMissedCallbackToGroup?.value || false}
                        onChange={handleInputChange}
                      />
                    }
                    label='Reassign Missed Callback to another Agent?'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type='number'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { min: CallbackRescheduleSeconds.Min, Max: CallbackRescheduleSeconds.Max },
                    }}
                    variant='outlined'
                    id='callbackRescheduleSeconds'
                    name='callbackRescheduleSeconds'
                    label='Reschedule Callback Seconds'
                    disabled={submitting}
                    required={true}
                    value={fields.callbackRescheduleSeconds?.value || CallbackRescheduleSeconds.Min}
                    error={Boolean(errors.callbackRescheduleSeconds)}
                    helperText={errors.callbackRescheduleSeconds && errors.callbackRescheduleSeconds[0]!}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='limitCallbackReschedule'
                        name='limitCallbackReschedule'
                        checked={fields.limitCallbackReschedule?.value || false}
                        onChange={handleInputChange}
                      />
                    }
                    label='Limit the number of times a callback is rescheduled?'
                  />
                </Grid>

                {fields.limitCallbackReschedule?.value && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type='number'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ inputProps: { min: CallbackMaxReschedules.Min, max: CallbackMaxReschedules.Max } }}
                      variant='outlined'
                      id='callbackMaxReschedules'
                      name='callbackMaxReschedules'
                      label='Maximum Reschedules for a Callback'
                      disabled={submitting}
                      required={true}
                      value={fields.callbackMaxReschedules?.value || CallbackMaxReschedules.Min}
                      error={Boolean(errors.callbackMaxReschedules)}
                      helperText={errors.callbackMaxReschedules && errors.callbackMaxReschedules[0]!}
                      onChange={handleInputChange}
                    />
                  </Grid>
                )}
              </>
            )}

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id='sortAttemptCountFirst'
                    name='sortAttemptCountFirst'
                    checked={fields.sortAttemptCountFirst.value}
                    onChange={handleInputChange}
                  />
                }
                label='Sort leads by attempt count before priority?'
              />
            </Grid>

            <Grid item xs={12}>
              <SelectboxMulti
                id='washLists'
                name='washLists'
                title='Wash Lists'
                items={washListOptions}
                value={fields.washLists.value}
                disabled={submitting}
                required={false}
                error={Boolean(errors.washLists)}
                helperText={errors.washLists && errors.washLists[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectboxMulti
                id='exclusionLists'
                name='exclusionLists'
                title='Exclusion Lists'
                items={exclusionListDropdown}
                value={fields.exclusionLists.value}
                disabled={submitting}
                required={false}
                error={Boolean(errors.exclusionLists)}
                helperText={errors.exclusionLists && errors.exclusionLists[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submitting}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default LeadEngineSettingsForm;
