import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { TabPanel } from '~components/TabPanel';
import DiallerGroupLiveDash from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupLiveDash';
import useDiallerGroup from '~pages/CampaignManagement/DiallerGroupDetails/useDiallerGroup';
import { DiallerType } from '~pages/CampaignManagement/domain';
import { PolicyType, isAllowedRole, useAuth } from '~providers/AuthProvider';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import Routes from '~providers/RouteProvider/Routes';

import DiallerGroupAgents from './DiallerGroupAgents';
import DiallerGroupCampaigns from './DiallerGroupCampaigns';
import DiallerGroupSettings from './DiallerGroupSettings';

// Used to manage visible tab selection
enum ViewTabsType {
  Agents,
  Campaigns,
  LiveDash,
  Settings,
}

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tab-${index}`,
});

// default tab, fallback to this tab when no permission on specified tab
const defaultViewTab = ViewTabsType.Agents;

const DiallerGroupDetails = () => {
  const navigate = useNavigate();
  const { policies } = useAuth();

  // Map for restricted tabs(key: ViewTabsType, value: boolean(true means can access))
  const restrictedViewTabs = useMemo(
    () => new Map([[ViewTabsType.Settings, isAllowedRole([PolicyType.DiallerAdmin], policies)]]),
    [policies],
  );

  // check whether we have permission on specified ViewTabsType
  const hasViewTabPermission = useCallback(
    (viewTabsType: ViewTabsType) => {
      const allowed = restrictedViewTabs.get(viewTabsType);
      return allowed === undefined || allowed;
    },
    [restrictedViewTabs],
  );

  const setPageTitleProps = useSetPageTitleProps();
  const { diallerGroupId } = useParams() as { diallerGroupId: string };
  const { loading, data, error, update, archive, reload } = useDiallerGroup(+diallerGroupId);
  const initialLoad = loading && !data;
  const [searchParams, setSearchParams] = useSearchParams({
    show: ViewTabsType[defaultViewTab],
  });
  const tabIndex = +ViewTabsType[searchParams.get('show') as any];

  useEffect(() => {
    // fallback to default one when no permission(can happen when show parameter in the URL is manually updated)
    if (!hasViewTabPermission(tabIndex)) {
      setSearchParams({
        show: ViewTabsType[defaultViewTab],
      });
    }
  }, []);

  useEffect(() => {
    reload();
  }, [tabIndex]);

  useEffect(() => {
    if (data) {
      // Set page title
      setPageTitleProps({ pageName: data.name });
    }
  }, [data]);

  const onTabChange = (e: ChangeEvent<{}>, tabIndex: number) => {
    setSearchParams({
      show: ViewTabsType[tabIndex],
    });
  };

  const errorAction = () => {
    navigate(Routes.diallerConfig.path);
  };

  const errorDisplay = error ? (
    <EmptyState
      type='error'
      text={error}
      subText='Click the button below to return to dialler configuration.'
      action={errorAction}
      actionText='Dialler Config'
    />
  ) : null;

  return (
    <AsyncLoader isLoading={initialLoad} error={errorDisplay}>
      <Breadcrumbs style={{ marginBottom: 16 }} aria-label='breadcrumb'>
        <Link underline='hover' color='inherit' component={RouterLink} to={Routes.diallerConfig.path}>
          Dialler Configuration
        </Link>
        <Typography color='textPrimary'>Dialler Group Details</Typography>
      </Breadcrumbs>

      {data === undefined && (
        <EmptyState
          type='not-found'
          text='Sorry!'
          subText='We are unable to find the page you are looking for. Click the button below to return to campaign details.'
          action={errorAction}
          actionText='Campaigns Details'
        />
      )}

      {data !== undefined && (
        <>
          <Typography variant='h4' component='h1' gutterBottom>
            {data.name}
          </Typography>

          <Tabs
            orientation='horizontal'
            variant='scrollable'
            onChange={onTabChange}
            value={tabIndex}
            indicatorColor='primary'
            aria-label='Vertical tabs example'>
            <Tab label='Agents' {...a11yProps(ViewTabsType.Agents)} value={ViewTabsType.Agents} />
            <Tab label='Campaigns' {...a11yProps(ViewTabsType.Campaigns)} value={ViewTabsType.Campaigns} />
            {data.diallerType == DiallerType.SIP && (
              <Tab label='Live Dash' {...a11yProps(ViewTabsType.LiveDash)} value={ViewTabsType.LiveDash} />
            )}
            {hasViewTabPermission(ViewTabsType.Settings) && (
              <Tab label='Settings' {...a11yProps(ViewTabsType.Settings)} value={ViewTabsType.Settings} />
            )}
          </Tabs>
          <Divider variant='fullWidth' component='hr' />

          <TabPanel value={tabIndex} index={ViewTabsType.Agents}>
            <DiallerGroupAgents />
          </TabPanel>

          <TabPanel value={tabIndex} index={ViewTabsType.Campaigns}>
            <DiallerGroupCampaigns diallerType={data.diallerType} campaignType={data.campaignType} />
          </TabPanel>

          <TabPanel value={tabIndex} index={ViewTabsType.LiveDash}>
            <DiallerGroupLiveDash diallerGroup={data} />
          </TabPanel>

          {hasViewTabPermission(ViewTabsType.Settings) && (
            <TabPanel value={tabIndex} index={ViewTabsType.Settings}>
              <DiallerGroupSettings diallerGroup={data} update={update} archive={archive} />
            </TabPanel>
          )}
        </>
      )}
    </AsyncLoader>
  );
};

export default DiallerGroupDetails;
