import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import PhoneIcon from '@mui/icons-material/Phone';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Grid from '@mui/material/Grid';
import React from 'react';

import InfoCard from '~components/InfoCard';
import { getDurationFromSeconds, pluraliseWord } from '~utils/Functions';

import { LeadList } from '../../domain';

interface LeadListSummaryProps {
  leadList: LeadList;
}

const LeadListSummary = ({ leadList }: LeadListSummaryProps) => {
  return (
    <Grid container spacing={1} alignContent='center'>
      <Grid item xs={12} md={4}>
        <InfoCard variant='info' icon={DonutLargeIcon} text={leadList?.progress.toFixed(2)} subText='% progress' />
      </Grid>

      <Grid item xs={12} md={4}>
        <InfoCard
          variant='info'
          icon={DonutLargeIcon}
          text={leadList?.penetrationRate.toFixed(2)}
          subText='% penetration rate'
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <InfoCard variant='info' icon={WatchLaterIcon} text={getDurationFromSeconds(leadList?.aht)} subText='aht' />
      </Grid>

      <Grid item xs={12} md={4}>
        <InfoCard
          variant='info'
          icon={ContactPhoneIcon}
          text={leadList?.totalLeads.toLocaleString()}
          subText='total leads'
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <InfoCard
          variant='info'
          icon={PhoneIcon}
          text={leadList?.numCompleted.toLocaleString()}
          subText={`${pluraliseWord(leadList?.numCompleted, 'lead')} called`}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <InfoCard
          variant='info'
          icon={PhoneIcon}
          text={leadList?.numAttempts.toLocaleString()}
          subText={`${pluraliseWord(leadList?.numAttempts, 'lead')} attempted`}
        />
      </Grid>
    </Grid>
  );
};

export default LeadListSummary;
