import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import AgentCard from '~components/AgentCard';
import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import { UpdateAgentGroupAssignment } from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupAgents/useGroupAssignedAgentSearch/domain';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import AssignEditAgentModal from './AssignAgentModal';
import useGroupAssignedAgentSearch from './useGroupAssignedAgentSearch';

interface Query {
  search: string;
}

const DiallerGroupAgents = () => {
  const { pushNotification } = useNotification();
  const { diallerGroupId } = useParams() as { diallerGroupId: string };
  const [assignModalOpen, setAssignModalOpen] = useState<boolean>(false);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [query, setQuery] = useState<Query>({ search: '' });
  const debouncedSearch = useDebounce(query.search, 500);
  const { loading, error, list, hasMore, getNextPage, add, remove } = useGroupAssignedAgentSearch(
    +diallerGroupId,
    debouncedSearch,
  );
  const noSearchSet = query.search;
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const lastDataElement = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const toggleAssignModal = () => {
    setAssignModalOpen((prev) => !prev);
  };

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const assignAgents = async (data: UpdateAgentGroupAssignment) => {
    setSubmittingData(true);

    try {
      await add(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', 'Assigned Agents to dialler group');
    setAssignModalOpen(false);
  };

  const removeAssignedAgent = (username: string) => async () => {
    try {
      await remove(username);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }

    pushNotification('success', `removed agent ${username} from dialler group`);
  };

  const displayList = list.map((agent, index) => {
    let props: any = {
      key: agent.username,
      fullName: agent.fullName,
      username: agent.username,
      menuItems: [{ name: 'Remove', action: removeAssignedAgent(agent.username) }],
    };

    if (index === list.length - 1) {
      props = { ...props, ref: lastDataElement };
    }

    return <AgentCard {...props} />;
  });

  return (
    <>
      <Grid sx={{ marginBottom: 1 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            defaultValue={query.search}
            onChange={onQueryChange}
          />
        </Grid>

        <Hidden smDown>
          <Grid item md={5}></Grid>
        </Hidden>

        <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            startIcon={<AddIcon />}
            onClick={toggleAssignModal}>
            Assign Agents
          </Button>
        </Grid>
      </Grid>

      <AsyncLoader isLoading={loading && list.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {list.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && list.length > 0 && <DotLoader align='center' />}

                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}

                {error && list.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load agents
                  </Typography>
                )}
              </>
            )}

            {list.length === 0 && noSearchSet && (
              <EmptyState
                type='no-records-found'
                text='No agents found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}

            {list.length === 0 && !noSearchSet && <EmptyState type='no-items-3' text='No agents assigned to group' />}
          </Grid>
        </Grid>

        <AssignEditAgentModal
          open={assignModalOpen}
          diallerGroupId={+diallerGroupId}
          submitting={submittingData}
          onClose={toggleAssignModal}
          onAccept={assignAgents}
        />
      </AsyncLoader>
    </>
  );
};

export default DiallerGroupAgents;
