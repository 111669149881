import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers';
import axios, { CancelTokenSource } from 'axios';
import { DateTime } from 'luxon';
import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import { BasicLineChart, BasicLineChartData } from '~components/BasicLineChart';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import Selectbox from '~components/Form/Selectbox';
import SectionCard from '~components/SectionCard';
import useAgentList from '~hooks/useAgentList';
import useDebounce from '~hooks/useDebounce';
import { DiallerType } from '~pages/CampaignManagement/domain';
import { Agent } from '~pages/SystemManagement/domain';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import { useUserPreferences } from '~providers/UserPreferencesProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';
import { calculatePercentage, convertToColor } from '~utils/Functions';

import { getMetrics } from './api';
import { MetricItem, MetricsResponse, StreamType } from './domain';

interface Query {
  diallerType: DiallerType;
  agents: Agent[];
  dateFrom: string;
  dateTo: string;
}

interface Error {
  text: string;
  subText: string;
}

const DiallerTypeSelect = [
  {
    label: 'Connect',
    value: DiallerType.Connect,
  },
  {
    label: 'SIP',
    value: DiallerType.SIP,
  },
];

const getChartXAxis = (metrics: MetricItem[]): string[] => {
  const arr: string[] = [];

  for (let i = 0; i < metrics.length; i++) {
    arr.push(DateTime.fromISO(metrics[i].timestamp).toFormat('F'));
  }

  return [...new Set(arr)];
};

const getBasicChartData = (
  metrics: MetricItem[],
  xKey: keyof MetricItem,
  yKey: keyof MetricItem,
): BasicLineChartData[] => {
  let data: { [key: string]: BasicLineChartData } = {};

  for (let metric of metrics) {
    if (metric.streamType !== StreamType.AudioAgentToDialler && metric.streamType !== StreamType.AudioDiallerToAgent) {
      continue;
    }

    let key = '';
    let lineColor = '';

    if (metric.streamType === StreamType.AudioAgentToDialler) {
      key = `${metric.agentUsername} - ${StreamType.AudioAgentToDialler}`;
      lineColor = convertToColor(metric.agentUsername);
    }

    if (metric.streamType === StreamType.AudioDiallerToAgent) {
      key = `${metric.agentUsername} - ${StreamType.AudioDiallerToAgent}`;
      lineColor = convertToColor(metric.agentUsername, null, 0.4);
    }

    if (!data[key]) {
      data = {
        ...data,
        [key]: {
          key: key,
          lineColor: lineColor,
          lineItems: [],
        },
      };
    }

    data = {
      ...data,
      [key]: {
        ...data[key],
        lineItems: [
          ...data[key].lineItems,
          {
            x: DateTime.fromISO(metric[xKey] as string).toFormat('F'),
            y: metric[yKey] as number,
          },
        ],
      },
    };
  }

  return Object.values(data);
};

const getPacketsLostChartData = (metrics: MetricItem[]): BasicLineChartData[] => {
  let data: { [key: string]: BasicLineChartData } = {};

  for (let metric of metrics) {
    if (metric.streamType !== StreamType.AudioAgentToDialler && metric.streamType !== StreamType.AudioDiallerToAgent) {
      continue;
    }

    let key = '';
    let lineColor = '';

    if (metric.streamType === StreamType.AudioAgentToDialler) {
      key = `${metric.agentUsername} - ${StreamType.AudioAgentToDialler}`;
      lineColor = convertToColor(metric.agentUsername);
    }

    if (metric.streamType === StreamType.AudioDiallerToAgent) {
      key = `${metric.agentUsername} - ${StreamType.AudioDiallerToAgent}`;
      lineColor = convertToColor(metric.agentUsername, null, 0.4);
    }

    if (!data[key]) {
      data = {
        ...data,
        [key]: {
          key: key,
          lineColor: lineColor,
          lineItems: [],
        },
      };
    }

    data = {
      ...data,
      [key]: {
        ...data[key],
        lineItems: [
          ...data[key].lineItems,
          {
            x: DateTime.fromISO(metric.timestamp).toFormat('F'),
            y: calculatePercentage(metric.packetsLost, metric.packetsCount),
          },
        ],
      },
    };
  }

  return Object.values(data);
};

const getArraySeriesAverage = (metrics: MetricItem[], propertyKey: keyof MetricItem): string | null => {
  const valuesArray: number[] = [];

  for (let i = 0; i < metrics.length; i++) {
    valuesArray.push(metrics[i][propertyKey] as number);
  }

  const totalBeforeAverage = valuesArray.reduce((accumulator: number, currentValue: number) => {
    return accumulator + currentValue;
  }, 0);

  if (totalBeforeAverage === 0) return null;

  return (totalBeforeAverage / valuesArray.length).toFixed(2);
};

const CallStatistics = () => {
  const setPageTitleProps = useSetPageTitleProps();
  const { accessFilter } = useUserPreferences();
  const [metrics, setMetrics] = useState<MetricItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [dateToValidationError, setDateToValidationError] = useState<string | undefined>(undefined);
  const dateToDefault = DateTime.fromJSDate(new Date());
  const [searchAgent, setSearchAgent] = useState<string>('');
  const debouncedSearchAgent = useDebounce(searchAgent, 500);
  const [query, setQuery] = useState<Query>({
    diallerType: DiallerType.Connect,
    agents: [],
    dateFrom: dateToDefault.minus({ days: 7 }).toISO(),
    dateTo: dateToDefault.toISO(),
  });
  const {
    loading: agentFetching,
    error: agentFetchError,
    agents,
    intersectionObserverRef: lastAgentDataElement,
  } = useAgentList(debouncedSearchAgent, {
    accessFilterId: accessFilter?.id,
  });
  const queryDateToMinDate = useMemo(() => DateTime.fromISO(query.dateFrom), [query.dateFrom]);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'Call Statistics' });
  }, []);

  // Initial Data Load
  useEffect(() => {
    (async () => {
      // Reset error from previous query
      setError(null);
      setLoading(true);

      let data: MetricsResponse;

      try {
        axiosCancelRef.current = axios.CancelToken.source();
        data = await getMetrics(
          query.dateFrom,
          query.dateTo,
          query.diallerType,
          query.agents.map((item) => item.username),
          accessFilter?.id,
        );
      } catch (e) {
        handleError(e);
        return;
      } finally {
        setLoading(false);
      }

      setMetrics(data.metrics);
    })();

    return () => {
      axiosCancelRef.current.cancel();
    };
  }, [query.dateFrom, query.dateTo, query.diallerType, query.agents, accessFilter?.id]);

  const handleError = (e: any) => {
    console.error(e);
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }
    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  // Hack of applying <DateTime> generic type on the DatePicker components as then it
  // inforces correct typing for this onChange function so it's typing is not messed up. This breaks visible
  // typing however even though it passed type checking. i.e. defers TDate generic from input value which is wrong
  // as minDate then also overrides this with its DateTime generic
  const handleDatepickerChange = (fieldName: string) => (dateObj: DateTime | null) => {
    let date: string | null = null;

    if (dateObj !== null) {
      date = dateObj.toISO();
    }

    // Reset selected agents and update date value
    setQuery((prev) => ({
      ...prev,
      agents: [],
      [fieldName]: date,
    }));
  };

  const handleDiallerTypeChange = (name: string, value: any) => {
    setQuery((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAutoCompleteAgentsChange = (e: ChangeEvent<{}>, value: Agent[]) => {
    setQuery((prev) => ({
      ...prev,
      agents: value,
    }));
  };

  const diallerTypeMetrics = metrics.filter((item) => item.diallerType === query.diallerType);

  const packetsLostAvg = getArraySeriesAverage(diallerTypeMetrics, 'packetsLost');
  const packetsLostAvgDisplay = packetsLostAvg === null ? 'N/A' : `${packetsLostAvg}%`;
  const roundTripAvg = getArraySeriesAverage(diallerTypeMetrics, 'roundTripTimeMillis');
  const roundTripAvgDisplay = roundTripAvg === null ? 'N/A' : `${roundTripAvg}ms`;
  const jitterBufferAvg = getArraySeriesAverage(diallerTypeMetrics, 'jitterBufferMillis');
  const jitterBufferAvgDisplay = jitterBufferAvg === null ? 'N/A' : `${jitterBufferAvg}ms`;

  const packetsLostChartData = getPacketsLostChartData(diallerTypeMetrics);
  const roundTripChartData = getBasicChartData(diallerTypeMetrics, 'timestamp', 'roundTripTimeMillis');
  const jitterBufferChartData = getBasicChartData(diallerTypeMetrics, 'timestamp', 'jitterBufferMillis');
  // Get x axis values
  const xAxis = getChartXAxis(diallerTypeMetrics);

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchAgent(e.target.value);
  }, []);

  const onSearchBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
    setSearchAgent('');
  }, []);

  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : null;

  const agentNoOptionsText = useMemo(() => {
    if (agentFetching) {
      return <DotLoader align='center' />;
    }

    if (agentFetchError) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          Failed to load agents
        </Typography>
      );
    }

    return undefined;
  }, [agentFetching, agentFetchError]);

  const agentFilteredList = useMemo(() => {
    if (!query.agents) {
      return agents;
    }

    return agents.filter((listItem) => {
      return !query.agents.find((queryItem) => listItem.username === queryItem.username);
    });
  }, [agents, query.agents]);

  return (
    <>
      <Typography variant='h4' component='h1' gutterBottom>
        Call Statistics
      </Typography>

      <Grid sx={{ marginBottom: 3 }} container spacing={1}>
        <Grid item xs={12} md={6}>
          <DateTimePicker
            disableMaskedInput
            disableFuture
            inputFormat='DDDD hh:mm a'
            label='Date From'
            value={query.dateFrom}
            onChange={handleDatepickerChange('dateFrom')}
            renderInput={(params) => <TextField {...params} fullWidth variant='outlined' size='small' />}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DateTimePicker
            disableMaskedInput
            disableFuture
            minDate={queryDateToMinDate}
            onError={(reason) => {
              if (reason === 'minDate') {
                setDateToValidationError('Date To should not be before Date From.');
                return;
              }

              setDateToValidationError(undefined);
            }}
            inputFormat='DDDD hh:mm a'
            label='Date To'
            value={query.dateTo}
            onChange={handleDatepickerChange('dateTo')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant='outlined'
                size='small'
                error={Boolean(dateToValidationError)}
                helperText={dateToValidationError}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Selectbox
            id='diallerType'
            size='small'
            name='diallerType'
            title='Dialler Type'
            items={DiallerTypeSelect}
            value={query.diallerType || ''}
            onChange={(e) => handleDiallerTypeChange('diallerType', e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            fullWidth
            size='small'
            onChange={handleAutoCompleteAgentsChange}
            value={query.agents}
            options={agentFilteredList}
            noOptionsText={agentNoOptionsText}
            getOptionLabel={(option) => option?.fullName || ''}
            renderOption={(props, option) => (
              <li {...props} ref={lastAgentDataElement} key={option.username}>
                <div>
                  <Typography variant='body2' color='textPrimary' component='p'>
                    {option.fullName}
                  </Typography>

                  <Typography variant='caption' color='textSecondary' component='p'>
                    {option.username}
                  </Typography>
                </div>
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  deleteIcon={<CancelIcon style={{ color: convertToColor(option.username, 100) }} />}
                  style={{
                    backgroundColor: convertToColor(option.username, 30),
                    color: convertToColor(option.username, 100),
                  }}
                  label={option.fullName}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label='Agents'
                variant='outlined'
                required={true}
                onBlur={onSearchBlur}
                onChange={onSearchChange}
              />
            )}
          />
        </Grid>
      </Grid>

      <AsyncLoader isLoading={loading} error={errorDisplay}>
        <SectionCard title='Packets Lost' subTitle={`Avg: ${packetsLostAvgDisplay}`}>
          <BasicLineChart
            xAxis={xAxis}
            data={packetsLostChartData}
            yAxisSuffix='%'
            tooltipValueFormat={(value, suffix) => {
              return `${Number(value).toFixed(2)}${suffix}`;
            }}
          />
        </SectionCard>

        <SectionCard title='Round Trip' subTitle={`Avg: ${roundTripAvgDisplay}`}>
          <BasicLineChart xAxis={xAxis} data={roundTripChartData} yAxisSuffix='ms' />
        </SectionCard>

        <SectionCard title='Jitter Buffer' subTitle={`Avg: ${jitterBufferAvgDisplay}`}>
          <BasicLineChart xAxis={xAxis} data={jitterBufferChartData} yAxisSuffix='ms' />
        </SectionCard>
      </AsyncLoader>
    </>
  );
};

export default CallStatistics;
