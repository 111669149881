import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import SiteLayout from '~components/SiteLayout';
import { PolicyType, isAllowedRole, useAuth } from '~providers/AuthProvider';
import { useRoutes } from '~providers/RouteProvider';
import Page from '~providers/RouteProvider/Page';
import RouteMap from '~providers/RouteProvider/Routes';

const Pages = () => {
  const routes = useRoutes();
  const navigate = useNavigate();
  const location = useLocation();
  const { policies } = useAuth();
  const routeToCampaigns = isAllowedRole(
    [PolicyType.TeamLeader, PolicyType.Manager, PolicyType.WorkforceAnalyst, PolicyType.DiallerAdmin],
    policies,
  );
  const routeToContactLogs = isAllowedRole(
    [PolicyType.TeamLeader, PolicyType.Manager, PolicyType.QualityAnalyst, PolicyType.DiallerAdmin],
    policies,
  );

  // Handles initial login redirect to the first dynamic route
  // Is there a better way to do this???
  useEffect(() => {
    if (location.pathname === '/' && routes[0] !== undefined) {
      // Policy based landing page pathing in order of importance
      if (routeToCampaigns) {
        navigate(RouteMap.diallerConfig.path, { replace: true });
      } else if (routeToContactLogs) {
        navigate(RouteMap.contactLogs.path, { replace: true });
      } else {
        // Agent and all other roles should land on the first available route
        navigate(routes[0].path, { replace: true });
      }
    }
  }, [location.pathname, routes]);

  const routeDisplay = routes.map(({ path, component: Component }: Page, index: number) =>
    Component ? <Route key={index} path={path} element={<Component />} /> : null,
  );

  return (
    <SiteLayout>
      <Routes>{routeDisplay}</Routes>
    </SiteLayout>
  );
};

export default Pages;
