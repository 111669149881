import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/system/Box';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import SectionCard from '~components/SectionCard';
import {
  Campaign,
  RoutingProfile,
  UpdateCampaignGeneralSettings,
  typeIdsToDisplayText,
} from '~pages/CampaignManagement/domain';
import { findIndexByProperty } from '~utils/Functions';

interface GeneralSettingsFormProps {
  campaign: Campaign;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit: () => void;
  onSubmit: (data: UpdateCampaignGeneralSettings) => Promise<void>;
  routingProfiles: RoutingProfile[];
}

interface Form {
  name: string;
  description: string;
  defaultListId: number;
  routingProfiles: string[];
}

const GeneralSettingsForm = ({
  campaign,
  isEdit,
  submitting,
  toggleEdit,
  onSubmit,
  routingProfiles,
}: GeneralSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const routingProfileList = routingProfiles.map((item: RoutingProfile) => item.id);
  const defaultList = campaign.lists.find((list) => list.id === campaign.defaultListId);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      description: '',
      defaultListId: 0,
      routingProfiles: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isEdit) {
      setValue('name', campaign.name);
      setValue('description', campaign.description);
      setValue('defaultListId', campaign.defaultListId);
      setValue('routingProfiles', campaign.routingProfiles);
    }

    return () => {
      reset();
    };
  }, [isEdit]);

  const onSubmitFn = handleSubmit((data: Form) => {
    const submitData: UpdateCampaignGeneralSettings = {
      name: data.name,
      description: data.description || '',
      defaultListId: data.defaultListId,
      routingProfiles: data.routingProfiles,
    };

    onSubmit(submitData);
  });

  const routingProfileChips = campaign.routingProfiles.map((item: any) => {
    const index = findIndexByProperty(routingProfiles, 'id', item);
    const label = routingProfiles[index]?.name || 'Unknown';

    return (
      <Chip
        key={item}
        sx={{ minWidth: 100, maxWidth: 200, margin: '4px' }}
        title={item}
        variant='filled'
        color='primary'
        label={label}
      />
    );
  });

  // We do this so that if we have zero results we allow the DataItem to add '-' for an undefined or null value
  const routingProfileDisplay =
    routingProfileChips.length > 0 ? <Box sx={{ marginLeft: '-4px' }}>{routingProfileChips}</Box> : null;

  const leadListsDisplay = campaign.lists.map((item, index) => (
    <MenuItem key={index} value={item.id}>
      {item.name}
    </MenuItem>
  ));

  return (
    <SectionCard title='General' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem stacked={isExtraSmall} disableMargin title='Campaign Name' value={campaign.name} />
          <DataItem stacked={isExtraSmall} title='Dialler Type' value={typeIdsToDisplayText[campaign.diallerType]} />
          <DataItem stacked={isExtraSmall} title='Campaign Type' value={typeIdsToDisplayText[campaign.campaignType]} />
          <DataItem stacked={isExtraSmall} title='Description' value={campaign.description || '-'} />
          <DataItem stacked={isExtraSmall} title='Default Lead List' value={defaultList?.name || '-'} />
          <DataItem stacked={isExtraSmall} title='Routing Profiles' value={routingProfileDisplay} />
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmitFn} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name='name'
                control={control}
                rules={{
                  required: 'Name is required.',
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Name'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='description'
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant='outlined'
                    label='Description'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='defaultListId'
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    select
                    variant='outlined'
                    label='Default Lead List'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.defaultListId)}
                    helperText={errors.defaultListId?.message}
                    {...field}>
                    {leadListsDisplay}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='routingProfiles'
                control={control}
                rules={{
                  required: 'At least one routing profile must be selected.',
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    fullWidth
                    multiple
                    onChange={(e, data) => {
                      field.onChange(data);
                    }}
                    options={routingProfileList}
                    filterSelectedOptions
                    disabled={submitting}
                    getOptionLabel={(option) => routingProfiles.find((item) => item.id === option)?.name || ''}
                    renderInput={(params) => (
                      <TextField
                        label='Routing Profiles'
                        variant='outlined'
                        required={true}
                        error={Boolean(errors.routingProfiles)}
                        helperText={errors.routingProfiles?.message}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submitting}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default GeneralSettingsForm;
