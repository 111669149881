import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';

import OberonDialog from '~components/OberonDialog';
import { TabPanel } from '~components/TabPanel';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { ConnectVoiceContact, ContactStateType } from '~providers/ConnectProvider/domain';

import ConferenceTransfer from './ConferenceTransfer';
import ExternalTransfer from './ExternalTransfer';
import InternalTransfer from './InternalTransfer';
import PaymentGateway from './PaymentGateway';

enum TabType {
  External,
  Internal,
  PaymentGateway,
  Conference,
}

interface Feature {
  dialogTitle: string;
  tabName: string;
  tabValue: TabType;
  enabled: boolean;
}

interface TransfersProps {
  contact?: ConnectVoiceContact;
}

const TransferButton = styled(Button)({
  'display': 'block',
  'margin': '8px 0',
  'color': 'rgba(0, 0, 0, 0.87)',
  'backgroundColor': '#e0e0e0',
  ':last-of-type': {
    marginBottom: 0,
  },
  ':hover': {
    backgroundColor: '#d5d5d5',
  },
  ':disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
});

const MAX_MULTI_PARTY_CONNECTIONS = 6;
const MAX_NON_MULTI_PARTY_CONNECTIONS = 3;

const Transfers = ({ contact }: TransfersProps): JSX.Element | null => {
  const appConfig = useAppConfiguration();
  const features: Feature[] = [
    {
      dialogTitle: 'External Transfer',
      tabName: 'External',
      tabValue: TabType.External,
      enabled: Boolean(appConfig.extensions.externalTransfer),
    },
    {
      dialogTitle: 'Internal Transfer',
      tabName: 'Internal',
      tabValue: TabType.Internal,
      enabled: Boolean(appConfig.extensions.internalTransfer),
    },
    {
      dialogTitle: 'Payment Gateway',
      tabName: 'Payments',
      tabValue: TabType.PaymentGateway,
      enabled: Boolean(appConfig.extensions.paymentGateway),
    },
    {
      dialogTitle: 'Conference',
      tabName: 'Conference',
      tabValue: TabType.Conference,
      enabled: Boolean(appConfig.extensions.conferenceTransfer),
    },
  ];
  const enabledFeaturesList = features.filter((feature) => feature.enabled);
  // Do not render this component if no features are enabled
  if (enabledFeaturesList.length === 0) {
    return null;
  }

  const isConnected = contact !== undefined && contact.statusType === ContactStateType.Connected;
  // Depending on if multi party is enabled or not, we have different capacity limits of how many contacts can be on any
  // one call.
  // Note: We minus 1 from the count as the active agent connection does not live in the connections array
  const maxConnectionCapacityReached =
    contact !== undefined &&
    ((contact.isMultiPartyCallEnabled && contact.connections.length >= MAX_MULTI_PARTY_CONNECTIONS - 1) ||
      (!contact.isMultiPartyCallEnabled && contact.connections.length >= MAX_NON_MULTI_PARTY_CONNECTIONS - 1));
  const [transferModalOpen, setTransferModalOpen] = useState<boolean>(false);
  // We set this property to the first item in the list, at this point there should always be 1 or more
  // features enabled so index 0 should always exist
  const [transferTab, setTransferTab] = useState<number>(enabledFeaturesList[0].tabValue);

  // Closes transfer model if the initial connection drops
  // without the initial connection we cannot add more transfers etc
  const contactExists = contact !== undefined;
  useEffect(() => {
    if (!contactExists) {
      console.log('+ Initial Contact Dropped.');
      onCloseModal();
    }
  }, [contactExists]);

  const onOpenModal = (): void => {
    setTransferModalOpen(true);
  };

  const onCloseModal = (): void => {
    setTransferModalOpen(false);
  };

  let dialogTitle = 'Transfer';

  // Since we do a check earlier on to see if any features are enabled at all,
  // it is safe to assume here that we will have one OR more transfer types enabled.
  // If we have one we want the dialog title to be the name of the actual transfer type
  // that is enabled.
  if (enabledFeaturesList.length === 1) {
    dialogTitle = enabledFeaturesList[0].dialogTitle;
  }

  return (
    <>
      <TransferButton variant='contained' fullWidth disabled={!isConnected} disableElevation onClick={onOpenModal}>
        Transfers
      </TransferButton>

      <OberonDialog
        open={transferModalOpen}
        title={dialogTitle}
        onClose={onCloseModal}
        closeOnBackdropClick
        disableContentPadding
        content={
          <>
            {enabledFeaturesList.length >= 2 && (
              <>
                <Tabs
                  variant='scrollable'
                  indicatorColor='primary'
                  orientation='horizontal'
                  value={transferTab}
                  onChange={(_, value) => setTransferTab(value)}>
                  {enabledFeaturesList.map((feature, index) => (
                    <Tab key={index} label={feature.tabName} value={feature.tabValue} />
                  ))}
                </Tabs>
                <Divider variant='fullWidth' component='hr' />
              </>
            )}

            <TabPanel
              style={{ height: 500, overflowX: 'hidden' }}
              disablePadding
              value={transferTab}
              index={TabType.External}>
              <ExternalTransfer
                contact={contact}
                maxConnectionCapacityReached={maxConnectionCapacityReached}
                onCloseTransferModal={onCloseModal}
              />
            </TabPanel>

            <TabPanel
              style={{ height: 500, overflowX: 'hidden' }}
              disablePadding
              value={transferTab}
              index={TabType.Internal}>
              <InternalTransfer
                contact={contact}
                maxConnectionCapacityReached={maxConnectionCapacityReached}
                onCloseTransferModal={onCloseModal}
              />
            </TabPanel>

            <TabPanel
              style={{ height: 500, overflowX: 'hidden' }}
              disablePadding
              value={transferTab}
              index={TabType.Conference}>
              <ConferenceTransfer
                contact={contact}
                maxConnectionCapacityReached={maxConnectionCapacityReached}
                onCloseTransferModal={onCloseModal}
              />
            </TabPanel>

            <TabPanel style={{ height: 500 }} value={transferTab} index={TabType.PaymentGateway}>
              <PaymentGateway
                contact={contact}
                maxConnectionCapacityReached={maxConnectionCapacityReached}
                onCloseTransferModal={onCloseModal}
              />
            </TabPanel>
          </>
        }
        actionFooter={
          <Button variant='text' onClick={onCloseModal}>
            Close
          </Button>
        }
      />
    </>
  );
};

export default Transfers;
