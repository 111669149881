import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import { triggerDiallerGroupReload } from '~pages/CampaignManagement/api';
import useCampaignSearch from '~pages/CampaignManagement/CampaignList/useCampaignSearch';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { PolicyType, isAllowedRole, useAuth } from '~providers/AuthProvider';
import { useNotification } from '~providers/NotificationProvider';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import Routes from '~providers/RouteProvider/Routes';
import { useUserPreferences } from '~providers/UserPreferencesProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';
import { parseBoolean, pluraliseWord } from '~utils/Functions';

import { CampaignType, CreateCampaign } from '../domain';
import CampaignCard from './CampaignCard';
import CreateCampaignModal from './CreateCampaignModal';

const enum FilterType {
  None = '',
  Active = 'false',
  Archived = 'true',
}

interface Query {
  search: string;
  filter: FilterType;
}

const filterList = [
  {
    label: 'None',
    value: FilterType.None,
  },
  {
    label: 'Active',
    value: FilterType.Active,
  },
  {
    label: 'Archived',
    value: FilterType.Archived,
  },
];

const CampaignList = () => {
  const { policies } = useAuth();
  const { accessFilter } = useUserPreferences();
  const canCreateCampaign = isAllowedRole([PolicyType.DiallerAdmin], policies);
  const appConfig = useAppConfiguration();
  const { pushNotification } = useNotification();
  const setPageTitleProps = useSetPageTitleProps();
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [query, setQuery] = useState<Query>({ search: '', filter: FilterType.Active });
  const debouncedSearch = useDebounce(query.search, 500);
  const {
    loading,
    error,
    list,
    hasMore,
    create,
    intersectionObserverRef: lastDataElement,
  } = useCampaignSearch(debouncedSearch, {
    accessFilterId: accessFilter?.id,
    archived: query.filter === FilterType.None ? undefined : parseBoolean(query.filter),
  });

  const noSearchOrFilterSet = query.search === '' && query.filter === '';

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'Campaigns' });
  }, []);

  const toggleCreateCampaignModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const onQueryChange = async (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const addCampaign = async (data: CreateCampaign) => {
    setSubmittingData(true);

    try {
      await create(data);

      if (appConfig.extensions.predictive !== undefined && data.campaignType === CampaignType.Predictive) {
        // ask the predictive dialler to reload its copy of the campaigns after this was successfully created,
        // if the predictive extension is enabled.
        await triggerDiallerGroupReload(appConfig.extensions.predictive.diallerURL);
      }
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Created campaign ${data.name}`);
    setCreateModalOpen(false);
  };

  const displayList = useMemo(
    () =>
      list.map((item, index) => {
        const props = {
          ref: index === list.length - 1 ? lastDataElement : null,
          key: item.id,
          name: item.name,
          diallerType: item.diallerType,
          campaignType: item.campaignType,
          archived: Boolean(item.archived),
          to: generatePath(Routes.viewCampaign.path, { campaignId: item.id.toString() }),
          statisticItems: [
            { icon: ListAltIcon, text: `${item.numLists.toLocaleString()} ${pluraliseWord(item.numLists, 'list')}` },
          ],
        };

        return <CampaignCard {...props} />;
      }),

    [list, lastDataElement],
  );

  const filterListsDisplay = useMemo(
    () =>
      filterList.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      )),
    [],
  );

  return (
    <>
      <Grid sx={{ marginBottom: 1 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            defaultValue={query.search}
            onChange={onQueryChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            select
            variant='outlined'
            id='filter'
            name='filter'
            label='Filter'
            value={query.filter}
            onChange={onQueryChange}>
            {filterListsDisplay}
          </TextField>
        </Grid>

        <Hidden smDown>
          <Grid item md={3}></Grid>
        </Hidden>

        {canCreateCampaign && (
          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={toggleCreateCampaignModal}>
              Create Campaign
            </Button>
          </Grid>
        )}
      </Grid>

      <AsyncLoader isLoading={loading && list.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {list.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && list.length > 0 && <DotLoader align='center' />}

                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}

                {error && list.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load campaigns
                  </Typography>
                )}
              </>
            )}

            {list.length === 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text='No campaigns found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}

            {list.length === 0 && noSearchOrFilterSet && !accessFilter && (
              <EmptyState type='no-items-3' text='No campaigns currently exist' />
            )}

            {list.length === 0 && noSearchOrFilterSet && accessFilter && (
              <EmptyState
                type='no-records-found'
                text={`${accessFilter.name} access filter does not have any campaigns assigned to it`}
              />
            )}
          </Grid>
        </Grid>

        <CreateCampaignModal
          open={createModalOpen}
          submitting={submittingData}
          onClose={toggleCreateCampaignModal}
          onAccept={addCampaign}
        />
      </AsyncLoader>
    </>
  );
};

export default CampaignList;
