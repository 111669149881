import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import PhoneIcon from '@mui/icons-material/Phone';
import Chip from '@mui/material/Chip';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';

import OberonCard from '~components/OberonCard';
import OberonSwitch from '~components/OberonSwitch';
import { PolicyType, isAllowedRole, useAuth } from '~providers/AuthProvider';
import Routes from '~providers/RouteProvider/Routes';

import { LeadListItem } from '../../../domain';

enum ColorType {
  Active = 'active',
  Inactive = 'inactive',
  InactiveWarning = 'inactive-warning',
}

interface Status {
  borderColor: string;
  statusColor: string;
  defaultListColor: string;
}

interface LeadListCardProps {
  leadsList: LeadListItem;
  onToggleList: (checked: boolean) => void;
}

const colorData: { [key in ColorType]: Status } = {
  [ColorType.Active]: {
    borderColor: green[600],
    statusColor: green[600],
    defaultListColor: grey[600],
  },
  [ColorType.Inactive]: {
    borderColor: grey[600],
    statusColor: grey[600],
    defaultListColor: grey[600],
  },
  [ColorType.InactiveWarning]: {
    borderColor: grey[600],
    statusColor: grey[600],
    defaultListColor: red[600],
  },
};

const LeadListCard = ({ leadsList, onToggleList }: LeadListCardProps) => {
  const { policies } = useAuth();
  const canUpdateLeadList = isAllowedRole([PolicyType.DiallerAdmin], policies);
  const { campaignId } = useParams() as { campaignId: string };
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const viewLink = generatePath(Routes.viewCampaignLeadList.path, {
    campaignId: campaignId,
    listId: leadsList.id.toString(),
  });
  const statusName = leadsList.isActive ? 'Active' : 'Inactive';
  const isDefaultListAndDisabled = leadsList.isDefaultList === true && leadsList.isActive === false;
  let colors;

  if (isDefaultListAndDisabled) {
    colors = colorData[ColorType.InactiveWarning];
  } else if (leadsList.isActive === true) {
    colors = colorData[ColorType.Active];
  } else {
    colors = colorData[ColorType.Inactive];
  }

  // Prevent Link navigation on selecting the switch toggle
  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const handleToggleChange = async (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsUpdating(true);
    await onToggleList(checked);
    setIsUpdating(false);
  };

  return (
    <OberonCard
      to={viewLink}
      titleFontWeight={400}
      title={leadsList.name}
      subHeader={
        <>
          <Chip
            sx={{
              marginTop: 2,
              textTransform: 'uppercase',
              fontSize: 10,
              borderRadius: 1,
              height: 20,
              lineHeight: '21px',
              color: '#ffffff',
              fontWeight: 700,
              backgroundColor: colors.statusColor,
            }}
            label={statusName}
          />

          {leadsList.isDefaultList && (
            <Chip
              sx={{
                marginTop: 2,
                marginLeft: 1,
                textTransform: 'uppercase',
                fontSize: 10,
                borderRadius: 1,
                height: 20,
                lineHeight: '21px',
                color: '#ffffff',
                fontWeight: 700,
                backgroundColor: colors.defaultListColor,
              }}
              label='Default List'
            />
          )}

          {isDefaultListAndDisabled && (
            <Typography sx={{ marginTop: 1 }} display='block' variant='caption' color='textSecondary'>
              * This list should be enabled as it is the default list for the campaign.
            </Typography>
          )}
        </>
      }
      action={
        canUpdateLeadList && (
          <OberonSwitch
            checked={leadsList.isActive}
            disabled={isUpdating}
            onClick={stopPropagation}
            onChange={handleToggleChange}
            name={leadsList.name}
          />
        )
      }
      footerBorderColor={colors.borderColor}
      footer={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            marginRight={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            variant='caption'
            color='textSecondary'>
            <DonutLargeIcon sx={{ marginRight: 0.5 }} fontSize='small' />
            {`${leadsList.progress.toFixed(2)}%`}
          </Typography>

          <Typography
            marginLeft={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            variant='caption'
            color='textSecondary'>
            <PhoneIcon sx={{ marginRight: 0.5 }} fontSize='small' />
            {`${leadsList.numCompleted.toLocaleString()} / ${leadsList.totalLeads.toLocaleString()}`}
          </Typography>
        </Box>
      }
    />
  );
};

export default LeadListCard;
