import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Chip from '@mui/material/Chip';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { ForwardedRef, KeyboardEvent, MouseEvent, forwardRef, useCallback, useMemo, useState } from 'react';

import OberonCard from '~components/OberonCard';

import { CampaignType, DiallerType, typeIdsToDisplayText } from '../../domain';

interface Props {
  name: string;
  diallerType?: DiallerType;
  campaignType?: CampaignType;
  isDefaultCampaign?: boolean;
  archived: boolean;
  to?: string;
  statisticItems?: { icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>; text: string }[];
  menuItems?: { action: () => void; name: string }[];
}

const CampaignCard = forwardRef(
  (
    { name, diallerType, campaignType, isDefaultCampaign, archived, to, statisticItems, menuItems }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const borderColor = archived ? grey[600] : green[600];
    const statusBackgroundColor = archived ? grey[600] : green[600];
    const statusName = archived ? 'Archived' : 'Active';

    const handleMenuOpen = useCallback((e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setAnchorEl(e.currentTarget);
    }, []);

    const handleMenuClose = useCallback((e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setAnchorEl(null);
    }, []);

    const handleActionClick = useCallback((e: MouseEvent<HTMLLIElement>, callback: () => void) => {
      e.stopPropagation();
      setAnchorEl(null);
      callback();
    }, []);

    const menuList = useMemo(() => {
      if (!menuItems) {
        return [];
      }

      return menuItems.map((item, index) => (
        <MenuItem key={index} onClick={(e) => handleActionClick(e, item.action)}>
          {item.name}
        </MenuItem>
      ));
    }, [menuItems]);

    const statisticList = useMemo(() => {
      if (!statisticItems) {
        return [];
      }

      return statisticItems.map((item, index) => (
        <Typography
          key={index}
          marginLeft={index === 0 ? 0 : 1}
          marginRight={index === statisticItems.length - 1 ? 0 : 1}
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          variant='caption'
          color='textSecondary'>
          {item.icon && <item.icon sx={{ marginRight: 0.5 }} fontSize='small' />}
          {item.text}
        </Typography>
      ));
    }, [statisticItems]);

    return (
      <OberonCard
        ref={ref}
        to={to}
        titleFontWeight={400}
        title={name}
        subHeader={
          <>
            <Chip
              sx={{
                marginTop: 2,
                textTransform: 'uppercase',
                fontSize: 10,
                borderRadius: 1,
                height: 20,
                lineHeight: '21px',
                color: '#ffffff',
                fontWeight: 700,
                backgroundColor: statusBackgroundColor,
              }}
              label={statusName}
            />

            {isDefaultCampaign && (
              <Chip
                sx={{
                  marginTop: 2,
                  marginLeft: 1,
                  textTransform: 'uppercase',
                  fontSize: 10,
                  borderRadius: 1,
                  height: 20,
                  lineHeight: '21px',
                  color: '#ffffff',
                  fontWeight: 700,
                  backgroundColor: grey[600],
                }}
                label='Default Campaign'
              />
            )}
          </>
        }
        footerBorderColor={borderColor}
        action={
          menuItems && (
            <>
              <IconButton
                aria-controls='agent-menu'
                aria-haspopup='true'
                onKeyPress={handleMenuOpen}
                onClick={handleMenuOpen}>
                <MoreHorizIcon />
              </IconButton>
              <Menu id='agent-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {menuList}
              </Menu>
            </>
          )
        }
        footer={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>{statisticList}</Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {(diallerType || campaignType) && (
                <Typography
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  variant='caption'
                  color='textSecondary'>
                  {diallerType && typeIdsToDisplayText[diallerType]}
                  {diallerType && campaignType && ' | '}
                  {campaignType && typeIdsToDisplayText[campaignType]}
                </Typography>
              )}
            </Box>
          </Box>
        }
      />
    );
  },
);

export default CampaignCard;
