import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FocusEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DotLoader } from '~components/DotLoader';
import OberonDialog from '~components/OberonDialog';
import useAgent from '~hooks/useAgent';
import useDebounce from '~hooks/useDebounce';
import useDiallerGroupRoutingProfiles, {
  isDiallerGroupRoutingProfileList,
} from '~hooks/useDiallerGroupRoutingProfiles';
import { AsyncQueue } from '~pages/AsyncManagement/domain';
import useAsyncQueueSearch from '~pages/AsyncManagement/useAsyncQueueSearch';
import { RoutingProfile } from '~pages/CampaignManagement/domain';
import useAccessFilterSearch from '~pages/SystemManagement/AccessFilterList/useAccessFilterSearch';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useNotification } from '~providers/NotificationProvider';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

import { getSkillTypes } from '../../api';
import { AgentUpdateRequest, SkillType } from '../../domain';

interface CreateEditAgentModalProps {
  open: boolean;
  submitting: boolean;
  // Dictates if this is an editable view or not
  agentUsername?: string;
  onAccept: (data: AgentUpdateRequest) => void;
  onClose: () => void;
}

interface ListItem {
  id: number;
  name: string;
}

interface AgentForm {
  username: string;
  firstName: string;
  lastName: string;
  diallerGroupId: number | '';
  routingProfileId: string;
  asyncQueues: AsyncQueue[];
  accessFilters: ListItem[] | undefined;
}

interface SkillForm {
  skillType: string;
  skillValue: string;
}

interface SearchQueries {
  asyncQueues: string;
  accessFilters: string;
}

const regex = {
  email:
    /^[a-zA-Z0-9.!#$%&''*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
};

const getNoOptionsText = (fetching: boolean, fetchError: boolean, fetchErrorText: string) => {
  if (fetching) {
    return <DotLoader align='center' />;
  }

  if (fetchError) {
    return (
      <Typography variant='body2' align='center' color='textSecondary'>
        {fetchErrorText}
      </Typography>
    );
  }

  return undefined;
};

function filterListByKey<T>(list: T[], watch: T[] | undefined, key: keyof T) {
  if (!watch) {
    return list;
  }

  return list.filter((listItem) => {
    return !watch.find((watchItem) => listItem[key] === watchItem[key]);
  });
}

const CreateEditAgentModal = ({ open, submitting, agentUsername, onAccept, onClose }: CreateEditAgentModalProps) => {
  const isEdit = Boolean(agentUsername);
  const appConfig = useAppConfiguration();
  const { accessFilter } = useUserPreferences();
  const { pushNotification } = useNotification();
  const [skillTypes, setSkillTypes] = useState<SkillType[]>([]);
  const [agentSkills, setAgentSkills] = useState<string[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [searchQueries, setSearchQueries] = useState<SearchQueries>({
    asyncQueues: '',
    accessFilters: '',
  });
  const debouncedSearchQueries = useDebounce(searchQueries, 500);
  const { loading: groupRoutingProfileFetching, data: groupRoutingProfileData } = useDiallerGroupRoutingProfiles({
    accessFilterId: accessFilter?.id,
    shouldFetch: open,
  });
  const {
    loading: asyncQueueFetching,
    error: asyncQueueFetchError,
    list: asyncQueues,
    intersectionObserverRef: lastAsyncQueueDataElement,
  } = useAsyncQueueSearch(debouncedSearchQueries.asyncQueues, { accessFilterId: accessFilter?.id, shouldFetch: open });
  const {
    loading: accessFilterFetching,
    error: accessFilterFetchError,
    list: accessFilters,
    intersectionObserverRef: lastAccessFilterDataElement,
  } = useAccessFilterSearch(debouncedSearchQueries.accessFilters, { archived: false, shouldFetch: open && !isEdit });
  const { loading: fetchingAgent, agent } = useAgent(agentUsername || '', open);
  const groupRoutingProfileList =
    groupRoutingProfileData && isDiallerGroupRoutingProfileList(groupRoutingProfileData) ? groupRoutingProfileData : [];
  const isLoading = submitting || dataLoading || groupRoutingProfileFetching || fetchingAgent || accessFilterFetching;
  const agentForm = useForm<AgentForm>({
    defaultValues: {
      username: '',
      firstName: '',
      lastName: '',
      diallerGroupId: '',
      routingProfileId: '',
      asyncQueues: [],
      accessFilters: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const asyncQueuesWatch = agentForm.watch('asyncQueues');
  const accessFiltersWatch = agentForm.watch('accessFilters');
  const watchDiallerGroupId = agentForm.watch('diallerGroupId');

  const agentSkillForm = useForm<SkillForm>({
    defaultValues: {
      skillType: '',
      skillValue: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const getRoutingProfilesForDiallerGroupId = (diallerGroupId: number | null): RoutingProfile[] => {
    if (!diallerGroupId) {
      return [];
    }

    if (!groupRoutingProfileList) {
      return [];
    }

    if (isDiallerGroupRoutingProfileList(groupRoutingProfileList)) {
      for (const group of groupRoutingProfileList) {
        if (group.id === diallerGroupId && groupRoutingProfileList.length > 0) {
          return group.routingProfiles;
        }
      }
      return [];
    }

    return [];
  };

  // Manages agent edit based data
  // Note: Event though we already pass in the agent object, we do a fetch for an individual agent
  // so that we can get routing profile information. The reason for this is that the list view is incapable
  // of querying connect for each agent's routing profile due to rate limiting mechanisms.
  useEffect(() => {
    if (agent !== undefined && dataLoading === false) {
      agentForm.setValue('username', agent.username);
      agentForm.setValue('firstName', agent.firstName);
      agentForm.setValue('lastName', agent.lastName);
      agentForm.setValue('diallerGroupId', agent.diallerGroupId || '');

      // Manage the case were a routing profile might have been removed from a campaign, or that someone was messing with
      // agent routing profiles in connect and have the agent assigned to mismatched campaign/ routing profiles
      const currentGroupRoutingProfiles = getRoutingProfilesForDiallerGroupId(agent.diallerGroupId);
      const currentRoutingProfile = currentGroupRoutingProfiles.find((item) => agent.routingProfileId === item.id);

      agentForm.setValue('routingProfileId', currentRoutingProfile === undefined ? '' : currentRoutingProfile.id);

      let editValue: AsyncQueue[] = [];
      for (let q of agent.asyncQueues) {
        const queue = asyncQueues.find((item) => item.queue === q);
        if (queue !== undefined) {
          editValue = [...editValue, queue];
        }
      }

      agentForm.setValue('asyncQueues', editValue);

      // Assign agent skills to a local state variable for manipulation
      setAgentSkills(agent.skills);
      return;
    } else if (agent === undefined && dataLoading === false) {
      if (accessFilter) {
        agentForm.setValue('accessFilters', [{ id: accessFilter.id, name: accessFilter.name }]);
      }
    }
  }, [agent, dataLoading]);

  // Manages routing profile selection defaults
  useEffect(() => {
    if (agent !== undefined && dataLoading === false) {
      const currentCampaignRoutingProfiles = getRoutingProfilesForDiallerGroupId(watchDiallerGroupId || null);

      if (agent.diallerGroupId === watchDiallerGroupId) {
        const currentRoutingProfile = currentCampaignRoutingProfiles.find((item) => agent.routingProfileId === item.id);

        agentForm.setValue('routingProfileId', currentRoutingProfile === undefined ? '' : currentRoutingProfile.id);
      } else {
        agentForm.setValue(
          'routingProfileId',
          currentCampaignRoutingProfiles.length > 0 ? currentCampaignRoutingProfiles[0].id : '',
        );
      }
    }
  }, [agent, dataLoading, watchDiallerGroupId]);

  // Manages form based data
  useEffect(() => {
    const createViewData = async () => {
      setDataLoading(true);
      let skills;

      try {
        skills = await getSkillTypes();
      } catch (e) {
        pushNotification('error', 'unable to load skill types');
        setDataLoading(false);
        return;
      }

      setSkillTypes(skills);
      setDataLoading(false);
    };

    const editViewData = async () => {
      setDataLoading(true);
      let skills;

      try {
        skills = await getSkillTypes();
      } catch (e) {
        pushNotification('error', 'unable to load skill types');
        setDataLoading(false);
        return;
      }

      setSkillTypes(skills);
      setDataLoading(false);
    };

    if (open) {
      if (agentUsername === undefined) {
        createViewData();
      } else {
        editViewData();
      }
    }

    // Reset form on close
    return function cleanupCreateEditAgentModal() {
      agentForm.reset();
      agentSkillForm.reset();
      setAgentSkills([]);
      setSkillTypes([]);
    };
  }, [open, agentUsername]);

  const onSkillDelete = (index: number) => () => {
    setAgentSkills((prev) => {
      const newArray = [...prev];

      newArray.splice(index, 1);
      return newArray;
    });
  };

  const onAgentSubmit = agentForm.handleSubmit(async (data: AgentForm) => {
    let submitData: AgentUpdateRequest = {
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
      diallerGroupId: data.diallerGroupId || null,
      routingProfileId: data.routingProfileId,
      skills: agentSkills,
      asyncQueues: data.asyncQueues.map((item) => item.queue),
      accessFilterIds: undefined,
    };

    if (data.accessFilters) {
      submitData = {
        ...submitData,
        accessFilterIds: data.accessFilters.length > 0 ? data.accessFilters.map((item) => item.id) : undefined,
      };
    }

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    agentForm.reset();
  });

  const onAgentSkillSubmit = agentSkillForm.handleSubmit(async (data: SkillForm) => {
    const skill = `${data.skillType}:${data.skillValue}`;
    setAgentSkills((prev) => [...prev, skill]);

    agentSkillForm.reset();
  });

  const usernamePattern = appConfig.validations.username
    ? new RegExp(appConfig.validations.username.pattern)
    : regex.email;
  const usernamePatternErrorMessage = appConfig.validations.username
    ? appConfig.validations.username.error
    : 'Username must be a valid email address';
  const usernameHelperText = agentForm.formState.errors.username
    ? agentForm.formState.errors.username.message
    : `Must match user's username from IdP`;

  const agentSkillsDisplay = agentSkills.map((item: any, index: number) => (
    <Chip key={item} sx={{ minWidth: 100, maxWidth: 200, margin: 0.5 }} label={item} onDelete={onSkillDelete(index)} />
  ));

  const skillTypeListDisplay = skillTypes.map((item, index) => (
    <MenuItem key={index} value={item.skillType}>
      {item.skillType}
    </MenuItem>
  ));

  let diallerGroupListDisplay =
    groupRoutingProfileList && isDiallerGroupRoutingProfileList(groupRoutingProfileList)
      ? groupRoutingProfileList.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            {item.name}
          </MenuItem>
        ))
      : [];

  if (diallerGroupListDisplay.length > 0) {
    // Add None selection as agents can exist without having an assigned campaign
    diallerGroupListDisplay = [
      <MenuItem key='none' value=''>
        None
      </MenuItem>,
      ...diallerGroupListDisplay,
    ];
  }

  const routingProfileDisplay = getRoutingProfilesForDiallerGroupId(watchDiallerGroupId || null).map((item, index) => (
    <MenuItem key={index} value={item.id}>
      {item.name}
    </MenuItem>
  ));

  const onSearchChange = (key: keyof SearchQueries) => (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQueries((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const onSearchBlur = (key: keyof SearchQueries) => (e: FocusEvent<HTMLInputElement>) => {
    setSearchQueries((prev) => ({ ...prev, [key]: '' }));
  };

  const asyncQueuesNoOptionsText = useMemo(
    () => getNoOptionsText(asyncQueueFetching, asyncQueueFetchError, 'Failed to load queues'),
    [asyncQueueFetching, asyncQueueFetchError],
  );

  const asyncQueuesFilteredList = useMemo(
    () => filterListByKey(asyncQueues, asyncQueuesWatch, 'queue'),
    [asyncQueues, asyncQueuesWatch],
  );

  const accessFiltersNoOptionsText = useMemo(
    () => getNoOptionsText(accessFilterFetching, accessFilterFetchError, 'Failed to load access filters'),
    [accessFilterFetching, accessFilterFetchError],
  );

  const accessFiltersFilteredList: ListItem[] = useMemo(() => {
    let list: ListItem[] = accessFilters.map((item) => ({ id: item.id, name: item.name }));
    if (accessFilter) {
      list = [...list, { id: accessFilter.id, name: accessFilter.name }];
    }
    if (!accessFiltersWatch) {
      return list;
    }

    return list.filter((listItem) => {
      return !accessFiltersWatch.find((watchItem) => listItem.id === watchItem.id);
    });
  }, [accessFilters, accessFiltersWatch]);

  return (
    <OberonDialog
      open={open}
      onSubmit={onAgentSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Agent`}
      content={
        <Grid container spacing={2}>
          {!isEdit && (
            <Grid item xs={12}>
              <Controller
                name='username'
                control={agentForm.control}
                rules={{
                  required: 'Agent username is required.',
                  pattern: {
                    value: usernamePattern,
                    message: usernamePatternErrorMessage,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Username'
                    disabled={isLoading}
                    required={true}
                    error={Boolean(agentForm.formState.errors.username)}
                    helperText={usernameHelperText}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}

          {!appConfig.aws.externallyManagedConnectInstance && (
            <>
              <Grid item xs={12}>
                <Controller
                  name='firstName'
                  control={agentForm.control}
                  rules={{
                    required: 'Agent first name is required.',
                  }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='First Name'
                      disabled={isLoading}
                      required={true}
                      error={Boolean(agentForm.formState.errors.firstName)}
                      helperText={agentForm.formState.errors.firstName?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='lastName'
                  control={agentForm.control}
                  rules={{
                    required: 'Agent last name is required.',
                  }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='Last Name'
                      disabled={isLoading}
                      required={true}
                      error={Boolean(agentForm.formState.errors.lastName)}
                      helperText={agentForm.formState.errors.lastName?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </>
          )}

          {!isEdit && (
            <>
              <Grid item xs={12}>
                <Divider style={{ marginBottom: 8 }} variant='fullWidth' component='hr' />

                <Typography variant='h6' component='h3'>
                  Assign Access Filters
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='accessFilters'
                  control={agentForm.control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      multiple
                      onChange={(e, data) => {
                        field.onChange(data);
                      }}
                      options={accessFiltersFilteredList}
                      noOptionsText={accessFiltersNoOptionsText}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disabled={isLoading}
                      getOptionLabel={(option) => option.name || ''}
                      renderOption={(props, option) => (
                        <li {...props} ref={lastAccessFilterDataElement} key={option.id}>
                          <Typography variant='body1' color='textPrimary' component='p'>
                            {option.name}
                          </Typography>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Access Filters'
                          variant='outlined'
                          onBlur={onSearchBlur('accessFilters')}
                          onChange={onSearchChange('accessFilters')}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            {!appConfig.aws.externallyManagedConnectInstance && (
              <Divider style={{ marginBottom: 8 }} variant='fullWidth' component='hr' />
            )}

            <Typography variant='h6' component='h3'>
              Assign Group
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='diallerGroupId'
              control={agentForm.control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  select
                  variant='outlined'
                  label='Dialler Group'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(agentForm.formState.errors.diallerGroupId)}
                  helperText={agentForm.formState.errors.diallerGroupId?.message}
                  {...field}>
                  {diallerGroupListDisplay}
                </TextField>
              )}
            />
          </Grid>

          {!appConfig.aws.externallyManagedRoutingProfile && (
            <Grid item xs={12}>
              <Controller
                name='routingProfileId'
                control={agentForm.control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    select
                    variant='outlined'
                    label='Routing Profile'
                    disabled={isLoading || routingProfileDisplay.length === 0}
                    required={true}
                    error={Boolean(agentForm.formState.errors.routingProfileId)}
                    helperText={agentForm.formState.errors.routingProfileId?.message}
                    {...field}>
                    {routingProfileDisplay}
                  </TextField>
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider style={{ marginBottom: 8 }} variant='fullWidth' component='hr' />

            <Typography variant='h6' component='h3'>
              Assign Messaging Queues
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='asyncQueues'
              control={agentForm.control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  multiple
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  options={asyncQueuesFilteredList}
                  noOptionsText={asyncQueuesNoOptionsText}
                  isOptionEqualToValue={(option, value) => option.queue === value.queue}
                  disabled={isLoading}
                  getOptionLabel={(option) => option.title || ''}
                  renderOption={(props, option) => (
                    <li {...props} ref={lastAsyncQueueDataElement} key={option.queue}>
                      <Box>
                        <Typography variant='body1' color='textPrimary' component='p'>
                          {option.title}
                        </Typography>
                        <Chip
                          sx={{
                            textTransform: 'uppercase',
                            fontSize: 10,
                            borderRadius: 1,
                            height: 'auto',
                            lineHeight: '21px',
                            color: '#ffffff',
                            fontWeight: 700,
                          }}
                          color='primary'
                          label={option.channelType}
                        />
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Async Queues'
                      variant='outlined'
                      onBlur={onSearchBlur('asyncQueues')}
                      onChange={onSearchChange('asyncQueues')}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider style={{ marginBottom: 8 }} variant='fullWidth' component='hr' />

            <Typography variant='h6' component='h3'>
              Assign Skills
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='skillType'
              rules={{ required: 'Skill type is required.' }}
              control={agentSkillForm.control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  select
                  variant='outlined'
                  label='Skill Type'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(agentSkillForm.formState.errors.skillType)}
                  helperText={agentSkillForm.formState.errors.skillType?.message}
                  {...field}>
                  {skillTypeListDisplay}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='skillValue'
              control={agentSkillForm.control}
              rules={{ required: 'Skill value is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Skill Value'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(agentSkillForm.formState.errors.skillValue)}
                  helperText={agentSkillForm.formState.errors.skillValue?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant='contained'
              disableElevation
              color='primary'
              onClick={onAgentSkillSubmit}
              disabled={isLoading}>
              Add Skill
            </Button>
          </Grid>

          <Grid item xs={12}>
            {agentSkillsDisplay.length > 0 && <>{agentSkillsDisplay}</>}

            {agentSkillsDisplay.length === 0 && (
              <Typography variant='body1' component='p'>
                No Skills currently assigned to an agent.
              </Typography>
            )}
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateEditAgentModal;
