import React, { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useAssignedDiallerGroup } from '~providers/AssignedDiallerGroupProvider';
import { useAsync } from '~providers/AsyncProvider';
import { useConnect } from '~providers/ConnectProvider';
import { AgentStateType, ContactStateType } from '~providers/ConnectProvider/domain';
import { AgentStatesManager } from '~services/AgentStatesManager';
import {
  AgentState,
  AgentStateTypeWS,
  AgentStateUpdate,
  AmazonConnectMetrics,
  ContactStateTypeWS,
} from '~services/AgentStatesManager/domain';
import { exponentialDelay } from '~utils/Functions';

interface AgentStatesProviderProps {
  children: ReactNode;
}

interface AgentStatesContext {
  agentStatesManager: AgentStatesManager;
  agentList: AgentState[];
  agentVoiceList: AgentState[];
  agentAsyncList: AgentState[];
  connectMetrics: AmazonConnectMetrics | undefined;
  connected: boolean;
}

const AgentStatesContext = createContext<AgentStatesContext | undefined>(undefined);

export const useAgentStates = (): AgentStatesContext => {
  return useContext(AgentStatesContext) as AgentStatesContext;
};

const AgentStatesProvider = ({ children }: AgentStatesProviderProps) => {
  const { group } = useAssignedDiallerGroup();
  const { agent: connectAgent, currentVoiceContact } = useConnect();
  const { customers } = useAsync();
  const [connected, setConnected] = useState<boolean>(true);
  const retryTimeoutRef = useRef<number | undefined>(undefined);
  const retryCount = useRef<number>(0);
  const [agentList, setAgentList] = useState<AgentState[]>([]);
  const [connectMetrics, setConnectMetrics] = useState<AmazonConnectMetrics | undefined>(undefined);
  const agentStatesManager = useMemo(() => {
    const url = new URL(window.document.location.href);
    const proto = url.protocol === 'https:' ? 'wss' : 'ws';
    const socketUrl = `${proto}://${url.host}/api/agent-state/`;

    const manager = new AgentStatesManager(socketUrl, {
      onConnected: () => {
        clearTimeout(retryTimeoutRef.current);
        retryCount.current = 0;
        setConnected(true);
      },
      onMessage: (socketMessage) => {
        if (socketMessage.agents) {
          setAgentList(socketMessage.agents || []);
        }

        if (socketMessage.agentUpdate) {
          const { agentUpdate } = socketMessage;
          setAgentList((prev) => {
            const exists = prev.find((agent) => agent.username === agentUpdate.username);

            if (exists !== undefined) {
              return prev.map((agent) => {
                if (agent.username === agentUpdate.username) {
                  return { ...agent, ...agentUpdate };
                }

                return agent;
              });
            } else {
              return [...prev, agentUpdate];
            }
          });
        }

        if (socketMessage.agentRemoved) {
          setAgentList((prev) => prev.filter((agent) => agent.username !== socketMessage.agentRemoved));
        }

        if (socketMessage.metrics) {
          setConnectMetrics(socketMessage.metrics);
        }
      },
      onDisconnected: (closeCode) => {
        clearTimeout(retryTimeoutRef.current);
        setConnected(false);
        setAgentList([]);
        setConnectMetrics(undefined);

        // Attempt to reconnect the socket at an exponential delay
        if (closeCode > 1000) {
          retryTimeoutRef.current = exponentialDelay(retryCount.current, () => {
            retryCount.current += 1;
            manager.connect();
          });
        }
      },
    });

    return manager;
  }, []);
  const agentVoiceList = useMemo(() => agentList.filter((item) => item.hasVoice), [agentList]);
  const agentAsyncList = useMemo(() => agentList.filter((item) => item.hasAsync), [agentList]);

  // Handle the connection and disconnection of the socket
  useEffect(() => {
    agentStatesManager.connect();

    return () => {
      clearTimeout(retryTimeoutRef.current);
      agentStatesManager.disconnect();
    };
  }, []);

  const messagingConcurrency = Object.keys(customers).length;
  useEffect(() => {
    const stateType =
      connectAgent.status.type === AgentStateType.System || connectAgent.status.type === AgentStateType.Error
        ? AgentStateTypeWS.Routable
        : (connectAgent.status.type as unknown as AgentStateTypeWS);

    const newState: AgentStateUpdate = {
      state: connectAgent.status.name as unknown as string,
      stateType: stateType,
      contactStateType: undefined,
      routingProfile: connectAgent.routingProfile,
      // TODO (christian): update this to be configurable when we support async with no dialling campaigns
      hasVoice: true,
      hasAsync: group.hasAsyncQueues,
      inVoiceCall: Boolean(currentVoiceContact),
      messagingConcurrency: messagingConcurrency,
    };

    if (currentVoiceContact !== undefined) {
      // Massage into WS contact types
      if (
        currentVoiceContact.statusType === ContactStateType.Init ||
        currentVoiceContact.statusType === ContactStateType.Incoming ||
        currentVoiceContact.statusType === ContactStateType.Pending
      ) {
        newState.contactStateType = ContactStateTypeWS.Connecting;
      } else {
        newState.contactStateType = currentVoiceContact.statusType as unknown as ContactStateTypeWS;
      }
    }

    agentStatesManager.sendAgentState(newState);
  }, [connectAgent.status.type, connectAgent.status, group.hasAsyncQueues, messagingConcurrency, currentVoiceContact]);

  // Logic to attempt a resend of any potential messages not send due to a network error
  useEffect(() => {
    const ref = setInterval(() => {
      agentStatesManager.sendQueuedMessages();
    }, 1_000);

    return () => {
      clearInterval(ref);
    };
  }, []);

  const context: AgentStatesContext = {
    agentStatesManager,
    agentList,
    agentVoiceList,
    agentAsyncList,
    connectMetrics,
    connected,
  };

  return <AgentStatesContext.Provider value={context}>{children}</AgentStatesContext.Provider>;
};

export default AgentStatesProvider;
