import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import AsyncLoader from '~components/AsyncLoader';
import { BasicLineChart } from '~components/BasicLineChart';
import { BasicTable, ColumnData } from '~components/BasicTable';
import ContentSpacer from '~components/ContentSpacer';
import { DataItem } from '~components/DataItem';
import EmptyState from '~components/EmptyState';
import OberonSwitch from '~components/OberonSwitch';
import SectionCard from '~components/SectionCard';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { AsteriskStatsManager } from '~services/AsteriskStatsManager';
import { AsteriskStats } from '~services/AsteriskStatsManager/domain';
import { assertUnreachable, exponentialDelay, sortObject } from '~utils/Functions';

import { DiallerGroup } from '../../domain';
import AgentStatusCard from './AgentStatusCard';
import CallStatusCard from './CallStatusCard';
import StatusTotalCard from './StatusTotalCard';

enum ViewType {
  Grid,
  List,
}

enum ToggleType {
  Agents,
  Calls,
}

interface DiallerGroupLiveDashProps {
  diallerGroup: DiallerGroup;
}

interface ListItem {
  label: string;
  value: string;
}

interface TableFilter {
  statuses?: ListItem[];
  callStatuses?: ListItem[];
  sortBy: string;
  sortOrder: 'asc' | 'desc';
}

const getChartDataWithTimeBasedAxis = (data: { [key: string]: number }): [string[], { x: string; y: number }[]] => {
  const labels = Object.keys(data).sort((a, b) => {
    return parseInt(a.replace(':', '')) - parseInt(b.replace(':', ''));
  });

  const items: { x: string; y: number }[] = [];
  for (const label of labels) {
    items.push({
      x: label,
      y: data[label],
    });
  }

  return [labels, items];
};

const callStatusList = [
  {
    label: 'Fetching Lead',
    value: 'Fetching Lead',
  },
  {
    label: 'Initialised',
    value: 'Initialised',
  },
  {
    label: 'Inbound IVR',
    value: 'Inbound IVR',
  },
  {
    label: 'Leaving Message',
    value: 'Leaving Message',
  },
  {
    label: 'Pre Dial',
    value: 'Pre Dial',
  },
  {
    label: 'Start AMD',
    value: 'Start AMD',
  },
  {
    label: 'Early Media',
    value: 'Early Media',
  },
  {
    label: 'Ringing',
    value: 'Ringing',
  },
  {
    label: 'In AMD',
    value: 'In AMD',
  },
  {
    label: 'Inbound Pending',
    value: 'Inbound Pending',
  },
  {
    label: 'In Queue',
    value: 'In Queue',
  },
  {
    label: 'Connected',
    value: 'Connected',
  },
  {
    label: 'On Hold',
    value: 'On Hold',
  },
  {
    label: 'Abandoned',
    value: 'Abandoned',
  },
  {
    label: 'Transferred',
    value: 'Transferred',
  },
  {
    label: 'Disconnected',
    value: 'Disconnected',
  },
];

const DiallerGroupLiveDash = ({ diallerGroup }: DiallerGroupLiveDashProps) => {
  const appConfig = useAppConfiguration();
  const [state, setState] = useState<AsteriskStats | undefined>(undefined);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [socketConnected, setSocketConnected] = useState<boolean>(false);
  const graphBucketLocalRef = useRef<HTMLInputElement | undefined>(undefined);
  const [activeCallViewType, setActiveCallViewType] = useState<ViewType>(ViewType.Grid);
  const [agentsViewType, setAgentsViewType] = useState<ViewType>(ViewType.Grid);
  const retryTimeoutRef = useRef<number | undefined>(undefined);
  const retryCount = useRef<number>(0);
  const predictiveConfig = appConfig.extensions.predictive ?? {
    diallerURL: '',
    sipWebsocket: '',
    sipHost: '',
  };
  const [agentsFilter, setAgentsFilter] = useState<TableFilter>({
    statuses: [],
    sortBy: 'username',
    sortOrder: 'desc',
  });
  const [callsFilter, setCallsFilter] = useState<TableFilter>({
    statuses: [],
    sortBy: 'totalInteractionTime',
    sortOrder: 'desc',
  });
  const asteriskStatsManager = useMemo(() => {
    const socketUrl = `${predictiveConfig.diallerURL}/stats-ws`;

    const manager = new AsteriskStatsManager(socketUrl, diallerGroup.id, {
      onConnected: () => {
        clearTimeout(retryTimeoutRef.current);
        setSocketConnected(true);
        retryCount.current = 0;
      },
      onMessage: (asteriskStats) => {
        setInitialLoad(false);

        if (!Array.isArray(asteriskStats)) {
          setState(asteriskStats);
        }
      },
      onDisconnected: (reconnect) => {
        clearTimeout(retryTimeoutRef.current);
        setInitialLoad(false);
        setSocketConnected(false);

        retryTimeoutRef.current = exponentialDelay(retryCount.current, () => {
          retryCount.current += 1;
          reconnect();
        });
      },
    });

    return manager;
  }, []);

  // Handle the connection and disconnection of the socket
  useEffect(() => {
    asteriskStatsManager.connect();

    return () => {
      clearTimeout(retryTimeoutRef.current);
      asteriskStatsManager.disconnect();
    };
  }, []);

  // Logic to attempt a resend of any potential messages not send due to a network error
  useEffect(() => {
    const ref = setInterval(() => {
      asteriskStatsManager.sendQueuedMessages();
    }, 1_000);

    return () => {
      clearInterval(ref);
    };
  }, []);

  const pauseDiallingToggle = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked === true) {
      asteriskStatsManager.pauseDialling();
    } else {
      asteriskStatsManager.resumeDialling();
    }
  };

  const disconnectChannel = (id: string) => {
    asteriskStatsManager.disconnectChannel(id);
  };

  const changeGraphBucket = () => {
    if (graphBucketLocalRef.current === undefined) {
      console.error('changeGraphBucket: graphBucketLocalRef.current is undefined, unable to execute action.');
      return;
    }

    // TODO: set a min and max limit for number input

    const parsed = parseInt(graphBucketLocalRef.current.value);

    if (isNaN(parsed)) {
      console.error('changeGraphBucket: parsed value is not a number, unable to execute action.');
      return;
    }

    asteriskStatsManager.setGraphBucket(parsed);
  };

  const totalAgentCount = useMemo(() => {
    if (state !== undefined) {
      return state.agents.length;
    }

    return 0;
  }, [state?.agents]);

  const [awtLabels, awtData] = useMemo(() => {
    if (state !== undefined) {
      return getChartDataWithTimeBasedAxis(state.agentTimeInQueue);
    } else {
      return [[], []];
    }
  }, [state?.agentTimeInQueue]);

  const [htLabels, htData] = useMemo(() => {
    if (state !== undefined) {
      return getChartDataWithTimeBasedAxis(state.handleTime);
    } else {
      return [[], []];
    }
  }, [state?.handleTime]);

  // Function to handle toggle button change for call records
  const handleToggleChange = useCallback(
    (toggleType: ToggleType) => (e: MouseEvent<HTMLElement>, viewValue: ViewType) => {
      switch (toggleType) {
        case ToggleType.Agents: {
          setAgentsViewType(viewValue);
          break;
        }
        case ToggleType.Calls: {
          setActiveCallViewType(viewValue);
          break;
        }
        default: {
          assertUnreachable(toggleType);
        }
      }
    },
    [],
  );

  const onFilterChange = useCallback(
    (key: string, setState: Dispatch<SetStateAction<TableFilter>>) => (e: ChangeEvent<{}>, value: ListItem[]) => {
      setState((prev) => ({ ...prev, [key]: value }));
    },
    [],
  );

  const handleColumnSort = useCallback(
    (setState: Dispatch<SetStateAction<TableFilter>>, column: string) => () => {
      setState((prev) => {
        if (column === prev.sortBy) {
          return { ...prev, sortOrder: prev.sortOrder === 'asc' ? 'desc' : 'asc' };
        }

        return { ...prev, sortBy: column, sortOrder: 'asc' };
      });
    },
    [],
  );

  const statusList =
    state !== undefined
      ? state.agentStatusCounts.map((agentStatusCount) => ({
          label: agentStatusCount.status,
          value: agentStatusCount.status,
        }))
      : [];

  const agentsColumns: ColumnData[] = useMemo(() => {
    return [
      {
        label: 'ID',
        dataKey: 'id',
        copyToClipboardIcon: true,
        sortDirection: agentsFilter.sortOrder,
        sortActive: agentsFilter.sortBy === 'id',
        onColumnSort: handleColumnSort(setAgentsFilter, 'id'),
      },
      {
        label: 'Username',
        dataKey: 'username',
        sortDirection: agentsFilter.sortOrder,
        sortActive: agentsFilter.sortBy === 'username',
        onColumnSort: handleColumnSort(setAgentsFilter, 'username'),
      },
      {
        label: 'Status',
        dataKey: 'status',
        sortDirection: agentsFilter.sortOrder,
        sortActive: agentsFilter.sortBy === 'status',
        onColumnSort: handleColumnSort(setAgentsFilter, 'status'),
      },
      {
        label: 'Time In Status',
        dataKey: 'timeInStatus',
        sortDirection: agentsFilter.sortOrder,
        sortActive: agentsFilter.sortBy === 'timeInStatus',
        onColumnSort: handleColumnSort(setAgentsFilter, 'timeInStatus'),
      },
    ];
  }, [agentsFilter.sortBy, agentsFilter.sortOrder]);

  // Should only revalidate if the dataset changes
  const agentsRows = useMemo(() => {
    if (state === undefined) {
      return [];
    }

    return (
      state.agents
        // Filter out agents whose statuses do not exist in the status filter list
        .filter((agent) => {
          if (agentsFilter.statuses === undefined || agentsFilter.statuses.length === 0) {
            return true;
          }

          return agentsFilter.statuses.find((item) => item.value === agent.status) !== undefined;
        })
        .map((agent) => ({
          key: agent.key,
          color: agent.color,
          username: agent.username,
          status: agent.status,
          timeInStatus: agent.timeInStatus,
          isAgentPrimaryChannel: agent.isAgentPrimaryChannel,
        }))
        .sort(sortObject(agentsFilter.sortBy as any, agentsFilter.sortOrder))
    );
  }, [state?.agents, agentsFilter]);

  const callsColumns: ColumnData[] = useMemo(() => {
    return [
      {
        label: 'ID',
        dataKey: 'id',
        copyToClipboardIcon: true,
        sortDirection: callsFilter.sortOrder,
        sortActive: callsFilter.sortBy === 'id',
        onColumnSort: handleColumnSort(setCallsFilter, 'id'),
      },
      {
        label: 'Endpoint',
        dataKey: 'endpoint',
        copyToClipboardIcon: true,
        sortDirection: callsFilter.sortOrder,
        sortActive: callsFilter.sortBy === 'endpoint',
        onColumnSort: handleColumnSort(setCallsFilter, 'endpoint'),
      },
      {
        label: 'Total Interaction Time',
        dataKey: 'totalInteractionTime',
        sortDirection: callsFilter.sortOrder,
        sortActive: callsFilter.sortBy === 'totalInteractionTime',
        onColumnSort: handleColumnSort(setCallsFilter, 'totalInteractionTime'),
      },
      {
        label: 'Time In Status',
        dataKey: 'timeInStatus',
        sortDirection: callsFilter.sortOrder,
        sortActive: callsFilter.sortBy === 'timeInStatus',
        onColumnSort: handleColumnSort(setCallsFilter, 'timeInStatus'),
      },
      {
        label: 'Call Status',
        dataKey: 'callStatus',
        sortDirection: callsFilter.sortOrder,
        sortActive: callsFilter.sortBy === 'callStatus',
        onColumnSort: handleColumnSort(setCallsFilter, 'callStatus'),
      },
      {
        label: 'Agent',
        dataKey: 'agent',
        sortDirection: callsFilter.sortOrder,
        sortActive: callsFilter.sortBy === 'agent',
        onColumnSort: handleColumnSort(setCallsFilter, 'agent'),
      },
    ];
  }, [callsFilter.sortBy, callsFilter.sortOrder]);

  // Should only revalidate if the dataset changes
  const callsRows = useMemo(() => {
    if (state === undefined) {
      return [];
    }

    return (
      state.calls
        // Filter out calls whose statuses do not exist in the callStatuses filter list
        .filter((call) => {
          if (callsFilter.callStatuses === undefined || callsFilter.callStatuses.length === 0) {
            return true;
          }

          return callsFilter.callStatuses.find((item) => item.value === call.customerChannels[0]?.status) !== undefined;
        })
        .map((call) => ({
          id: call.id,
          color: call.color,
          endpoint: call.lead.endpoint || call.address || '',
          totalInteractionTime: call.callTime,
          callStatus: call.customerChannels[0]?.status,
          agent: call.agentChannels[0]?.username || '',
          timeInStatus: call.customerChannels[0]?.timeInStatus,
          customerChannels: call.customerChannels,
          agentChannels: call.agentChannels,
        }))
        .sort(sortObject(callsFilter.sortBy as any, callsFilter.sortOrder))
    );
  }, [state?.calls, callsFilter]);

  return (
    <AsyncLoader isLoading={initialLoad}>
      {!socketConnected && (
        <EmptyState
          type='error'
          text='Not Connected'
          subText={`Unable to connect to server: ${predictiveConfig.diallerURL}/stats-ws`}
        />
      )}

      {socketConnected && state !== undefined && (
        <>
          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Agents In Status
            </Typography>

            <Grid container spacing={2}>
              {state.agentStatusCounts.map((status, index) => (
                <Grid key={index} item xs={12} md={3}>
                  <StatusTotalCard
                    color={status.color}
                    status={status.status}
                    totalInStatus={status.count}
                    totalAgents={totalAgentCount}
                  />
                </Grid>
              ))}
            </Grid>
          </ContentSpacer>

          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Agents
            </Typography>

            {state.agents.length === 0 && (
              <Typography variant='body1' align='left'>
                <i>No active agents to display.</i>
              </Typography>
            )}

            {state.agents.length > 0 && (
              <Grid container spacing={2}>
                <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xs={12} md={3}>
                  <ToggleButtonGroup
                    fullWidth
                    orientation='horizontal'
                    value={agentsViewType}
                    exclusive
                    onChange={handleToggleChange(ToggleType.Agents)}
                    color='primary'>
                    <Tooltip title='Show as grid'>
                      <ToggleButton
                        selected={agentsViewType === ViewType.Grid}
                        value={ViewType.Grid}
                        aria-label='module'>
                        <ViewModuleIcon sx={{ marginRight: 1 }} />
                        Grid
                      </ToggleButton>
                    </Tooltip>

                    <Tooltip title='Show as table'>
                      <ToggleButton selected={agentsViewType === ViewType.List} value={ViewType.List} aria-label='list'>
                        <ViewListIcon sx={{ marginRight: 1 }} />
                        Table
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} md={9}>
                  <Autocomplete
                    multiple
                    fullWidth
                    onChange={onFilterChange('statuses', setAgentsFilter)}
                    value={agentsFilter.statuses || []}
                    options={statusList}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          deleteIcon={<CancelIcon />}
                          label={option.label}
                          {...getTagProps({ index })}
                          variant='filled'
                          color='primary'
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} id='statuses' name='statuses' label='Statuses' variant='outlined' />
                    )}
                  />
                </Grid>

                {agentsViewType === ViewType.List && (
                  <Grid item xs={12}>
                    <BasicTable
                      height={400}
                      rows={agentsRows}
                      columns={agentsColumns}
                      noResultsMessage='No agents data available.'
                    />
                  </Grid>
                )}

                {agentsViewType === ViewType.Grid && (
                  <>
                    {agentsRows.length === 0 && (
                      <Grid item xs={12}>
                        <Typography variant='body1' align='left'>
                          <i>No agents data available.</i>
                        </Typography>
                      </Grid>
                    )}
                    {agentsRows.length > 0 &&
                      agentsRows.map((agent, index) => (
                        <Grid key={index} item xs={12} md={3}>
                          <AgentStatusCard
                            color={agent.color}
                            id={agent.key}
                            username={agent.username}
                            status={agent.status}
                            timeInStatus={agent.timeInStatus}
                            isAgentPrimaryChannel={agent.isAgentPrimaryChannel}
                            disconnect={disconnectChannel}
                          />
                        </Grid>
                      ))}
                  </>
                )}
              </Grid>
            )}
          </ContentSpacer>

          <ContentSpacer spacing={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SectionCard title='Metrics'>
                  {Object.keys(state.vars).map((key, index) => (
                    <DataItem key={index} disableMargin={index === 0} title={key} value={state.vars[key]} />
                  ))}
                </SectionCard>
              </Grid>

              <Grid item xs={12} md={6}>
                <SectionCard title='Settings'>
                  <DataItem
                    disableMargin
                    title='Dialling Paused'
                    value={
                      <OberonSwitch
                        sx={{
                          // Canceling out left padding messing with positioning
                          marginLeft: '-12px',
                          marginTop: '-10px',
                        }}
                        checked={state.systemConfig.pauseDialling}
                        onChange={pauseDiallingToggle}
                      />
                    }
                  />
                  <DataItem
                    title='Graph Buckets'
                    value={
                      <>
                        <TextField
                          type='number'
                          variant='outlined'
                          size='small'
                          InputProps={{
                            endAdornment: (
                              <Button
                                sx={{
                                  borderRadius: '0 4px 4px 0',
                                  marginRight: '-14px',
                                  marginLeft: '4px',
                                  padding: '8px 16px',
                                }}
                                disableElevation
                                variant='contained'
                                onClick={changeGraphBucket}
                                color='primary'>
                                <SaveIcon />
                              </Button>
                            ),
                          }}
                          inputRef={(e) => (graphBucketLocalRef.current = e)}
                          defaultValue={state.systemConfig.graphBuckets}
                        />

                        <Typography component='div' variant='caption' color='textSecondary'>
                          <i>Current Display Value: {state.systemConfig.graphBuckets}</i>
                        </Typography>
                      </>
                    }
                  />
                  <DataItem title='Customer Hold Drop Time' value={state.systemConfig.customerHoldDropTime} />
                  <DataItem title='Max Lines Per Agent' value={state.systemConfig.maxLinesPerAgent} />
                  <DataItem title='Predictive Lookahead Time' value={state.systemConfig.predictiveLookaheadTime} />
                  <DataItem title='Time Between Call Checks' value={state.systemConfig.timeBetweenCallChecks} />
                  <DataItem title='Number Reserved Agents' value={state.systemConfig.numReservedAgents} />
                </SectionCard>
              </Grid>
            </Grid>
          </ContentSpacer>

          <ContentSpacer spacing={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SectionCard title='Agent Time In Queue'>
                  <BasicLineChart
                    fillSpace
                    yAxisPosition='left'
                    xAxis={awtLabels}
                    data={[
                      {
                        key: 'Agent Wait Time',
                        lineColor: '#17a2b8',
                        lineItems: awtData,
                      },
                    ]}
                  />
                </SectionCard>
              </Grid>

              <Grid item xs={12} md={6}>
                <SectionCard title='HandlingTime'>
                  <BasicLineChart
                    fillSpace
                    yAxisPosition='left'
                    xAxis={htLabels}
                    data={[
                      {
                        key: 'Handling Time',
                        lineColor: '#17a2b8',
                        lineItems: htData,
                      },
                    ]}
                  />
                </SectionCard>
              </Grid>
            </Grid>
          </ContentSpacer>

          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Active Calls
            </Typography>

            {state.calls.length === 0 && (
              <Typography variant='body1' align='left'>
                <i>No active calls to display.</i>
              </Typography>
            )}

            {state.calls.length > 0 && (
              <Grid container spacing={2}>
                <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xs={12} md={3}>
                  <ToggleButtonGroup
                    fullWidth
                    orientation='horizontal'
                    value={activeCallViewType}
                    exclusive
                    onChange={handleToggleChange(ToggleType.Calls)}
                    color='primary'>
                    <Tooltip title='Show as grid'>
                      <ToggleButton
                        selected={activeCallViewType === ViewType.Grid}
                        value={ViewType.Grid}
                        aria-label='module'>
                        <ViewModuleIcon sx={{ marginRight: 1 }} />
                        Grid
                      </ToggleButton>
                    </Tooltip>

                    <Tooltip title='Show as table'>
                      <ToggleButton
                        selected={activeCallViewType === ViewType.List}
                        value={ViewType.List}
                        aria-label='list'>
                        <ViewListIcon sx={{ marginRight: 1 }} />
                        Table
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} md={9}>
                  <Autocomplete
                    multiple
                    fullWidth
                    onChange={onFilterChange('callStatuses', setCallsFilter)}
                    value={callsFilter.callStatuses || []}
                    options={callStatusList}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          deleteIcon={<CancelIcon />}
                          label={option.label}
                          {...getTagProps({ index })}
                          variant='filled'
                          color='primary'
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id='callStatuses'
                        name='callStatuses'
                        label='Call Statuses'
                        variant='outlined'
                      />
                    )}
                  />
                </Grid>

                {activeCallViewType === ViewType.List && (
                  <Grid item xs={12}>
                    <BasicTable
                      height={400}
                      rows={callsRows}
                      columns={callsColumns}
                      noResultsMessage='No call data available.'
                    />
                  </Grid>
                )}

                {activeCallViewType === ViewType.Grid && (
                  <>
                    {callsRows.length === 0 && (
                      <Grid item xs={12}>
                        <Typography variant='body1' align='left'>
                          <i>No call data available.</i>
                        </Typography>
                      </Grid>
                    )}

                    {callsRows.length > 0 &&
                      callsRows.map((call, index) => (
                        <Grid key={index} item xs={12} md={3}>
                          <CallStatusCard
                            color={call.color}
                            id={call.id}
                            address={call.endpoint}
                            callTime={call.totalInteractionTime}
                            customerChannels={call.customerChannels}
                            agentChannels={call.agentChannels}
                            disconnect={disconnectChannel}
                          />
                        </Grid>
                      ))}
                  </>
                )}
              </Grid>
            )}
          </ContentSpacer>
        </>
      )}
    </AsyncLoader>
  );
};

export default DiallerGroupLiveDash;
